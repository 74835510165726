import { useTranslation } from 'react-i18next';
import { Stack } from '@koob/margaret';
import { Container } from 'ui';
import {
  EXPERIENCE_TRANSFER_AIR_SCHEMA,
  EXPERIENCE_TRANSFER_BOAT_SCHEMA,
  EXPERIENCE_TRANSFER_LAND_SCHEMA,
  EXPERIENCE_TRANSFER_RAIL_SCHEMA,
} from '../../Utilities/ExperienceValidationSchemas';
import ExperienceTransferTypeIcon from '../../Partials/ExperienceTransferTypeIcon';
import { useField } from 'formik';
import SpecificationFormLand from './Partials/SpecificationFormLand';
import SpecificationFormAir from './Partials/SpecificationFormAir';
import SpecificationFormRail from './Partials/SpecificationFormRail';
import SpecificationFormBoat from './Partials/SpecificationFormBoat';

export function ExperienceTransferSpecification() {
  const { t } = useTranslation('experiences');
  const [transferObj] = useField('transfer');
  const transfer = transferObj?.value;

  const getForm = type => {
    switch (type) {
      case 'LAND':
        return <SpecificationFormLand />;
      case 'AIR':
        return <SpecificationFormAir />;
      case 'RAIL':
        return <SpecificationFormRail />;
      case 'BOAT':
        return <SpecificationFormBoat />;
      default:
        return null;
    }
  };

  return (
    <>
      <Container size="full">
        <div className="py-5 text-gray-500 text-center font-semibold">
          <div className="mb-3 flex justify-center">
            <ExperienceTransferTypeIcon type={transfer?.type} />
          </div>
          {transfer?.type} {t('types.transfer')}
        </div>
        <Stack direction="column" gutterSize={1.5} size="full">
          {getForm(transfer?.type)}
        </Stack>
      </Container>
    </>
  );
}

export const getSchema = experience => {
  switch (experience?.transfer?.type) {
    case 'LAND':
      return EXPERIENCE_TRANSFER_LAND_SCHEMA;
    case 'AIR':
      return EXPERIENCE_TRANSFER_AIR_SCHEMA;
    case 'RAIL':
      return EXPERIENCE_TRANSFER_RAIL_SCHEMA;
    case 'BOAT':
      return EXPERIENCE_TRANSFER_BOAT_SCHEMA;
    default:
      return null;
  }
};
