import { Stack } from '@koob/margaret';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

const PillWrapper = styled(Stack).attrs({ as: 'span' })`
  font-size: 0.875rem;
  padding: ${({ theme }) => theme.spacing(0.125)}
    ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  display: inline-flex;
  ${({ variant }) =>
    variant === 'danger'
      ? css`
          background-color: rgba(224, 53, 27, 0.12);
          color: ${({ theme }) => theme.danger};
        `
      : null};
  ${({ variant }) =>
    variant === 'warning'
      ? css`
          background-color: rgba(245, 112, 10, 0.12);
          color: ${({ theme }) => theme.orange};
        `
      : null};
  ${({ variant }) =>
    variant === 'success'
      ? css`
          color: ${({ theme }) => theme.success};
          background-color: rgba(41, 204, 136, 0.12);
        `
      : null};
`;

const PillStatus = ({ value, emptyValue = 'N/A' }) => {
  const { t } = useTranslation('promotions');

  const variants = {
    active: {
      label: t('statusValues.active'),
      variant: 'success',
    },
    expired: {
      label: t('statusValues.expired'),
      variant: 'danger',
    },
    planned: {
      label: t('statusValues.planned'),
      variant: 'warning',
    },
  };

  const variant = variants[value];

  if (!Boolean(variant)) {
    return emptyValue;
  }

  return <PillWrapper variant={variant.variant}>{variant.label}</PillWrapper>;
};

export default PillStatus;
