import { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import styled from 'styled-components';
import { MdArrowDropDown } from 'react-icons/md';
import { Stack, PopoverItemButton } from '@koob/margaret';
import { fontStyles, Text } from 'ui';

const SectionToggleTitle = styled(PopoverItemButton)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.5)};
  ${fontStyles.bodySmall}
  text-transform: uppercase;

  ${({ variant }) =>
    variant === 'nested' &&
    `
      padding-left: 0;
      padding-right: 0;
    `}

  &:hover {
    color: ${({ theme }) => theme.textLight};
  }

  ${({ variant }) =>
    variant === 'full' &&
    `
      width: 100%;
    `}
`;

const ToggleSection = (
  { title, children, variant, disabled, rightElement },
  ref,
) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useImperativeHandle(ref, () => ({
    toggle: () => {
      setIsExpanded(!isExpanded);
    },
    expand: () => {
      setIsExpanded(true);
    },
    collapse: () => {
      setIsExpanded(false);
    },
  }));

  useEffect(() => {
    if (disabled && isExpanded) {
      setIsExpanded(false);
    }
  }, [disabled, isExpanded, setIsExpanded]);

  return (
    <>
      <SectionToggleTitle
        type="button"
        onClick={() => {
          if (!disabled) {
            setIsExpanded(!isExpanded);
          }
        }}
        variant={variant}
        id={title?.split(' ').join('-')}
      >
        <Stack size="full" alignY="center">
          <Stack gutterSize={0.5}>
            <MdArrowDropDown
              style={{
                marginRight: 0,
                transform: `rotate(-${isExpanded ? 0 : 90}deg)`,
                opacity: disabled ? 0.2 : 1,
              }}
              size={24}
            />
          </Stack>

          <Text type="body">{title}</Text>

          {rightElement && (
            <Stack alignX="end" style={{ flex: 1 }}>
              {rightElement}
            </Stack>
          )}
        </Stack>
      </SectionToggleTitle>

      {isExpanded && children}
    </>
  );
};

export default forwardRef(ToggleSection);
