import { useDropzone } from 'react-dropzone';
import styled, { useTheme } from 'styled-components';
import { IcUpload } from './icons';
import { Trans, useTranslation } from 'react-i18next';
import { Stack } from '@koob/margaret';

const Outter = styled.div`
  height: 0;
  padding-bottom: 75%;
  position: relative;
  width: 100%;
`;

const Inner = styled(Stack).attrs({
  direction: 'column',
  alignX: 'center',
  alignY: 'center',
})`
  background-color: ${({ theme }) => theme.secondaryBackgroundLight};
  border: 1px dashed ${({ theme }) => theme.secondarySeparator};
  padding: ${({ theme }) => theme.spacing()};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: center;
`;

const AffordingBooster = styled.div`
  text-decoration: underline;
`;

const Dropzone = ({ onDrop, accept = 'image/*', multiple = true }) => {
  const { t } = useTranslation('dragAndDrop');
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
  });
  const theme = useTheme();

  return (
    <Outter>
      <Inner {...getRootProps()}>
        <input {...getInputProps()} />
        <IcUpload size={48} color={theme.secondary} />
        {isDragActive ? (
          <div>{t('labelActive')}</div>
        ) : (
          <Trans i18nKey="dragAndDrop:labelInactive">
            Drop an image here to add it
            <AffordingBooster>or import from the device</AffordingBooster>
          </Trans>
        )}
      </Inner>
    </Outter>
  );
};

export default Dropzone;
