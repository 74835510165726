import { useRef } from 'react';
import styled from 'styled-components';

import { Stack, Dropdown } from '@koob/margaret';
import { MdContentCopy } from 'react-icons/md';
import Button from './Button';

const MyButton = styled(Button)`
  height: 10px;
  width: 10px;
`;

const BreadcrumbPopoverTrigger = ({ icon = <MdContentCopy size={14} /> }) => (
  <MyButton variant="primary">{icon}</MyButton>
);

const DropdownRooms = ({ popoverElement }) => {
  const popoverRef = useRef();

  return (
    <>
      <Stack direction="row" gutterSize={1} alignY="center">
        {Boolean(popoverElement) && (
          <Dropdown ref={popoverRef} trigger={<BreadcrumbPopoverTrigger />}>
            {popoverElement}
          </Dropdown>
        )}
      </Stack>
    </>
  );
};

export default DropdownRooms;
