import { Container } from 'ui';
import { Stack } from '@koob/margaret';
import { EditorField } from '../../../../components/Fields';
import React from 'react';
import { useField } from 'formik';
import { EXPERIENCE_CONDITION_SCHEMA } from '../Utilities/ExperienceValidationSchemas';

export function ExperienceCondition() {
  const [{ value: experienceType }, ,] = useField('type');

  return (
    <Container size="full">
      {experienceType !== 'Extra' && (
        <div className="my-5">
          <Stack gutterSize={1.5}>
            <EditorField name="cancellationPolicies" fillSpace />
          </Stack>
        </div>
      )}
    </Container>
  );
}

export const getSchema = () => {
  return EXPERIENCE_CONDITION_SCHEMA;
};
