import styled from 'styled-components';
import { CardTitle, Legend, Stack } from '@koob/margaret';
import { differenceInDays } from 'date-fns';
import { DateRange } from 'components';
import { IcOrganization } from 'components/icons';
import { useTranslation } from 'react-i18next';
import { IoMoonOutline, IoPeopleOutline } from 'react-icons/io5';
import { Text, SectionCard } from 'ui';
import { StarsPicker } from 'components/Fields';

const ItemCard = styled(Stack).attrs({
  size: 'full',
  padding: 0.5,
  gutterSize: 0.5,
})`
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;

const DmcContractHotelInfo = ({ hotel, filters }) => {
  const { t } = useTranslation('bookings');
  const { startAt, endAt, rooms } = filters;
  const daysCount = differenceInDays(new Date(endAt), new Date(startAt));

  return (
    <Stack direction="column" size="full" gutterSize={0.5}>
      <Stack paddingBottom={0.5}>
        <Text type="h2">{t('mySearch')}</Text>
      </Stack>

      <SectionCard>
        <Stack direction="column" size="full" gutterSize={1}>
          <ItemCard>
            <DateRange startAt={startAt} endAt={endAt} size="full" />
          </ItemCard>
          {Boolean(startAt) && Boolean(endAt) && (
            <Stack gutterSize={0.25} alignY="center">
              <IoMoonOutline size={18} />
              <Legend>
                {t('nNightsStay', {
                  count: daysCount,
                  days: daysCount + 1,
                })}
              </Legend>
            </Stack>
          )}

          <ItemCard direction="column" gutterSize={0.25}>
            <CardTitle>{hotel.displayName}</CardTitle>
            <Stack>
              <Legend>{hotel.address}</Legend>
            </Stack>
            <StarsPicker value={hotel.stars} />
          </ItemCard>

          <ItemCard>
            <Stack gutterSize={0.25} alignY="center">
              <IoPeopleOutline size={18} />
              <span>
                {t('nGuests', {
                  count: (rooms || []).reduce(
                    (total, { adults, children }) => total + adults + children,
                    0,
                  ),
                })}
              </span>
            </Stack>
            <Stack gutterSize={0.25} alignY="center">
              <IcOrganization size={18} />
              <span>{t('nRooms', { count: (rooms || []).length })}</span>
            </Stack>
          </ItemCard>
        </Stack>
      </SectionCard>
    </Stack>
  );
};

export default DmcContractHotelInfo;
