import DateRangePicker from './DateRangePicker';
import { FormLabel, ErrorMessageWrapper, FormField } from 'ui/forms';
import { Stack } from '@koob/margaret';
import { FastField } from 'formik';
import { useState } from 'react';
import { uniqueId } from 'lodash';
import { formatDate } from 'utils';

const FastDateRangePickerField = ({
  placeholder,
  label,
  name,
  isDateTime = false,
  ...props
}) => {
  const [id] = useState(uniqueId());

  return (
    <FormField>
      <Stack direction="column" size="full" alignX="stretch" gutterSize={0.5}>
        {Boolean(label) && <FormLabel>{label}</FormLabel>}
        <FastField name={name}>
          {({ field, form, meta }) => {
            const hasError = Boolean(meta.error && meta.touched);

            return (
              <>
                <DateRangePicker
                  id={id}
                  hasError={hasError}
                  placeholder={placeholder}
                  {...field}
                  onChange={value => {
                    const newValues = value ? value : [new Date(), new Date()];
                    if (isDateTime) {
                      form.setFieldValue(name, newValues);
                    } else {
                      const start = formatDate(newValues?.[0], 'yyyy-MM-dd') || null;
                      const end = formatDate(newValues?.[1], 'yyyy-MM-dd') || null;
                      form.setFieldValue(name, [start, end]);
                    }
                  }}
                  {...props}
                />
                {hasError && (
                  <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>
                )}
              </>
            );
          }}
        </FastField>
      </Stack>
    </FormField>
  );
};

export default FastDateRangePickerField;
