export default function AllotmentsHotelSelectorItem({
  item,
  isSelected,
  onSelect,
}) {
  return (
    <div
      onClick={onSelect}
      className={[
        'bg-white border border-l-4 rounded-md py-4 px-4',
        isSelected ? 'border-l-k-orange' : 'cursor-pointer',
      ].join(' ')}
    >
      <div 
        className="flex space-x-3 items-center" 
      >
        {isSelected ? (
          <i className="fas fa-circle-check text-k-orange" />
        ) : (
          <i className="far fa-circle text-gray-400" />
        )}

        <div className="flex-shrink-0 bg-gray-50 w-12 h-12 rounded-md overflow-hidden">
          {item.image && (
            <img
              src={item.image}
              alt=""
              className="w-full h-full object-cover border-none"
            />
          )}
        </div>

        <div className="truncate">
          <div className="font-medium leading-none text-gray-700">
            {item.name}
          </div>

          <div>
            {item.stars && (
              <div>
                {[...Array(5)].map((_, i) => (
                  <i
                    key={i}
                    className={[
                      'fas fa-star text-xs',
                      i < item?.stars ? 'text-yellow-400' : 'text-gray-300',
                    ].join(' ')}
                  />
                ))}
              </div>
            )}
          </div>

          {item.description && (
            <div className="text-gray-500 text-xs">{item.description}</div>
          )}
        </div>
      </div>
    </div>
  );
}
