import { useEffect, useRef, useState } from 'react';
import { FormField, FormLabel } from 'ui/forms';
import { useTheme } from 'styled-components';
import { pickBy } from 'lodash';
import {
  ButtonReset,
  Dropdown,
  PopoverContainer,
  PopoverMenu,
  Stack,
} from '@koob/margaret';
import { IcCheckMark, IcAddCircle } from 'components/icons';
import Search from 'components/Search';
import { useField } from 'formik';
import { gql, useQuery } from '@apollo/client';
import { useDebounce } from 'react-use';
import { useTranslation } from 'react-i18next';
import ToggleSection from 'components/ToggleSection';
import FacilityPopoverItem from 'components/FacilityPopoverItem';
import { formatFacilitiesToGroupsFacilities } from 'utils';

const GET_FACILITIES = gql`
  query getFacilities($search: String, $kind: FacilityKindValues) {
    facilities(displayName: $search, kind: $kind) {
      edges {
        node {
          id
          displayName
          enDisplayName
          iconUrl(size: ICON)
          groups {
            nodes {
              id
              displayName
              enDisplayName
            }
          }
        }
      }
    }
  }
`;

const FacilitiesGroup = ({
  group,
  facilities,
  hasSearch,
  onToggleFacility,
  checkedIds,
}) => {
  const theme = useTheme();
  const toggleRef = useRef();

  useEffect(() => {
    if (!Boolean(toggleRef.current)) {
      return;
    }
    if (hasSearch) {
      toggleRef.current.expand();
      return;
    }
    toggleRef.current.collapse();
  }, [hasSearch]);

  return (
    <ToggleSection
      ref={toggleRef}
      title={group.displayName || group.enDisplayName}
    >
      {facilities.map(facility => (
        <FacilityPopoverItem
          key={facility.id}
          facility={facility}
          onClick={() => onToggleFacility(facility)}
          actions={
            <ButtonReset
              type="button"
              onClick={() => onToggleFacility(facility)}
            >
              {checkedIds.includes(facility.id) ? (
                <IcCheckMark size={24} color={theme.primary} />
              ) : (
                <IcAddCircle size={24} color={theme.textLight} />
              )}
            </ButtonReset>
          }
        />
      ))}
    </ToggleSection>
  );
};

const FacilitiesGroupsSelectField = ({
  label,
  placeholder,
  name,
  kind,
  disabled,
  currentLocale,
}) => {
  const { t } = useTranslation('');
  const [{ value }, , { setValue }] = useField({ name });
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { data } = useQuery(GET_FACILITIES, {
    variables: pickBy({
      search: debouncedSearch,
      kind,
      locale: currentLocale?.value ?? 'en',
    }),
  });

  const groups = formatFacilitiesToGroupsFacilities(
    data?.facilities?.edges || [],
    t('misc:others'),
  );
  const checkedIds = (value ?? []).map(({ facility }) => facility?.id);

  const handleToggleFacility = targetFacility => {
    if (value.some(({ facility }) => targetFacility?.id === facility?.id)) {
      setValue(
        value.filter(({ facility }) => targetFacility?.id !== facility?.id),
      );
      return;
    }

    setValue([
      ...value,
      { facility: targetFacility, details: '', groups: targetFacility?.groups },
    ]);
  };

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  return (
    <FormField>
      {Boolean(label) && <FormLabel>{label}</FormLabel>}

      {!disabled && (
        <Stack direction="column" gutterSize={1} size="full" alignX="stretch">
          <Dropdown
            trigger={
              <Search
                placeholder={placeholder}
                onChange={setSearch}
                value={search}
              />
            }
          >
            <PopoverContainer>
              <PopoverMenu>
                {groups.map(
                  ({ group, facilities }) =>
                    facilities.length > 0 && (
                      <FacilitiesGroup
                        key={group.id}
                        group={group}
                        facilities={facilities}
                        hasSearch={Boolean(debouncedSearch)}
                        onToggleFacility={handleToggleFacility}
                        checkedIds={checkedIds}
                      />
                    ),
                )}
              </PopoverMenu>
            </PopoverContainer>
          </Dropdown>
        </Stack>
      )}
    </FormField>
  );
};

export default FacilitiesGroupsSelectField;
