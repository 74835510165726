import { useState } from 'react';
import styled, { css } from 'styled-components';
import { Card as RawCard, Text } from 'ui';
import { useTranslation } from 'react-i18next';
import { useQuery, gql } from '@apollo/client';
import { ButtonReset, Stack } from '@koob/margaret';
import { Select } from 'components/Fields';
import { useField, useFormikContext } from 'formik';
import { FormField, FormLabel } from 'ui/forms';
import { get, find, pickBy } from 'lodash';
import { useUserRights } from 'hooks';

const GET_COUNTRIES = gql`
  query getCountries($organizationId: ID) {
    countries(organizationId: $organizationId) {
      edges {
        node {
          id
          alpha2
          title
          regions(organizationId: $organizationId) {
            nodes {
              id
              title
            }
            totalCount
          }
        }
      }
    }
  }
`;

const Card = styled(RawCard)`
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  padding: 12px;
`;

const CountryWrapper = styled(Stack).attrs({
  gutterSize: 0.5,
  size: 'full',
})``;

const CollapseButton = styled(ButtonReset).attrs({ type: 'button' })`
  color: ${({ theme }) => theme.secondary};
`;

const Sublabel = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing()};
  color: ${({ theme }) => theme.textLight};
`;

const Regions = styled(Stack).attrs({
  direction: 'column',
  size: 'full',
  paddingBottom: 0.75,
})`
  border-bottom: 1px solid ${({ theme }) => theme.separator};

  ${({ variant }) =>
    variant === 'last' &&
    css`
      border-bottom: 0;
      padding-bottom: 0;
    `}
`;

const RightsSelect = ({ name, value, onChange }) => {
  const rightOptions = useUserRights();

  return (
    <Select
      options={rightOptions}
      name={name}
      placeholder="–"
      onChange={onChange}
      value={value}
    />
  );
};

const RightsSelectField = ({ name }) => {
  const [{ value }, , { setValue }] = useField({ name });

  return (
    <RightsSelect
      value={value}
      name={name}
      placeholder="–"
      onChange={setValue}
    />
  );
};

const Country = ({ country, name, isLast }) => {
  const { t } = useTranslation('user');
  const [isExpanded, setIsExpanded] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const rightOptions = useUserRights();

  const formatAggregateValue = input => {
    if (country?.regions?.totalCount === 0) {
      return null;
    }
    const countryRegionIds = (country?.regions?.nodes || []).map(
      ({ id }) => id,
    );
    const filledCountryRegionIds = countryRegionIds.filter(id =>
      Boolean(input?.[id]),
    );
    if (filledCountryRegionIds.length !== countryRegionIds.length) {
      return null;
    }
    const countryValues = countryRegionIds.map(id => input?.[id]?.value);

    if (
      countryValues.filter(value => value === 'write').length ===
      country?.regions?.totalCount
    ) {
      return find(rightOptions, ({ value }) => value === 'write');
    }

    if (
      countryValues.filter(value => value === 'read').length ===
      country?.regions?.totalCount
    ) {
      return find(rightOptions, ({ value }) => value === 'read');
    }

    if (
      countryValues.filter(value => value === null).length ===
      country?.regions?.totalCount
    ) {
      return find(rightOptions, ({ value }) => value === null);
    }

    return null;
  };

  const handleSelectCountryRights = value => {
    const newFieldValue = (country.regions.nodes || []).reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: value,
      }),
      {},
    );

    setFieldValue(name, { ...values?.[name], ...newFieldValue });
  };

  const aggregateValue = formatAggregateValue(get(values, name));

  return (
    <CountryWrapper>
      <Stack alignY="center" style={{ height: 40 }}>
        <span
          className={`flag-icon flag-icon-${country.alpha2.toLowerCase()}`}
        />
      </Stack>
      <Regions variant={isLast && 'last'}>
        <Stack alignX="space-between" size="full" alignY="center">
          <div>
            <Text type="body" fontWeight="600">
              {country?.title} (
              {t('region', { count: country?.regions?.totalCount })})
            </Text>
          </div>
          <Stack alignY="center" gutterSize={0.75}>
            <CollapseButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? t('lessSettings') : t('moreSettings')}
            </CollapseButton>
            <div style={{ minWidth: 170 }}>
              <RightsSelect
                value={aggregateValue}
                onChange={handleSelectCountryRights}
              />
            </div>
          </Stack>
        </Stack>

        {isExpanded && (
          <Stack
            direction="column"
            size="full"
            gutterSize={0.5}
            paddingTop={0.5}
          >
            {(country?.regions?.nodes || []).map(region => (
              <Stack
                key={region?.id}
                size="full"
                alignX="space-between"
                alignY="center"
              >
                <div>{region?.title}</div>
                <div style={{ minWidth: 170 }}>
                  <RightsSelectField name={`${name}.${region?.id}`} />
                </div>
              </Stack>
            ))}
          </Stack>
        )}
      </Regions>
    </CountryWrapper>
  );
};

const RegionsRightsSelectorField = ({
  label,
  sublabel,
  name,
  organizationId,
}) => {
  const { t } = useTranslation('user');
  const { data, loading } = useQuery(GET_COUNTRIES, {
    variables: pickBy({ organizationId }),
  });

  const countries = data?.countries?.edges ?? [];

  return (
    <FormField>
      {Boolean(label) && <FormLabel>{label}</FormLabel>}
      {Boolean(sublabel) && <Sublabel>{sublabel}</Sublabel>}

      <Card>
        {countries.length > 0 ? (
          <Stack direction="column" gutterSize={0.75}>
            {countries.map(({ node }, index) => (
              <Country
                key={node?.id}
                country={node}
                name={name}
                isLast={countries.length === index + 1}
              />
            ))}
          </Stack>
        ) : (
          !loading && (
            <Text type="body" color="textLighter">
              {t('noCountryForThisOrganization')}
            </Text>
          )
        )}
      </Card>
    </FormField>
  );
};

export default RegionsRightsSelectorField;
