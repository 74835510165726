import moment from 'moment-timezone';
import styled, { css } from 'styled-components';
import ReactDateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Stack } from '@koob/margaret';
import { MdToday, MdClose, MdArrowForward } from 'react-icons/md';
import { FormLabel } from 'ui/forms';
import { DatesPickerStyles } from './DatePicker';
import 'react-date-picker/dist/DatePicker.css';
import { initialLocale } from 'utils';
import { useEffect } from 'react';
import { addMinutes } from 'date-fns';

const Icon = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.secondary};
  font-size: 18px;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.disabled};
    `}

  ${({ variant }) =>
    variant === 'light' &&
    css`
      color: ${({ theme }) => theme.disabled};
      font-size: 16px;
    `}

  ${({ variant }) =>
    variant === 'rangeDivider' &&
    css`
      color: ${({ theme }) => theme.textLight};
      padding: 0 ${({ theme }) => theme.spacing()};
    `}
`;

const Wrapper = styled.div`
  position: relative;
  background-color: #ffffff;
  border-radius: ${({ theme }) => theme.borderRadius.large};

  input {
    color: ${({ theme }) => theme.textLight};

    ${({ disabled }) =>
      disabled &&
      css`
        color: ${({ theme }) => theme.disabled};
      `}
  }
`;

const DateRangePicker = ({
  disabled,
  label,
  onChange,
  value,
  minDate,
  maxDate,
  isClearable = true,
  wrapperStyle,
  convertToUTC = true,
  ...props
}) => {
  useEffect(() => {
    if (value && minDate && value[0] < minDate) {
      onChange([minDate, value[1]]);
    }
    if (value && maxDate && value[1] > maxDate) {
      onChange([value[0], maxDate]);
    }
  });

  const toUTC = date => {
    return addMinutes(date, date.getTimezoneOffset());
  };

  const getValue = () => {
    let value1 = undefined;
    let value2 = undefined;
    if (value && value[0] !== undefined && value[0] !== null) {
      value1 = value[0];
      if (typeof value1 === 'string') {
        value1 = new Date(value1);
      }

      if (convertToUTC === true) {
        const dateUTC = toUTC(value1);
        if (dateUTC.getDate() !== value1.getDate()) {
          value1 = dateUTC;
        }
      }
    }

    if (value && value[1] !== undefined && value[1] !== null) {
      value2 = value[1];
      if (typeof value[1] === 'string') {
        value2 = new Date(value[1]);
      }
      if (convertToUTC === true) {
        const dateUTC = toUTC(value2);
        if (dateUTC.getDate() !== value2.getDate()) {
          value2 = dateUTC;
        }
      }
    }

    if (moment.isMoment(value1)) {
      value1 = value1.toDate();
    }
    if (moment.isMoment(value2)) {
      value2 = value2.toDate();
    }
    return [value1, value2];
  };
  return (
    <Stack
      direction="column"
      gutterSize={0.5}
      alignX="stretch"
      style={wrapperStyle}
    >
      {Boolean(label) && <FormLabel>{label}</FormLabel>}
      <Wrapper disabled={disabled} style={wrapperStyle}>
        <ReactDateRangePicker
          value={getValue()}
          onChange={onChange}
          locale={`${initialLocale.value}-${initialLocale?.label}`}
          maxDate={maxDate}
          minDate={minDate}
          format="dd/MM/yyyy"
          disabled={disabled}
          clearIcon={
            isClearable && (
              <Icon variant="light">
                <MdClose />
              </Icon>
            )
          }
          calendarIcon={
            <Icon disabled={disabled}>
              <MdToday />
            </Icon>
          }
          rangeDivider={
            <Icon variant="rangeDivider">
              <MdArrowForward />
            </Icon>
          }
          {...props}
        />
        <DatesPickerStyles />
      </Wrapper>
    </Stack>
  );
};

export default DateRangePicker;
