import { gql } from '@apollo/client';

export const GET_HOTEL_KEEPER_ADMIN_BOOKING = gql`
  query hotelKeeperAdminBooking($secret: String!) {
    hotelKeeperAdminBooking(secret: $secret) {
      id
      hotelKeeperMessage
      hotelKeeperMessageSendAt
      customerAdditionalAddress
      customerAddress
      customerCity
      customerCompany
      customerEmail
      customerFirstname
      customerLastname
      customerPhoneNumber
      customerZipCode
      canceledAt
      kind
      currency
      dmcTotalPriceWithoutPromo
      totalPrice
      note
      operatorTourName
      networkName
      numberOfNights
      comment
      allotmentAvailable
      showToTa
      hotelConfirmationNumber
      hotel {
        id
        stars
        address
        cityName
        regionName
        countryName
        displayName
        showToTa
        primaryAttachment {
          imageUrl(size: MEDIUM)
        }
        organization {
          id
          displayName
          avatarUrl(size: MEDIUM)
        }
      }
      roomCompositions {
        id
        sourceRoomId
        dmcPrice
        dmcPricePerDay
        dmcPriceWithoutPromo
        tags
        bedCompositions {
          label
          quantity
        }
        hotelRoom {
          displayName
        }
        travelers {
          id
          kind
          gender
          firstName
          lastName
          birthdate
          passportNumber
          expirationDate
          nationality
        }
        promotions {
          kind
          promotionKind
          value
        }
      }
      state
      startAt
      endAt
      rooms
    }
  }
`;

export const HOTEL_KEEPER_GET_ADMIN_BOOKING_DOCUMENTS = gql`
  query getHotelKeeperAdminBookingDocuments($secret: String!) {
    hotelKeeperAdminBookingDocuments(secret: $secret) {
      nodes {
        id
        user {
          firstName
          lastName
        }
        document {
          id
          metadata
        }
        documentUrl
        createdAt
      }
    }
  }
`;

export const HOTEL_KEEPER_CREATE_ADMIN_BOOKING_DOCUMENT = gql`
  mutation createAdminBookingDocument(
    $input: CreateAdminBookingDocumentInput!
  ) {
    createAdminBookingDocument(input: $input) {
      adminBookingDocument {
        id
      }
    }
  }
`;

export const HOTEL_KEEPER_GET_ADMIN_BOOKING_MESSAGES = gql`
  query getHotelKeeperAdminBookingMessages($secret: String!) {
    hotelKeeperAdminBookingMessages(secret: $secret) {
      nodes {
        id
        senderType
        user {
          firstName
          lastName
        }
        message
        createdAt
      }
    }
  }
`;

export const HOTEL_KEEPER_CREATE_ADMIN_BOOKING_MESSAGE = gql`
  mutation createAdminBookingMessage($input: CreateAdminBookingMessageInput!) {
    createAdminBookingMessage(input: $input) {
      adminBookingMessage {
        message
      }
    }
  }
`;
