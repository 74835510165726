import { Stack } from '@koob/margaret';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Text } from 'ui';
import { post } from 'api/node';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnack } from 'hooks';
import PromotionWizard from './PromotionWizard';

const TopSection = styled(Stack).attrs({
  size: 'full',
})`
  padding-top: ${({ theme }) => theme.spacing()};
  padding-bottom: ${({ theme }) => theme.spacing()};
`;

const PromotionCreate = ({ experience }) => {
  const { t } = useTranslation('promotions');
  const { experienceId } = useParams();
  const navigate = useNavigate();
  const { notify, notifyError } = useSnack();

  const handleSubmit = async (promotion, methods) => {
    try {
      methods.setSubmitting(true);
      await post(`/promotions/`, promotion);
      navigate(`/experiences/edit/${experienceId}/promotions`, {
        replace: true,
      });
      notify(t('createSuccess'));
    } catch (error) {
      if (error?.response?.data?.message) {
        notify(error?.response?.data?.message, { type: 'error' });
      } else {
        notifyError(error);
      }
    } finally {
      methods.setSubmitting(false);
    }
  };
  return (
    <>
      <TopSection>
        <Text type="h1">{t('totitle')}</Text>
      </TopSection>
      <PromotionWizard experience={experience} handleSave={handleSubmit} />
    </>
  );
};

export default PromotionCreate;
