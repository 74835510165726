import React from 'react';
import { cva } from 'class-variance-authority';

export default function KoobBadge({
  size = 'md',
  rounded = 'md',
  color = 'bg-gray-300 text-gray-50',
  children,
}) {
  const badge = cva(['flex items-center'], {
    variants: {
      color: {
        gray: 'bg-gray-200 text-gray-500',
        green: 'bg-green-200 text-green-500',
        blue: 'bg-blue-200 text-blue-500',
        orange: 'bg-orange-200 text-orange-500',
        red: 'bg-red-200 text-red-500',
        purple: 'bg-purple-200 text-purple-500',
      },
      size: {
        sm: 'py-0.5 px-2 text-xs',
        md: 'py-1 px-3 text-sm',
      },
      rounded: {
        sm: 'rounded-sm',
        md: 'rounded-md',
        lg: 'rounded-lg',
        full: 'rounded-full',
      },
    },
  });

  return (
    <span className={badge({ color, rounded, size })}>
      <div>{children}</div>
    </span>
  );
}
