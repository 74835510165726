import { useTranslation } from "react-i18next";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Select } from 'components/Fields';
import { useCallback, useEffect, useState } from "react";
import KoobInput from './Koob/Input/KoobInput';
import { debounce } from 'lodash';

export const Paginator = ({page, setPage, totalPages, perPage, setPerPage}) => {
  const HEIGHT = 42;
  const { t } = useTranslation('toConnectionRequest');
  const [selectedPage, setSelectedPage] = useState({label: page, value: page});

  const handleLoadNextPage = () => {
    const updatedPage = page + 1;
    setPage(updatedPage);
  }

  const handleLoadPreviousPage = () => {
    const updatedPage = page - 1;
    setPage(updatedPage);
  }

  const handleChange = useCallback(
    debounce((value) => {
      const count = value > 0 ? value : 50;
      setPerPage(count)
    }, 500),
    []
  );

  useEffect(() => {
    setSelectedPage({value: page, label: page});
  }, [page])

  const pageOptions = [];
  for (let page = 1; page <= (totalPages); page++) {
    pageOptions.push({
      label: page, 
      value: page
    });
  }
  
  return(
    <div className="flex justify-center items-center space-x-3">
      <div className="flex justify-end items-center space-x-1 rounded-md">
        <div className="flex items-center w-[40%] border space-x-2 p-1 rounded-md border">
          <div className="items-center">
            <KoobInput 
              defaultValue={perPage} 
              type="number" 
              onChange={(value) => {
                handleChange(value);
                setPage(1);
              }}
              size="medium"
            />
          </div>
          <div className="w-56">{t('perPage')}</div>
        </div>
      </div>


      <div className="flex items-center space-x-2 border rounded-md p-2">
        <div className="flex items-center space-x-1">
          <Select
            value={selectedPage}
            options={pageOptions}
            styles={{
              control: baseStyles => ({
                ...baseStyles,
                borderRadius: '20px',
                height: HEIGHT,
                padding: '0 0.5rem',
                
              }),
            }}
            onChange={(value) => {
              setSelectedPage(value);
              setPage(value?.value);
            }}
          />
        </div>
        
        <span className="text-gray-500">
          {t('pageCount', {count: totalPages, page})}
        </span>
        
        <div className="flex space-x-2">
          <button 
            className="text-gray-500 hover:text-black cursor-pointer border-none"
            type="button"
            onClick={() => handleLoadPreviousPage()}
            disabled={page === 1}
          >
            <MdKeyboardArrowLeft size={20} />
          </button>

          <button
            className="text-gray-500 hover:text-black cursor-pointer border-none"
            type="button"
            onClick={() => handleLoadNextPage()}
            disabled={Boolean(parseInt(totalPages) === page)}
          >
            <MdKeyboardArrowRight size={20}/>
          </button>

        </div>
      </div>
    </div>
  )
}