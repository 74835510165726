import React from 'react';

const EyeButton = ({ room, roomIndex, setFieldValue, className }) => {
  const iconClass = room.enabled ? 'fa-eye' : 'fa-eye-slash';
  return (
    <div className={`flex justify-center text-center ${room.enabled ? 'text-orange-500' : 'text-gray-500'} text-lg ${className}`}>
     <div
       onClick={(e) => {
         e.preventDefault();
         e.stopPropagation();
         setFieldValue(`rooms.${roomIndex}.enabled`, !room.enabled);
       }}
       className={'cursor-pointer'}
     >
       <i className={'fa-solid '+iconClass}/>
     </div>
    </div>
  );
}

export default EyeButton;
