import { KoobStateEnum } from "@koob/to/src/utils";

export function mergeOrganizationFromToRequestConnectionList(toConnectionRequestData) {
  if (!toConnectionRequestData?.data) return [];
  
  const formattedToRequests = Object.values(toConnectionRequestData.data)
    .reduce((acc, curr) => {
      if (curr.state === KoobStateEnum.CONFIRMED) { 
        const { id } = curr.toorganization;
        const type = curr.type;
    
        if (!acc[id]) {
          acc[id] = { ...curr, [type]: curr };
        } else {
          acc[id][type] = curr;
        }
      }
    return acc;
  }, {});

  return Object.values(formattedToRequests);
}