export const API_BASE = process.env.REACT_APP_API_BASE_URL;
export const API_NODE_BASE = process.env.REACT_APP_API_NODE_BASE_URL;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const KOOB_TO_URL = process.env.REACT_APP_TO_BASE_URL;

export const KOEDIA = 'KOEDIA';
export const RAKUTEN = 'RAKUTEN';

export const PASSWORD_REGEX = /^[A-Za-z0-9%!?,.(){}\-_@*^$&#]+$/;

export const DEFAULT_LOCAL = 'en';
