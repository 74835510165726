import { FieldArray, getIn, useField, useFormikContext } from 'formik';
import { find, uniqueId } from 'lodash';
import { Fragment, useEffect, useRef } from 'react';
import { Select, SelectField, ToggleSwitchField } from '.';
import styled, { useTheme, css } from 'styled-components';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Input, FormLabel } from 'ui/forms';
import { IoCloseOutline, IoMoonOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { pickBy } from 'lodash';
import { FormField } from 'ui/forms';
import { VscChevronDown } from 'react-icons/vsc';
import {
  Dropdown,
  PopoverContainer,
  PopoverMenu,
  Stack,
  PopoverItemButton as RawPopoverItemButton,
  ButtonReset,
} from '@koob/margaret';
import { IcCheckMark, IcAddCircle } from 'components/icons';
import { Text } from 'ui';
import VisuallyHidden from 'components/VisuallyHidden';
import { useTranslation } from 'react-i18next';
import { GET_HOTEL_ROOMS, GET_HOTEL_AMENITIES } from 'api/promotions';
import { isAfter, isBefore } from 'date-fns';
import { GET_CONTRACTS } from 'api/contracts';

const DropdownToggleWrapper = styled(Stack)`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(0.5)};
  box-shadow: 0 0 4px rgb(0 0 0 / 0.08);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin-bottom: ${({ theme }) => theme.spacing(0.25)};
  justify-content: space-between;
  align-items: center;
`;

const DropdownToggle = ({ children }) => {
  return (
    <DropdownToggleWrapper>
      {children} <VscChevronDown aria-hidden="true" size={16} />
    </DropdownToggleWrapper>
  );
};

const DisplayListView = styled.ul`
  list-style: none;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(1)};
  margin: 0;
  border: solid 1px rgba(0, 0, 0, 0.12);
  border-radius: ${({ theme }) => theme.borderRadius.default};
  width: 100%;
  overflow-y: auto;
  max-height: 12ch;
`;

const DisplayListItem = styled.li`
  padding: ${({ theme }) => theme.spacing(0.25)} 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`;

const SelectionLabel = styled.span`
  text-transform: uppercase;
`;

const ClearAllButton = styled(ButtonReset).attrs({ type: 'button' })`
  color: ${({ theme }) => theme.secondary};
  text-decoration: underline;

  ${({ empty }) =>
    empty
      ? css`
          color: ${({ theme }) => theme.textLighter};
        `
      : null};
`;

const SummaryListHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  margin-block: ${({ theme }) => theme.spacing(1)}
    ${({ theme }) => theme.spacing(0.5)};
`;

const PopoverItemButton = styled(RawPopoverItemButton)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};

  color: ${({ theme }) => theme.textLight};

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;
const FieldInputList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.text};
`;

const FieldInputItem = styled(Stack).attrs({ as: 'li' })`
  padding: ${({ theme }) => theme.spacing(1)} 0;
  color: ${({ theme }) => theme.textLight};
  &:only-child {
    border-bottom: none;
  }
`;

const AmenitiesField = ({ name, disabled }) => {
  const theme = useTheme();
  const [{ value }, , { setValue }] = useField(`${name}.amenities`);
  const { hotelId } = useParams();
  const { t } = useTranslation('promotionForm');

  const { data } = useQuery(GET_HOTEL_AMENITIES, {
    variables: pickBy({
      id: hotelId,
    }),
    skip: !hotelId,
  });

  const amenities = (data?.amenities?.nodes || []).map(node => ({
    id: node?.id,
    displayName: node?.displayName,
  }));

  const handleClick = selectedRoom => {
    if (value?.length > 0) {
      if (value?.some(({ id }) => id === selectedRoom.id)) {
        setValue(value.filter(({ id }) => id !== selectedRoom.id));
      } else {
        setValue([...value, { ...selectedRoom }]);
      }
    } else {
      setValue([{ ...selectedRoom }]);
    }
  };

  const handleRemoveAll = () => {
    setValue([]);
  };

  const handleRemoveById = id => {
    const nextState = value?.filter(item => item.id !== id);
    setValue(nextState);
  };

  return (
    <Wrapper style={{ alignSelf: 'stretch' }}>
      <FormField>
        <FormLabel>{t('amenities.label')}</FormLabel>
        {!disabled && (
          <Stack direction="column" gutterSize={1} size="full" alignX="stretch">
            <Dropdown
              trigger={
                <DropdownToggle>
                  {t('amenities.searchAmenitiesToAdd')}
                </DropdownToggle>
              }
            >
              <PopoverContainer>
                <PopoverMenu>
                  {amenities?.map(({ id, displayName }) => (
                    <PopoverItemButton
                      key={id}
                      onClick={() => handleClick({ id, displayName })}
                      type="button"
                    >
                      <Stack
                        size="full"
                        alignX="space-between"
                        alignY="center"
                        gutterSize={0.5}
                      >
                        <Stack alignY="center" gutterSize={0.25}>
                          <Text type="body">
                            <SelectionLabel>{displayName}</SelectionLabel>
                          </Text>
                        </Stack>
                        {value?.some(bed => bed.id === id) ? (
                          <IcCheckMark size={24} color={theme.primary} />
                        ) : (
                          <IcAddCircle size={24} color={theme.textLight} />
                        )}
                      </Stack>
                    </PopoverItemButton>
                  ))}
                </PopoverMenu>
              </PopoverContainer>
            </Dropdown>
          </Stack>
        )}
      </FormField>
      <SummaryListHeader>
        <span style={{ color: theme.textLight }}>
          {t('amenities.amenitiesSelected')} &middot; {value?.length ?? 0}
        </span>
        <ClearAllButton
          empty={(value?.length ?? 0) === 0}
          onClick={handleRemoveAll}
        >
          {t('applicableRooms.clear')}
        </ClearAllButton>
      </SummaryListHeader>
      {value?.length > 0 && (
        <DisplayListView>
          {value?.map(item => (
            <DisplayListItem key={item?.id}>
              <SelectionLabel>
                {item?.displayName?.toUpperCase()}
              </SelectionLabel>
              <ButtonReset onClick={() => handleRemoveById(item?.id)}>
                <VisuallyHidden>
                  {t('applicableRooms.deleteFromSelection', {
                    value: item?.displayName,
                  })}
                </VisuallyHidden>
                <IoCloseOutline
                  color={theme.textLighter}
                  aria-hidden="true"
                  size={24}
                />
              </ButtonReset>
            </DisplayListItem>
          ))}
        </DisplayListView>
      )}
    </Wrapper>
  );
};

const ApplicableRoomsField = ({ name, disabled }) => {
  const theme = useTheme();
  const [{ value }, , { setValue }] = useField(`${name}.applicableRooms`);
  const { hotelId } = useParams();
  const { t } = useTranslation('promotionForm');

  const { data } = useQuery(GET_HOTEL_ROOMS, {
    variables: pickBy({
      id: hotelId,
    }),
    skip: !hotelId,
  });

  const [, { value: type }] = useField(`${name}.type`);
  const [, { value: upgradeRoomValue }] = useField(`${name}.upgradedRoom`);

  const rooms = (
    type?.value !== 'upgrade_room'
      ? data?.node?.rooms?.nodes || []
      : (data?.node?.rooms?.nodes || []).filter(
          node => node?.id !== upgradeRoomValue?.value,
        )
  ).map(node => ({
    id: node?.id,
    displayName: node?.displayName || node?.enDisplayName,
    count: node?.count,
  }));

  const handleClick = selectedRoom => {
    if (value?.length > 0) {
      if (value?.some(({ id }) => id === selectedRoom.id)) {
        setValue(value.filter(({ id }) => id !== selectedRoom.id));
      } else {
        setValue([...value, { ...selectedRoom }]);
      }
    } else {
      setValue([{ ...selectedRoom }]);
    }
  };

  const handleRemoveAll = () => {
    setValue([]);
  };

  const handleRemoveById = id => {
    const nextState = value?.filter(item => item.id !== id);
    setValue(nextState);
  };

  return (
    <Wrapper>
      <FormField>
        <FormLabel>{t('applicableRooms.applicableOnRooms')}</FormLabel>
        {!disabled && (
          <Stack direction="column" gutterSize={1} size="full" alignX="stretch">
            <Dropdown
              trigger={
                <DropdownToggle>
                  {t('applicableRooms.searchRoomsToAdd')}
                </DropdownToggle>
              }
            >
              <PopoverContainer>
                <PopoverMenu>
                  {rooms?.map(({ id, displayName, count }) => (
                    <PopoverItemButton
                      key={id}
                      onClick={() => handleClick({ id, displayName, count })}
                      type="button"
                    >
                      <Stack
                        size="full"
                        alignX="space-between"
                        alignY="center"
                        gutterSize={0.5}
                      >
                        <Stack alignY="center" gutterSize={0.25}>
                          <Text type="body">
                            <SelectionLabel>
                              {displayName} ({count})
                            </SelectionLabel>
                          </Text>
                        </Stack>
                        {value?.some(bed => bed.id === id) ? (
                          <IcCheckMark size={24} color={theme.primary} />
                        ) : (
                          <IcAddCircle size={24} color={theme.textLight} />
                        )}
                      </Stack>
                    </PopoverItemButton>
                  ))}
                </PopoverMenu>
              </PopoverContainer>
            </Dropdown>
          </Stack>
        )}
      </FormField>
      <SummaryListHeader>
        <span style={{ color: theme.textLight }}>
          {t('applicableRooms.typesSelected')} &middot; {value?.length ?? 0}
        </span>
        <ClearAllButton
          empty={(value?.length ?? 0) === 0}
          onClick={handleRemoveAll}
        >
          {t('applicableRooms.clear')}
        </ClearAllButton>
      </SummaryListHeader>
      {value?.length > 0 && (
        <DisplayListView>
          {value?.map(item => (
            <DisplayListItem key={item?.id}>
              <SelectionLabel>
                {item?.displayName?.toUpperCase()} ({item?.count})
              </SelectionLabel>
              <ButtonReset onClick={() => handleRemoveById(item?.id)}>
                <VisuallyHidden>
                  {t('applicableRooms.deleteFromSelection', {
                    value: item?.displayName,
                  })}
                </VisuallyHidden>
                <IoCloseOutline
                  color={theme.textLighter}
                  aria-hidden="true"
                  size={24}
                />
              </ButtonReset>
            </DisplayListItem>
          ))}
        </DisplayListView>
      )}
    </Wrapper>
  );
};

const UpgradeFromRoomField = ({ name }) => {
  const { hotelId } = useParams();
  const { t } = useTranslation('promotionForm');
  const theme = useTheme();

  const { data } = useQuery(GET_HOTEL_ROOMS, {
    variables: {
      id: hotelId,
    },
    skip: !hotelId,
  });

  const [, { value, upgradedToId = value?.value }] = useField(
    `${name}.upgradedRoom.to`,
  );

  const rooms = (data?.node?.rooms?.nodes ?? [])
    .filter(item => {
      return upgradedToId !== item?.id;
    })
    .map(item => ({
      label: `${item.displayName} (${item.count})`,
      value: item.id,
    }));

  return (
    <Stack style={{ marginTop: theme.spacing(0.5) }}>
      <SelectField
        label={t('upgradedRoom.from')}
        options={rooms}
        name={`${name}.upgradedRoom.from`}
      />
    </Stack>
  );
};

const UpgradeToRoomField = ({ name }) => {
  const { hotelId } = useParams();
  const { t } = useTranslation('promotionForm');
  const theme = useTheme();

  const { data } = useQuery(GET_HOTEL_ROOMS, {
    variables: {
      id: hotelId,
    },
    skip: !hotelId,
  });

  const [, { value, upgradeRoomsId = value?.value }] = useField(
    `${name}.upgradedRoom.from`,
  );

  const rooms = (data?.node?.rooms?.nodes ?? [])
    .filter(item => {
      return item.id !== upgradeRoomsId;
    })
    .map(item => ({
      label: `${item.displayName} (${item.count})`,
      value: item.id,
    }));

  return (
    <Stack style={{ marginTop: theme.spacing(0.5) }}>
      <SelectField
        label={t('upgradedRoom.to')}
        options={rooms}
        name={`${name}.upgradedRoom.to`}
      />
    </Stack>
  );
};

const Hint = styled.p`
  color: ${({ theme }) => theme.textLighter};
  margin: ${({ theme }) => theme.spacing(0.5)} 0 0;
  font-size: 0.9em;
`;

const Wrapper = styled(Stack).attrs({ direction: 'column' })`
  padding: ${({ theme }) => theme.spacing(0.5)} 0;
`;

export const BenefitsField = ({ type, name, currency = undefined }) => {
  const [, state, { setValue }] = useField(name);
  const id = useRef(uniqueId(`${name}.benefits.`));
  const setBenefitsValue = value => {
    setValue({
      ...state.value,
      value,
    });
  };

  const items = {
    percent: (
      <NumberInputField
        kind="percent"
        id={id.current}
        value={state.value.value}
        onChange={setBenefitsValue}
      />
    ),
    amount: (
      <NumberInputField
        kind="amount"
        id={id.current}
        value={state.value.value}
        onChange={setBenefitsValue}
        currency={currency}
      />
    ),
    supplement_percent: (
      <NumberInputField
        kind="supplement_percent"
        id={id.current}
        value={state.value.value}
        onChange={setBenefitsValue}
      />
    ),
    supplement: (
      <NumberInputField
        kind="supplement"
        id={id.current}
        value={state.value.value}
        onChange={setBenefitsValue}
        currency={currency}
      />
    ),
    free_night: (
      <NumberInputField
        kind="free_night"
        id={id.current}
        value={state.value.value}
        onChange={setBenefitsValue}
      />
    ),
    amenity: <AmenitiesField name={name} />,
  };

  return <>{type && items[type] ? <Wrapper>{items[type]}</Wrapper> : null}</>;
};

const PaxNode = ({ name, extraBed, type, currency }) => {
  const [, state, { setValue }] = useField(name);
  const { t } = useTranslation('promotionForm');
  const setBenefitsValue = value => {
    setValue({
      ...state.value,
      age: extraBed.age,
      operand: extraBed.operand,
      value,
    });
  };

  return (
    <Fragment key={name}>
      <FieldInputItem>
        <div>
          {extraBed.operand === 'above' ? '>' : '<'}{' '}
          {t('yearsOld', {
            age: extraBed.age,
            count: extraBed.age === '' ? 0 : 1,
          })}
        </div>
      </FieldInputItem>
      <FieldInputItem>
        <NumberInputField
          kind={type}
          value={state.value?.value}
          id={`${name}.value`}
          currency={currency}
          onChange={setBenefitsValue}
        />
      </FieldInputItem>
    </Fragment>
  );
};

const PaxField = ({ type, name, currency = undefined, contract }) => {
  if (!contract) {
    return <></>;
  }
  return (
    <>
      <FieldArray
        name={`${name}.benefitAgeGroups`}
        render={_helpers => {
          return (
            <>
              <FieldInputList>
                {contract?.node?.extraBeds?.nodes?.map((extraBed, index) => (
                  <PaxNode
                    name={`${name}.benefitAgeGroups.${index}`}
                    extraBed={extraBed}
                    type={type}
                    currency={currency}
                  />
                ))}
              </FieldInputList>
            </>
          );
        }}
      />
    </>
  );
};

const NumberInputField = ({
  id,
  value,
  currency = 'eur',
  onChange = () => {},
  kind = 'percent',
}) => {
  const theme = useTheme();
  const { t } = useTranslation('promotionForm');
  const kinds = {
    percent: {
      label: t('benefit.percent'),
      element: '%',
    },
    amount: {
      label: t('benefit.amount'),
      element: <Stack>{getSymbolFromCurrency(currency)}</Stack>,
    },
    supplement_percent: {
      label: t('benefit.supplement_percent'),
      element: '%',
    },
    supplement: {
      label: t('benefit.supplement'),
      element: <Stack>{getSymbolFromCurrency(currency)}</Stack>,
    },
    free_night: {
      label: t('benefit.free_night'),
      element: (
        <IoMoonOutline aria-hidden="true" size={20} color={theme.textLighter} />
      ),
    },
  };

  return (
    <>
      <FormLabel htmlFor={id}>{kinds?.[kind]?.label}</FormLabel>
      <Stack direction="row" alignY="center" style={{ width: '100%' }}>
        <Input
          style={{ flex: 1 }}
          id={id}
          type="number"
          min={0}
          {...(kind === 'percent'
            ? {
                min: 0,
                max: 100,
              }
            : {})}
          value={value}
          onChange={e => onChange(e.target.valueAsNumber)}
          step={kind !== 'free_night' ? 0.01 : 1}
        />
        {kinds?.[kind]?.element && (
          <Stack
            style={{
              paddingBlock: theme.spacing(0.25),
              paddingInline: theme.spacing(0.5),
              color: theme.textLight,
            }}
          >
            {kinds?.[kind]?.element}
          </Stack>
        )}
      </Stack>
      {kind === 'amount' && <Hint>{t('benefit.currencyHint')}</Hint>}
    </>
  );
};

const PromotionTypeField = ({ name }) => {
  const { t } = useTranslation('promotionForm');
  const { id } = useParams();

  const parametersTypesOptions = [
    {
      label: t('promotionTypes.amount'),
      value: 'amount',
    },
    {
      label: t('promotionTypes.percent'),
      value: 'percent',
    },
    {
      label: t('promotionTypes.free_night'),
      value: 'free_night',
    },
    {
      label: t('promotionTypes.amenities'),
      value: 'amenity',
    },
    {
      label: t('promotionTypes.upgrade_room'),
      value: 'upgrade_room',
    },
    {
      label: t('promotionTypes.supplement'),
      value: 'supplement',
    },
    {
      label: t('promotionTypes.supplement_percent'),
      value: 'supplement_percent',
    },
  ];

  return (
    <Wrapper>
      <SelectField
        disabled={Boolean(id)}
        name={`${name}.type`}
        options={parametersTypesOptions}
        label={t('promotionTypes.label')}
      />
    </Wrapper>
  );
};

const PromotionParamsField = ({
  name,
  disabled,
  hotel,
  setContract,
  contract,
}) => {
  const [, state, { setValue }] = useField(name);
  const { t } = useTranslation('promotionForm');
  const isUpgradeRoomPromotion = state?.value?.type?.value === 'upgrade_room';
  const { values, validateField } = useFormikContext();

  const periods = getIn(values, 'periods');

  const { hotelId } = useParams();

  const startDate = (periods ?? []).reduce(function (prev, curr) {
    return isBefore(Date.parse(prev.startDate), Date.parse(curr.startDate))
      ? prev
      : curr;
  }).startAt;
  const endDate = (periods ?? []).reduce(function (prev, curr) {
    return isAfter(Date.parse(prev.endDate), Date.parse(curr.endDate))
      ? prev
      : curr;
  }).endAt;

  const { data: contracts } = useQuery(GET_CONTRACTS, {
    variables: pickBy({
      hotelId,
      stateFilter: {
        status: 'published',
        state: 'available',
      },
      encapsulateDates: {
        startDate: startDate,
        endDate: endDate,
      },
    }),
    fetchPolicy: 'no-cache',
    skip: !startDate || !endDate,
  });

  useEffect(() => {
    validateField(name);
  }, [contract, name, validateField]);

  if (contracts) {
    if (contracts.contracts.edges.length === 1) {
      setContract(contracts.contracts.edges[0]);
      if (
        contract &&
        state &&
        contracts.contracts.edges[0]?.node?.extraBeds?.nodes?.length === 0 &&
        state.value.benefitAgeGroups &&
        state.value.benefitAgeGroups.length !== 0
      ) {
        setValue({ ...state.value, benefitAgeGroups: [] });
      }
    } else {
      setContract(undefined);
      if (
        state &&
        state.value.benefitAgeGroups &&
        state.value.benefitAgeGroups.length !== 0
      ) {
        setValue({ ...state.value, benefitAgeGroups: [] });
      }
    }
  }

  const isAmountOrPercentPromotion =
    state?.value?.type?.value === 'amount' ||
    state?.value?.type?.value === 'percent' ||
    state?.value?.type?.value === 'supplement' ||
    state?.value?.type?.value === 'supplement_percent';

  const unitOptions = [
    {
      label: t('benefit.unit.per_unit'),
      value: 'per_unit',
    },
    {
      label: t('benefit.unit.per_night'),
      value: 'per_night',
    },
  ];

  return (
    <>
      <PromotionTypeField name={name} />
      {isAmountOrPercentPromotion && (
        <div className="flex flex-col space-y-2 w-full">
          <ToggleSwitchField
            name={`${name}.promoPerRoom`}
            label={t('promoPerRoom')}
            fullWidth
          />
          <ToggleSwitchField
            name={`${name}.includeExtraBeds`}
            label={t('includeExtraBeds')}
            fullWidth
          />
        </div>
      )}
      {state?.value?.type?.value === 'supplement' && (
        <div className="flex flex-col gap-y-2 w-full mt-2">
          <FormLabel>{t('benefit.unit.label')}</FormLabel>
          <Select
            options={unitOptions}
            name={`${name}.benefits.unit`}
            onChange={value => {
              setValue({
                ...state.value,
                unit: value,
              });
            }}
            value={state.value.unit ?? 'per_night'}
            renderSelectedValue={value =>
              find(unitOptions, option => option.value === value?.value)?.label
            }
            valueField="value"
          />
        </div>
      )}
      {isAmountOrPercentPromotion &&
      !state?.value?.promoPerRoom &&
      contract?.node?.extraBeds?.nodes?.length > 0 ? (
        <PaxField
          name={name}
          currency={hotel?.currency}
          contract={contract}
          type={state?.value?.type?.value}
        />
      ) : (
        <BenefitsField
          currency={hotel?.currency}
          name={name}
          type={state?.value?.type?.value}
        />
      )}

      {isUpgradeRoomPromotion ? (
        <>
          <UpgradeFromRoomField name={name} disabled={disabled} />
          <UpgradeToRoomField name={name} disabled={disabled} />
        </>
      ) : (
        <ApplicableRoomsField name={name} disabled={disabled} />
      )}
    </>
  );
};

export default PromotionParamsField;
