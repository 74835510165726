import { Container, Stack } from '@koob/margaret';
import { IoWarningOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { BookingState } from 'components';
import { Text } from 'ui';
import { formatDate } from 'utils';
import { IcCheckMark, IcHistory } from 'components/icons';

const LABEL_BY_BOOKING_STATE = {
  confirmed: null,
  canceled: 'reason',
  refused: 'reason',
  pending: 'pending',
};

const SubHeaderBase = styled(Stack).attrs({
  size: 'full',
})`
  background-color: #ffffff;
  box-shadow: 0 1px 2px ${({ theme }) => theme.separator};
  padding: ${({ theme }) => theme.spacing(1.5)}
    ${({ theme }) => theme.spacing(2)};
`;

const WrapperMessage = styled(Stack).attrs({
  direction: 'column',
  gutterSize: 0.5,
})`
  padding-left: ${({ theme }) => theme.spacing(2.25)};
`;

const BookingMessageByState = ({ state }) => {
  const { t } = useTranslation('hotelKeeper');
  const theme = useTheme();

  switch (state) {
    case 'canceled':
      return (
        <Stack alignY="center" gutterSize={0.75}>
          <IoWarningOutline size={24} color={theme.danger} />
          <div>{t('subHeader.canceledBooking')}</div>
        </Stack>
      );
    case 'refused':
      return (
        <Stack alignY="center" gutterSize={0.75}>
          <IoWarningOutline size={24} color={theme.danger} />
          <div>{t('subHeader.refusedBooking')}</div>
        </Stack>
      );
    case 'confirmed':
      return (
        <Stack alignY="center" gutterSize={0.75}>
          <IcCheckMark size={24} color={theme.success} />
          <div>{t('subHeader.confirmedBooking')}</div>
        </Stack>
      );
    case 'pending':
      return (
        <Stack alignY="center" gutterSize={0.75}>
          <IoWarningOutline size={24} color={theme.danger} />
          <div>{t('subHeader.pendingBooking')}</div>
        </Stack>
      );
    case 'sent':
      return (
        <Stack alignY="center" gutterSize={0.75}>
          <IcHistory size={24} color={theme.primary} />
          <div>{t('subHeader.sentBooking')}</div>
        </Stack>
      );
    default:
      return null;
  }
};

const SubHeader = ({ booking }) => {
  const { t } = useTranslation('hotelKeeper');

  if (booking?.state === 'sent') {
    return null;
  }

  return (
    <SubHeaderBase>
      <Container>
        <Stack direction="column" size="full" gutterSize={1.25}>
          <Stack alignX="space-between" alignY="center" size="full">
            <BookingMessageByState state={booking?.state} />
            <Stack alignY="center" gutterSize={1}>
              <BookingState
                state={booking?.state}
                translationKey="hotelKeeper"
              />
              {booking?.hotelKeeperMessageSendAt && (
                <div>
                  {formatDate(
                    booking?.hotelKeeperMessageSendAt,
                    'dd/MM/yy - HH:mm',
                  )}
                </div>
              )}
            </Stack>
          </Stack>

          {LABEL_BY_BOOKING_STATE[booking?.state] && (
            <WrapperMessage>
              <div>
                <Text fontWeight={700}>
                  {t(`subHeader.${LABEL_BY_BOOKING_STATE[booking?.state]}`) +
                    ':'}
                </Text>
              </div>
              <div>
                {booking?.state === 'canceled' ? (
                  <div>
                    {Boolean(booking?.comment)
                      ? `« ${booking.comment} »`
                      : t('noMessage')}
                  </div>
                ) : (
                  <div>
                    {Boolean(booking?.hotelKeeperMessage)
                      ? `« ${booking.hotelKeeperMessage} »`
                      : t('noMessage')}
                  </div>
                )}
              </div>
            </WrapperMessage>
          )}
        </Stack>
      </Container>
    </SubHeaderBase>
  );
};

export default SubHeader;
