import styled from 'styled-components';
import { MdAddCircleOutline } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Stack, ButtonReset } from '@koob/margaret';
import { Container, Text, Pills, Pill } from 'ui';
import { Tooltip } from 'components';
import { IoTrashOutline } from 'react-icons/io5';

const PillsWrapper = styled(Container)`
  padding-left: 0;
  padding-right: 0;
  padding-top: ${({ theme }) => theme.spacing()};
  padding-bottom: ${({ theme }) => theme.spacing()};
`;

const PillButtonHack = styled(ButtonReset)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const DayPills = ({
  createDayIndex,
  deleteDayIndex,
  selectedDayIndex,
  onSelectDayIndex,
  days,
  isUnsaved,
  addDisabled = false,
  translation,
  disabled,
}) => {
  const { t } = useTranslation(translation);

  return (
    <PillsWrapper>
      <Pills>
        {(days || []).map((_, index) => (
          <Pill
            type="button"
            key={index}
            as="span"
            isActive={index === selectedDayIndex}
            style={{ position: 'relative' }}
          >
            <PillButtonHack
              onClick={() => onSelectDayIndex(index)}
              type="button"
            />
            <Stack alignY="center">
              {`${t('form.day')} ${index + 1}`}
              <ButtonReset
                type="button"
                onClick={() => {
                  if (selectedDayIndex >= index && selectedDayIndex > 0) {
                    onSelectDayIndex(selectedDayIndex - 1);
                  }
                  deleteDayIndex(index);
                }}
                style={{ position: 'relative', marginLeft: '5px' }}
              >
                {' '}
                <IoTrashOutline size={18} />
              </ButtonReset>
            </Stack>
          </Pill>
        ))}
        {!disabled && (
          <Tooltip disabled={!addDisabled} tip={t('selectEnglish')}>
            <Pill
              type="button"
              onClick={() => !isUnsaved && !addDisabled && createDayIndex()}
              isActive={typeof selectedDayndex !== 'number'}
              disabled={addDisabled}
            >
              <Stack alignY="center" gutterSize={0.25}>
                <MdAddCircleOutline size={20} />
                <Text>{t('misc:add')}</Text>
              </Stack>
            </Pill>
          </Tooltip>
        )}
      </Pills>
    </PillsWrapper>
  );
};

export default DayPills;
