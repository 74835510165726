import { getAllCountries } from 'api/node/countries';
import { DEFAULT_LOCAL } from 'constants';
import { BookingContext } from 'contexts';
import { differenceInYears } from 'date-fns';
import { uniqueId } from 'lodash';
import { useCallback, useState, useMemo } from 'react';
import { useAsync } from 'react-async';

const filtersValidations = {
  rooms: (composition = []) => {
    const total = composition?.reduce((acc, curr) => {
      return acc + (curr?.adults ?? 0) + (curr?.children ?? 0);
    }, 0);
    return total !== 0;
  },
  location: value => !!value,
  startAndEndDates: (start, end) => {
    return !!start && !!end;
  },
};

export const getTravelerComposition = ({
  compositions,
  customerFile,
  nationalities,
  typeFieldName = 'kind',
}) => {
  const travelerRooms = (compositions || [])?.reduce((acc, curr) => {
    const peoples = [];
    for (let i = 0; i < curr.adults; i++) {
      const id = uniqueId();
      const people = { id };
      people[typeFieldName] = 'adult';
      people.indexOfKind = i;
      peoples.push(people);
    }
    for (let j = 0; j < curr.children; j++) {
      const id = uniqueId();
      const people = { id };
      people[typeFieldName] = 'child';
      people.indexOfKind = j;
      peoples.push(people);
    }
    acc.push({ ...curr, travelers: peoples });
    return acc;
  }, []);

  if (customerFile) {
    let adultIndex = 0;
    let childIndex = 0;
    const adults = customerFile?.travelers?.filter(
      traveler =>
        !!traveler.birthdate &&
        differenceInYears(new Date(), new Date(traveler.birthdate)) >= 18,
    );
    const childs = customerFile?.travelers?.filter(
      traveler =>
        !!traveler.birthdate &&
        differenceInYears(new Date(), new Date(traveler.birthdate)) < 18,
    );
    travelerRooms.forEach(room => {
      room.travelers.forEach((traveler, index) => {
        let person = undefined;
        if (traveler[typeFieldName] === 'adult') {
          if (adultIndex < adults?.length) {
            person = adults[adultIndex];
            adultIndex++;
          }
        } else {
          if (childIndex < childs?.length) {
            person = childs[childIndex];
            childIndex++;
          }
        }
        if (person) {
          traveler.id = person.id;
          traveler.gender = person.gender;
          traveler.firstName = person.firstName;
          traveler.lastName = person.lastName;
          traveler.birthdate = person.birthdate;
          traveler.nationality = nationalities?.find(
            nationality =>
              nationality?.value ===
              (index === 0
                ? room?.firstAdultNationality?.value || person.nationality
                : person.nationality),
          );
          traveler.passportNumber = person.passportNumber;
          traveler.expirationDate = person.passportExpirationDate;
          traveler.ageIsExact = person.ageIsExact;
        } else {
          traveler.nationality =
            index === 0 ? room?.firstAdultNationality : undefined;
        }
      });
    });
  } else {
    travelerRooms.forEach(room => {
      room.travelers.forEach((traveler, index) => {
        if (index === 0) {
          traveler.nationality = room?.firstAdultNationality;
        } else {
          traveler.nationality = null;
        }
      });
    });
  }
  return travelerRooms;
};

const BookingProvider = ({ children }) => {
  const [filters, setFilters] = useState({});
  const [customerFile, setCustomerFile] = useState(undefined);
  const [bookingSource, setBookingSource] = useState(undefined);
  const [bookingId, setBookingId] = useState(undefined);
  const [replaceBookingId, setReplaceBookingId] = useState(undefined);
  const [guests, setGuests] = useState([]);
  const [bedIndexes, setBedIndexes] = useState([]);

  const filtersValidity = useMemo(() => {
    if (!filtersValidations.rooms(filters?.rooms)) {
      return false;
    }
    if (!filtersValidations.location(filters?.location)) {
      return false;
    }
    if (
      !filtersValidations.startAndEndDates(filters?.startAt, filters?.endAt)
    ) {
      return false;
    }
    return true;
  }, [filters]);

  const resetValues = useCallback(() => {
    setFilters({});
  }, []);

  const { data: countriesData } = useAsync({
    promiseFn: getAllCountries,
  });
  const nationalities = useMemo(() => {
    return (countriesData?.data ?? []).map((country) => ({
      value: country?.alpha2,
      label: country?.translations.find((elem) => elem.locale === DEFAULT_LOCAL).title,
    }));
  }, [countriesData]);

  const value = {
    filters,
    setFilters,
    filtersValidity,
    customerFile,
    setCustomerFile,
    resetValues,
    bookingSource,
    setBookingSource,
    bookingId,
    setBookingId,
    replaceBookingId,
    setReplaceBookingId,
    bedIndexes,
    setBedIndexes,
    guests,
    setGuests,
    nationalities,
  };

  return (
    <BookingContext.Provider value={value}>{children}</BookingContext.Provider>
  );
};

export default BookingProvider;
