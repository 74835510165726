import { Route, Routes } from 'react-router-dom';
import React from 'react';
import Allotments from './index';

export default function AllotmentRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Allotments />} />
    </Routes>
  );
}
