import styled, { useTheme } from 'styled-components';
import { PopoverItemButton, Stack, ButtonReset } from '@koob/margaret';
import { Text } from 'ui';
import { IcDrag } from './icons';

const RegionPopoverItemButton = styled(PopoverItemButton)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(2)};

  svg {
    margin-right: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.textLight};
  }

  ${({ isDraggable, theme }) =>
    isDraggable &&
    `
      padding: ${theme.spacing(0.5)} ${theme.spacing()} ${theme.spacing(
      0.5,
    )} ${theme.spacing(0.5)}; 
      width: 100%;
    `}
`;

const RegionPopoverContent = styled(ButtonReset)`
  flex: 1;
  text-align: left;
`;

const Actions = styled(Stack)`
  svg {
    margin-right: 0;
  }
`;

const RegionPopoverItem = ({ region, onClick, actions, isDraggable }) => {
  const theme = useTheme();

  return (
    <RegionPopoverItemButton as="div" isDraggable={isDraggable}>
      <Stack
        size="full"
        alignX="space-between"
        alignY="center"
        gutterSize={0.5}
      >
        <RegionPopoverContent
          type={Boolean(onClick) ? 'button' : undefined}
          as={!Boolean(onClick) ? 'div' : undefined}
          onClick={onClick}
        >
          <Stack gutterSize={0.5} alignY="center" size="full">
            {isDraggable && <IcDrag color={theme.textLighter} />}
            <Text type="body">{region?.label}</Text>
          </Stack>
        </RegionPopoverContent>
        {Boolean(actions) && <Actions>{actions}</Actions>}
      </Stack>
    </RegionPopoverItemButton>
  );
};

export default RegionPopoverItem;
