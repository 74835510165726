import styled, { css, createGlobalStyle } from 'styled-components';
import ReactDatePicker from 'react-date-picker';
import { FormLabel } from 'ui/forms';
import { MdToday, MdClose } from 'react-icons/md';
import { Stack } from '@koob/margaret';
import { Field } from 'formik';
import { useState } from 'react';
import { uniqueId } from 'lodash';

export const DatesPickerStyles = createGlobalStyle`
.react-date-picker__inputGroup__input, .react-daterange-picker__inputGroup {
  color: ${({ theme }) => theme.textLight};
  font-weight: 600;

  input {
  color: ${({ theme }) => theme.textLight};
  }
}

  .react-daterange-picker__range-divider > * {
    padding-left: var(--range-picker-padding, ${({ theme }) =>
      theme.spacing(1)});
    padding-right: var(--range-picker-padding, ${({ theme }) =>
      theme.spacing(1)});
  }

  .react-date-picker,
  .react-daterange-picker {
    min-height: 42px;

    .react-date-picker__wrapper,
    .react-daterange-picker__wrapper {
      border: 1px solid ${({ theme }) => theme.separator};
      border-radius: ${({ theme }) => theme.borderRadius.default};
      width: var(--range-picker-width, auto);
    }
  }

  .react-date-picker__calendar.react-date-picker__calendar,
  .react-daterange-picker__calendar.react-daterange-picker__calendar {
    z-index: 2;
  }

  .react-daterange-picker__calendar-button.react-daterange-picker__calendar-button {
    display: none;
  }

  .react-date-picker__inputGroup__input.react-date-picker__inputGroup__input,
  .react-daterange-picker__inputGroup__input.react-daterange-picker__inputGroup__input {
    height: auto;
    outline: none;
  }

  .react-date-picker__inputGroup__leadingZero,
  .react-daterange-picker__inputGroup__leadingZero {
    color: ${({ theme }) => theme.textLight};
  }

  .react-date-picker__inputGroup.react-date-picker__inputGroup,
  .react-daterange-picker__inputGroup.react-daterange-picker__inputGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: ${({ theme }) => theme.spacing(0.5)};
    padding-right: ${({ theme }) => theme.spacing(0.25)};

    ~ .react-daterange-picker__inputGroup {
      padding-left: ${({ theme }) => theme.spacing(0.25)};;
    }
  }

  .react-date-picker__inputGroup__divider,
  .react-daterange-picker__inputGroup__divider {
    color: ${({ theme }) => theme.textLight};
  }

  .react-date-picker__inputGroup__input:invalid.react-date-picker__inputGroup__input:invalid,
  .react-daterange-picker__inputGroup__input:invalid.react-daterange-picker__inputGroup__input:invalid {
    background: transparent;
  }

  .react-calendar {
    &.react-calendar {
      border: 1px solid ${({ theme }) => theme.separator};
      border-radius: 4px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
    }

    .react-calendar__navigation__prev-8-button,
    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }

    .react-calendar__navigation {
      margin-bottom: 0;
      background-color: ${({ theme }) => theme.secondary};
      color: #ffffff;
      font-weight: 700;
      overflow: hidden;
      border-radius: 4px 4px 0 0;

      button:enabled:hover,
      button:enabled:focus {
        background-color: ${({ theme }) => theme.secondaryLight};
      }

      button[disabled] {
        background-color: ${({ theme }) => theme.secondary};
      }
    }

    abbr[title] {
      text-decoration: none;
    }

    .react-calendar__month-view__days__day--weekend {
      color: inherit;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      color: ${({ theme }) => theme.textLight};
    }

    .react-calendar__tile:disabled {
      background-color: transparent;
      color: ${({ theme }) => theme.textLighter};
    }

    .react-calendar__month-view__weekdays {
      background-color: ${({ theme }) => theme.secondary};
      color: #ffffff;
      font-weight: 700;
    }

    .react-calendar__tile {
      padding: ${({ theme }) => theme.spacing(0.25)};
      min-height: 3em;
      display: flex;
      align-items: stretch;
      justify-content:center;

      > abbr {
        padding: ${({ theme }) => theme.spacing(0.25)};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        width: 100%;
      }
    }

    .react-calendar__tile--now {
      background-color: transparent;
      color: inherit;
    }

    .react-calendar__tile--active {
      background-color: transparent;
      color: inherit;

      > abbr, > abbr:hover {
        background-color: ${({ theme }) => theme.secondary};
        color: #ffffff;
      }
    }

    .react-calendar__tile:enabled {
      &:hover,
      &:focus {
        background-color: transparent;

        > abbr {
          background-color: #f0f0f0;
        }
      }
    }
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.textLight};
  font-size: 18px;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.disabled};
    `}

  ${({ variant }) =>
    variant === 'light' &&
    css`
      color: ${({ theme }) => theme.disabled};
      font-size: 16px;
    `}
`;

const Wrapper = styled.div`
  position: relative;
  background-color: #ffffff;
  border-radius: ${({ theme }) => theme.borderRadius.large};

  input {
    color: ${({ theme }) => theme.textLight};

    ${({ disabled }) =>
      disabled &&
      css`
        color: ${({ theme }) => theme.disabled};
      `}
  }
`;

const DatePicker = ({
  disabled,
  label,
  onChange,
  value,
  minDate,
  maxDate,
  isClearable,
  canBeString,
  ...props
}) => {
  const newValue = typeof value === 'string' && canBeString ? new Date(value) : value;
  const newValueByType = typeof newValue === 'object' ? new Date(newValue?.setHours(12)) : new Date(newValue);
  
  return (
    <Stack direction="column" gutterSize={0.5}>
      {Boolean(label) && <FormLabel>{label}</FormLabel>}
      <Wrapper disabled={disabled}>
        <ReactDatePicker
          value={value ? newValueByType : null}
          onChange={value => {
            if (value === null) {
              return onChange(undefined);
            }

            onChange(new Date(value?.setHours(12)));
          }}
          maxDate={maxDate}
          minDate={minDate}
          locale={'en-GB'}
          format="dd/MM/yyyy"
          disabled={disabled}
          clearIcon={
            isClearable === true && (
              <Icon variant="light">
                <MdClose />
              </Icon>
            )
          }
          calendarIcon={
            <Icon disabled={disabled}>
              <MdToday />
            </Icon>
          }
          {...props}
        />
        <DatesPickerStyles />
      </Wrapper>
    </Stack>
  );
};

export default DatePicker;

export const DateField = ({ datePlaceholder, showPlaceHolder, ...props }) => {
  const [id] = useState(uniqueId());
  return (
    <>
      <Field {...props}>
        {({ field, form: { setFieldValue } }) => (
          <>
            {Boolean(datePlaceholder) && showPlaceHolder && (
              <Stack
                alignX="space-between"
                alignY="center"
                marginBottom={0.5}
                size="full"
              >
                <FormLabel variant="bare" htmlFor={id}>
                  {datePlaceholder}
                </FormLabel>
              </Stack>
            )}
            <DatePicker
              {...props}
              {...field}
              onChange={value => {
                setFieldValue(field.name, value);
              }}
              id={id}
              actionOnfocus={props.actionOnfocus}
            />
          </>
        )}
      </Field>
    </>
  );
};
