import { useParams, Link, useNavigate } from 'react-router-dom';
import { gql, useQuery, NetworkStatus, useMutation } from '@apollo/client';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Stack, SegmentedControl, Button, Avatar } from '@koob/margaret';
import { Text, Card, Pill } from 'ui';
import { useSearchParams, useSnack, useError } from 'hooks';
import { pickBy, upperFirst } from 'lodash';
import DateRange from 'components/DateRange';
import { useEffectOnce } from 'react-use';
import EmptyState from 'components/EmptyState';
import { formatDate, formatImageLinkForCss } from 'utils';
import { MdRotateLeft } from 'react-icons/md';
import { DUPLICATE_CONTRACT } from 'api/contracts';

export const GET_CONTRACTS = gql`
  query getContracts(
    $hotelId: ID!
    $stateFilter: StateFilter
    $endCursor: String
  ) {
    contracts(
      hotelId: $hotelId
      stateFilter: $stateFilter
      first: 10
      after: $endCursor
    ) {
      edges {
        node {
          id
          status
          startAt
          endAt
          updatedAt
          lastModifier {
            id
            avatarUrl(size: MEDIUM)
            firstName
            lastName
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

const ContractsWrapper = styled(Card)`
  max-width: 600px;
  width: 100%;
`;

const StatusPill = styled(Pill)`
  margin-left: ${({ theme }) => theme.spacing()};
  box-shadow: none;
  border: none;
  font-weight: normal;
  cursor: default;

  ${({ status }) =>
    status === 'active' &&
    css`
      color: ${({ theme }) => theme.green};
      background-color: ${({ theme }) => theme.greenLight};
    `};

  ${({ status }) =>
    status === 'draft' &&
    css`
      color: ${({ theme }) => theme.red};
      background-color: ${({ theme }) => theme.redLighter};
    `};

  ${({ status }) =>
    status === 'published' &&
    css`
      color: ${({ theme }) => theme.blue};
      background-color: ${({ theme }) => theme.blueLighter};
    `};
`;

const FilterWrapper = styled(Stack).attrs({ gutterSize: 1 })`
  max-width: 600px;
  width: 100%;
`;

const ContractsList = ({ canUpdateHotel }) => {
  const { t } = useTranslation('contracts');
  const { hotelId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const { status, contractState } = searchParams;

  const statusOptions = [
    { value: null, label: t('state.all') },
    { value: 'published', label: t('state.published') },
    { value: 'draft', label: t('state.draft') },
  ];

  const contractStateOptions = [
    { value: 'available', label: t('availableContracts') },
    { value: 'expired', label: t('expiredContracts') },
  ];

  const { data, fetchMore, networkStatus } = useQuery(GET_CONTRACTS, {
    variables: pickBy({
      hotelId,
      stateFilter: {
        status: contractState === 'expired' ? 'published' : status,
        state: contractState,
      },
    }),
    fetchPolicy: 'no-cache',
  });

  const contracts = data?.contracts?.edges ?? [];
  const isEmpty =
    networkStatus === NetworkStatus.ready && (contracts || []).length === 0;

  const handleLoadNextPage = () => {
    try {
      fetchMore({
        variables: {
          endCursor: data?.contracts?.pageInfo?.endCursor,
        },
      });
    } catch (err) {}
  };

  useEffectOnce(() => {
    setSearchParams({
      ...searchParams,
      contractState: 'available',
    });
  });

  const [duplicateContract] = useMutation(DUPLICATE_CONTRACT);
  const navigate = useNavigate();
  const { notify } = useSnack();
  const { sendErrorSnack } = useError();

  const handleRenew = async (e, contract) => {
    e.preventDefault();

    try {
      const contractId = contract.id;

      const { data } = await duplicateContract({
        variables: {
          input: {
            contractId: contractId,
          },
        },
      });

      if (data?.duplicateContract?.errors?.length > 0) {
        throw data?.duplicateContract?.errors?.[0];
      }

      navigate(`../contracts/${data?.duplicateContract?.contract?.id}`);
      notify(t('duplicateContractSuccess'));
    } catch (error) {
      sendErrorSnack(error);
    }
  };

  return (
    <>
      <FilterWrapper>
        <SegmentedControl
          style={{ backgroundColor: '#fff' }}
          options={contractStateOptions}
          value={contractState ?? 'available'}
          onSelect={value =>
            setSearchParams({
              ...searchParams,
              contractState: value,
            })
          }
        />
      </FilterWrapper>
      <ContractsWrapper>
        <Stack alignX="space-between" paddingBottom={1}>
          <Text type="h3">{t('title')}</Text>
          {contractState === 'expired' ? null : (
            <SegmentedControl
              disabled={status === undefined && isEmpty}
              options={statusOptions}
              value={status ?? null}
              onSelect={value =>
                setSearchParams({
                  ...searchParams,
                  status: value,
                })
              }
            />
          )}
        </Stack>

        <Stack size="full" direction="column" gutterSize={1}>
          {isEmpty && (
            <EmptyState variant="full">
              {t('emptyContracts', { period: contractState })}
            </EmptyState>
          )}
          {(contracts ?? []).map(({ node }, index) => (
            <Card
              size="full"
              style={{ padding: 0, paddingBottom: 16 }}
              key={node?.id}
              as={canUpdateHotel ? Link : null}
              to={canUpdateHotel ? `${node?.id}` : null}
              id={`contrat_${index}`}
            >
              <Stack
                gutterSize={0.5}
                alignY="center"
                padding={0.75}
                size="full"
              >
                <DateRange startAt={node?.startAt} endAt={node?.endAt} />
                <StatusPill status={node?.status}>
                  {upperFirst(node?.status)}
                </StatusPill>
                {node?.status === 'published' && (
                  <Button
                    variant={'warning'}
                    onClick={e => handleRenew(e, node)}
                    style={{ height: 20, width: 90, marginLeft: 80 }}
                    icon={<MdRotateLeft size={20} />}
                  >
                    {t('Renew')}
                  </Button>
                )}
              </Stack>

              {Boolean(node?.lastModifier) && (
                <Stack
                  paddingHorizontal={0.75}
                  gutterSize={0.5}
                  alignY="center"
                >
                  <Avatar
                    imageUrl={formatImageLinkForCss(
                      node?.lastModifier?.avatarUrl,
                    )}
                    firstName={node?.lastModifier?.firstName}
                    lastName={node?.lastModifier?.lastName}
                  />
                  <Stack direction="column" alignY="center">
                    <Text>
                      {node?.lastModifier?.firstName}{' '}
                      {node?.lastModifier?.lastName}
                    </Text>
                    <Text color="textLighter" type="bodySmall">
                      Last edited{' '}
                      {formatDate(node?.updatedAt, 'dd MMM yyyy - h:maaa')}
                    </Text>
                  </Stack>
                </Stack>
              )}
            </Card>
          ))}
        </Stack>
        {data?.contracts?.pageInfo?.hasNextPage && (
          <Stack alignX="center" paddingTop={1}>
            <Button variant="simple" onClick={handleLoadNextPage}>
              {t('misc:seeMore')}
            </Button>
          </Stack>
        )}
      </ContractsWrapper>
    </>
  );
};

export default ContractsList;
