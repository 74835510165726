import { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { includes } from 'lodash';
import { Dropdown, Stack, ButtonReset } from '@koob/margaret';
import {
  PopoverContainer,
  PopoverMenu as RawPopoverMenu,
  PopoverItem,
  PopoverItemButton,
  Box,
} from '@koob/margaret';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { IcArrowDown } from 'components/icons';
import Scrollbars from 'react-custom-scrollbars';
import { useDeepCompareEffect } from 'react-use';
import Search from 'components/Search';

const PopoverMenuInner = styled(Scrollbars)``;

const PopoverMenu = styled(RawPopoverMenu)`
  max-height: none;
  overflow-y: initial;
`;

const PopoverFooter = styled(Stack)``;

const Icon = styled(ButtonReset)`
  display: flex;

  ${({ disabled, theme }) =>
    disabled &&
    `
      color: ${theme.disabled};
    `}
`;

const TagWrapper = styled(Stack).attrs({ alignY: 'center', gutterSize: 0.25 })`
  border: 1px solid ${({ theme }) => theme.textLighter};
  border-radius: 999em;
  padding: ${({ theme }) => theme.spacing(0.125)}
    ${({ theme }) => theme.spacing(0.75)};
  color: ${({ theme }) => theme.textLight};
  font-size: 14px;

  ${({ disabled, theme }) =>
    disabled &&
    `
      color: ${theme.disabled};
      border-color: ${theme.disabled};
    `}
`;

const Trigger = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  max-width: 100%;
  min-width: 176px;
  min-height: 36px;
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadow};
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid ${({ theme }) => theme.separator};
  flex: 1;
  border-radius: 4px;
  transition: border-color 100ms ease;
  z-index: 1;

  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
    color: #6d6d6d;
  }

  ${({ disabled }) =>
    Boolean(disabled) &&
    css`
      background-color: ${({ theme }) => theme.disabled};
      color: #6d6d6d;
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      &,
      &:hover,
      &:active {
        border-color: ${({ theme }) => theme.danger};
      }
    `}
`;

const Value = styled.div`
  ${({ isEmpty, theme }) => css`
    width: 100%;
    color: ${({ theme }) => theme.text};
    font-weight: 400;
    font-style: normal;

    &,
    + svg {
      color: ${isEmpty ? theme.textLighten : theme.text};
    }
  `};
`;

const SelectedValues = styled(Stack).attrs({ alignY: 'center' })`
  margin: ${({ theme }) => theme.spacing(-0.25)};
  flex-wrap: wrap;

  > * {
    margin: ${({ theme }) => theme.spacing(0.25)};
    width: auto;
  }
`;

const LocalMultiSearchableSelect = ({
  onChange,
  disabled,
  variant,
  wrapperStyle,
  isSearch,
  popoverFooter,
  renderOption,
  renderSelectedOption,
  emptyState,
  hasError,
  excludedValues,
  value,
  selectedValue,
  onSelectOption,
  onUnselectOption,
  optionList,
  shouldShowSelectedValues = true,
}) => {
  const dropdownRef = useRef();
  const popoverRef = useRef();
  const searchRef = useRef();
  const [isOpen, setIsOpen] = useState();
  const [search, setSearch] = useState('');

  const options = (
    isSearch && search.length === 0
      ? optionList
      : optionList.filter(item =>
          item.label?.toLowerCase().includes(search.toLowerCase()),
        )
  ).filter(option => !includes(excludedValues, option.value));

  useDeepCompareEffect(() => {
    if (!Boolean(selectedValue) || !onChange) {
      return;
    }

    onChange(selectedValue);
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  }, [{ value }]);

  useEffect(() => {
    setSearch('');

    if (!isOpen || !Boolean(searchRef.current)) {
      return;
    }

    searchRef.current.focus();
  }, [isOpen]);

  return (
    <Dropdown
      disabled={disabled}
      ref={dropdownRef}
      wrapperStyle={wrapperStyle}
      onToggle={setIsOpen}
      trigger={
        <Trigger disabled={disabled} hasError={hasError} isOpen={isOpen}>
          <SelectedValues>
            {shouldShowSelectedValues &&
              (value ?? []).map((item, index) => (
                <Value key={index} variant={variant}>
                  <TagWrapper>
                    <span>{renderSelectedOption(item)}</span>
                    {!disabled && (
                      <Icon
                        type="button"
                        onClick={() => {
                          onUnselectOption(item);
                        }}
                      >
                        <IoCloseCircleOutline size={18} />
                      </Icon>
                    )}
                  </TagWrapper>
                </Value>
              ))}
          </SelectedValues>
          <Stack>
            <IcArrowDown size={24} />
          </Stack>
        </Trigger>
      }
    >
      <PopoverContainer variant={variant}>
        <PopoverMenu>
          <PopoverMenuInner ref={popoverRef} autoHeight autoHeightMax={250}>
            <PopoverItem>
              <Box
                paddingTop={1}
                paddingHorizontal={1}
                paddingBottom={options.length === 0 ? 1 : 0.5}
              >
                <Search ref={searchRef} value={search} onChange={setSearch} />
              </Box>
            </PopoverItem>
            {options.map((option, index) => (
              <PopoverItem key={index}>
                <PopoverItemButton
                  type="button"
                  onClick={() => onSelectOption(option)}
                  isActive={includes(value, option)}
                >
                  {renderOption(option)}
                </PopoverItemButton>
              </PopoverItem>
            ))}
          </PopoverMenuInner>

          {(Boolean(popoverFooter) ||
            (Boolean(emptyState) && options.length === 0)) && (
            <PopoverFooter>{popoverFooter}</PopoverFooter>
          )}
        </PopoverMenu>
      </PopoverContainer>
    </Dropdown>
  );
};

LocalMultiSearchableSelect.defaultProps = {
  placeholder: '',
  excludedIds: [],
  renderOption: () => null,
  renderSelectedValue: () => null,
};

export default LocalMultiSearchableSelect;
