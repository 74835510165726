import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Stack } from '@koob/margaret';
import { Text } from 'ui';
import { TextField } from 'components/Fields';
import { Button } from 'components';

const SettingRequestForm = ({
  onSubmit,
  settingRequest,
  disabledCreate = false,
}) => {
  const { t } = useTranslation('settingRequest');

  return (
    <Formik
      initialValues={{
        displayName: settingRequest?.displayName ?? '',
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        displayName: Yup.string().required(t('errors:required')),
      })}
    >
      {({ isSubmitting }) => (
        <Form>
          <Stack direction="column" gutterSize={1} size="full" alignX="stretch">
            <Stack alignX="space-between" alignY="center" gutterSize={1}>
              <Text type="h1">{t('newSettingRequest')}</Text>
            </Stack>
            <TextField label={t('name')} name="displayName" />
            <Stack gutterSize={1}>
              <Button
                type="submit"
                variant="primary"
                isLoading={isSubmitting}
                disabled={disabledCreate}
              >
                {t('misc:saveChanges')}
              </Button>
              <Button variant="simple" type="button" to="/settings/hotels/">
                {t('misc:cancel')}
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default SettingRequestForm;
