import { useTranslation } from 'react-i18next';
import { TextAreaField } from 'components/Fields';
import { Stack } from '@koob/margaret';
import { Form, Formik } from 'formik';
import { Button } from 'components';

const DenyExplainationForm = ({ onSubmit, initialValues }) => {
  const { t } = useTranslation('settingRequest');

  return (
    <Formik
      initialValues={
        initialValues ?? {
          reason: '',
        }
      }
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <Stack direction="column" gutterSize={1}>
            <TextAreaField label={t('reason')} name="reason" />
          </Stack>

          <Stack marginTop={2} size="full" alignX="flex-end">
            <Button
              type="submit"
              variant="primary"
              style={{ minWidth: 120, minHeight: 40, marginLeft: 25 }}
            >
              {t('misc:save')}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default DenyExplainationForm;
