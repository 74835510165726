import { media, Stack } from '@koob/margaret';
import styled from 'styled-components';
import React, { useState } from 'react';
import ExperienceTypeIcon from '../Partials/ExperienceTypeIcon';
import { useTranslation } from 'react-i18next';
import TypologyButton from '../Partials/TypologyButton';
import { EXPERIENCE_TYPOLOGY_SCHEMA } from '../Utilities/ExperienceValidationSchemas';
import { useField } from 'formik';
import { Button } from '../../../../components';
import { useSnack } from '../../../../hooks';
import { get, post } from 'api/node';
import LocationSearchableSelectNoQuery from './LocationSearchableSelectNoQuery';

const getList = async ({ search = '' }) => {
  return await post('/experiences/liste', {
    search,
  });
};

const getExperience = async id => {
  return await get(`/experiences/${id}`);
};

const Separator = styled.div`
  width: 1px;
  height: 60%;
  background-color: ${({ theme }) => theme.separatorLight};

  ${media.desktop`
    width: 60%;
    height: 2px;
  `};
`;

export const TypologyItem = ({
  selected,
  onSelect,
  type,
  name,
  description,
}) => {
  return (
    <>
      <div className="w-48 flex flex-col items-center">
        <TypologyButton
          selected={selected}
          onSelect={() => onSelect(type)}
          icon={<ExperienceTypeIcon type={type} />}
          name={name}
        />

        <div className="mt-3 text-sm text-center text-gray-500">
          {description}
        </div>
      </div>
    </>
  );
};

export const ExperienceTypology = ({ onSelectExperience }) => {
  const { t } = useTranslation('experiences');
  const [{ value }, , { setValue }] = useField('type');

  const { notify } = useSnack();

  const handleImport = async experienceId => {
    onSelectExperience((await getExperience(experienceId))?.data);
  };
  const [experienceId, setExperienceId] = useState(null);

  return (
    <>
      <Stack gutterSize={1.5} alignY="center" padding={6} size="full">
        <div className="w-full flex items-start justify-around">
          <TypologyItem
            selected={value === 'Transfer'}
            onSelect={() => setValue('Transfer')}
            type="Transfer"
            name={t('types.transfer')}
            description={t('typology.transferDescription')}
          />

          <TypologyItem
            selected={value === 'Program'}
            onSelect={() => setValue('Program')}
            type="Program"
            name={t('types.program')}
            description={t('typology.programDescription')}
          />

          <TypologyItem
            selected={value === 'Activity'}
            onSelect={() => setValue('Activity')}
            type="Activity"
            name={t('types.extraAndActivity')}
            description={t('typology.extraAndActivityDescription')}
          />
        </div>
        <Stack alignX="center" padding={1}>
          <Separator />
        </Stack>
      </Stack>

      <div className="max-w-2xl mx-auto flex justify-center items-end space-x-3">
        <div className="w-96 v">
          <div className="mb-2 font-medium text-gray-600">
            {t(`startFromExisting`)}
          </div>

          <LocationSearchableSelectNoQuery
            query={getList}
            renderSelectedOption={value => value?.name}
            onSelect={value => {
              setExperienceId(value?.id);
            }}
            wrapperStyle={{
              marginLeft: 0,
              width: '100%',
              marginBottom: 16,
              height: 20,
              '--location-search-trigger-height': `${20}px`,
            }}
            inputStyle={{ width: '100%', height: 20 }}
          />
        </div>

        <div>
          <Button
            disabled={!experienceId}
            variant="primary"
            onClick={() => {
              handleImport(experienceId).then(() => notify(t('importSuccess')));
            }}
          >
            {t('misc:import')}
          </Button>
        </div>
      </div>
    </>
  );
};

export const getSchema = () => EXPERIENCE_TYPOLOGY_SCHEMA;
