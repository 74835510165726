import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Stack } from '@koob/margaret';
import { Container } from 'ui';
import { GET_HOTEL_KEEPER_ADMIN_BOOKING } from 'api/hotelKeeper';
import {
  BookingRecap,
  SubHeader,
  RespondBooking,
} from 'components/HotelKeeper';
import HotelKeeperDocuments from './Partials/HotelKeeperDocuments';
import { quickUpdateBooking } from 'api/node/bookings';
import { useError } from 'hooks';
import { useState } from 'react';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Space = styled.div`
  flex: 1;
`;

const Logo = styled.img`
  margin: auto;
  width: 8.5rem;
  height: auto;
`;

const Header = styled(Stack).attrs({
  alignY: 'center',
})`
  padding: ${({ theme }) => theme.spacing(1.5)}
    ${({ theme }) => theme.spacing(2)};
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
`;

const HotelKeeper = () => {
  const { secret } = useParams();
  const { sendErrorSnack } = useError();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data, refetch } = useQuery(GET_HOTEL_KEEPER_ADMIN_BOOKING, {
    variables: {
      secret,
    },
  });
  const booking = data?.hotelKeeperAdminBooking;
  const organization = booking?.hotel?.organization;

  const handleSubmit = async values => {
    if (!secret) {
      return;
    }
    setIsSubmitting(true);
    try {
      await quickUpdateBooking({
        secret: secret,
        body: {
          hotelConfirmationNumber: values.hotelConfirmationNumber,
        },
      });
      refetch();
    } catch (error) {
      sendErrorSnack(error);
    }
    setIsSubmitting(false);
  };

  return (
    <Wrapper>
      {organization?.avatarUrl && organization.avatarUrl.trim() !== '' && (
        <Header>
          <Logo src={organization.avatarUrl} alt={organization.displayName} />
        </Header>
      )}

      <SubHeader booking={booking} kind="hotelKeeper" />
      <Container>
        <BookingRecap
          booking={booking}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          hotelKeeper
        />

        <HotelKeeperDocuments bookingId={booking?.id} secret={secret} />
      </Container>
      <Space />
      {booking?.state === 'sent' && (
        <RespondBooking refetch={refetch} booking={booking} />
      )}
    </Wrapper>
  );
};

export default HotelKeeper;
