import { useTranslation } from 'react-i18next';
import BulkEditor from './BulkEditor';
import LocationAwareSearch from '../../../../components/LocationAwareSearch';
import FilterHotels from '../../../../components/FilterHotels';
import { isEqual } from 'lodash';
import { useSearchParams } from '../../../../hooks';
import { useAsync } from 'react-async';
import { getHotels } from 'api/node/hotels';
import { useEffect, useState } from 'react';
import { Paginator } from 'components/Paginator';
import { OrderColumnEnum, OrderDirectionEnum } from '@koob/enums';
import { getImageUrl } from '@koob/utils';

export default function BulkEditorHotels() {
  const { t } = useTranslation('toConnectionRequest');
  const [searchParams] = useSearchParams();
  const { search, kindsIds, searchLocation} = searchParams;

  const stars = Boolean(searchParams.stars) ? Number(searchParams.stars) : null;
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState();
  const countries = searchLocation?.kind === 'country'? [searchLocation?.id] : [];
  const regions = searchLocation?.kind === 'region'? [searchLocation?.id] : [];
  const kinds =  Boolean(kindsIds)
  ? (kindsIds ?? []).map(({ value }) => value)
  : undefined

  const requestFilters= {
    kinds,
    countries,
    regions,
    search,
    stars,
    perPage: perPage,
    page,
    direction: OrderDirectionEnum.DESC,
    order: OrderColumnEnum.UPDATED_AT
  };

  useEffect(() => {
    setPage(1);
  }, [search, searchLocation, kindsIds, stars])

  const { data: hotels, loading } = useAsync({
    promiseFn: getHotels,
    filters: requestFilters,
    locale: 'en',
    watchFn: (props,prevProps) => {
      return !isEqual(props.filters,prevProps.filters) || !isEqual(props.locale,prevProps.locale)
    },
    onResolve: ((response) => {
      setTotalPages(response?.headers?.xTotalPages)
    })
  });

  const totalCount = hotels?.headers?.xTotalCount;
  const hotelList = hotels?.data?.data ?? [];

  return (
    <BulkEditor
      title={t('bulkEdit.editor.hotelsTitle')}
      filters={
        <>
          <LocationAwareSearch name="search" />

          <FilterHotels />
        </>
      }
      loading={loading}
      results={
        (hotelList || []).map((hotel) => ({
          id: hotel.id,
          idRails: hotel.koobId,
          image: getImageUrl(hotel),
          name: hotel.displayName,
          description: hotel.address,
          stars: hotel.stars,
        }))
      }
      type="hotel"
      setPage={setPage}
      page={page}
      totalCount={totalCount}
      perPage={perPage}
      setTotalPages={setTotalPages}
      totalPages={hotels?.headers?.xTotalPages}
    >
      {hotelList.length > 0 && (
        <Paginator
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      )}
    </BulkEditor>
  );
}
