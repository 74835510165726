import { useField } from 'formik';
import { SegmentedControl, Stack } from '@koob/margaret';
import { FormLabel, ErrorMessageWrapper, FormField } from 'ui/forms';

const SegmentedControlField = ({
  label,
  options,
  touchRequired = true,
  required,
  ...props
}) => {
  const [field, meta, { setValue }] = useField(props);

  const hasError =
    (!touchRequired || Boolean(meta.touched)) && Boolean(meta.error);

  return (
    <FormField style={{ flex: 0 }}>
      {label && (
        <FormLabel>
          {label}
          {required && <span className="ml-1 text-red-500">*</span>}
        </FormLabel>
      )}
      <Stack>
        <SegmentedControl
          options={options}
          onSelect={setValue}
          {...field}
          {...props}
          hasError={hasError}
        />
      </Stack>
      {hasError && <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>}
    </FormField>
  );
};

export default SegmentedControlField;
