import { useEffect, useState } from 'react';
import AllotmentsHotelSelector from './Partials/AllotmentsHotelSelector';
import AllotmentsEditor from './Partials/AllotmentsEditor';
import { useSearchParams } from '../../hooks';
import { useQuery } from '@apollo/client';
import { GET_HOTEL } from '../../api/hotel';
import { formatHotelResult } from './Partials/allotmentsUtils';

export default function Allotments() {
  const [searchParams] = useSearchParams();
  const hotelId = searchParams?.hotelId;

  const [hotel, setHotel] = useState(null);

  const { data: hotelData } = useQuery(GET_HOTEL, {
    variables: {
      id: hotelId,
    },
  });

  useEffect(() => {
    if (hotelData) {
      setHotel(formatHotelResult(hotelData.node));
    }
  }, [hotelData]);

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="pb-16 flex-col space-y-5">
        <AllotmentsHotelSelector
          hotel={hotel}
          setHotel={setHotel}
        />

        {hotel && (
          <AllotmentsEditor hotel={hotel} />
        )}
      </div>
    </div>
  );
}
