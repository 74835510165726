import { Stack } from '@koob/margaret';
import { useField } from 'formik';
import styled from 'styled-components';
import { MdToday } from 'react-icons/md';
import DatePicker from './DatePicker';
import { FormLabel, ErrorMessageWrapper, FormField } from 'ui/forms';
import { formatDate } from 'utils';

const DisabledDate = styled(Stack).attrs({ alignY: 'center', gutterSize: 2 })`
  width: max-content;
  padding: ${({ theme }) => theme.spacing(0.75)}
    ${({ theme }) => theme.spacing(0.5)};
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  line-height: 0;
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.disabled};
  color: ${({ theme }) => theme.textLight};
`;

const DatePickerField = ({ placeholder, label, name, ...props }) => {
  const [field, meta, { setValue }] = useField({ name });
  const hasError = Boolean(meta.error && meta.touched);

  return (
    <FormField>
      <Stack direction="column" size="full" alignX="stretch" gutterSize={0.5}>
        {Boolean(label) && <FormLabel variant="bare">{label}</FormLabel>}

        {!props?.disabled ? (
          <DatePicker
            hasError={hasError}
            placeholder={placeholder}
            {...field}
            onChange={setValue}
            {...props}
          />
        ) : (
          <DisabledDate>
            <div>{formatDate(field?.value, 'dd/MM/yyyy')}</div>
            <div>
              <MdToday color="textLight" size={16} />
            </div>
          </DisabledDate>
        )}

        {hasError && <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>}
      </Stack>
    </FormField>
  );
};

export default DatePickerField;
