import { gql, useQuery } from '@apollo/client';
import { useApp, useSearchParams } from '../../../hooks';
import { isNil, pickBy } from 'lodash';
import LocationAwareSearch from '../../../components/LocationAwareSearch';
import FilterHotels from '../../../components/FilterHotels';
import KoobTitle from '../../../components/Koob/KoobTitle';
import KoobParagraph from '../../../components/Koob/KoobParagraph';
import KoobCard from '../../../components/Koob/KoobCard';
import { useTranslation } from 'react-i18next';
import AllotmentsHotelSelectorItem from './AllotmentsHotelSelectorItem';
import KoobPlaceholder from '../../../components/Koob/KoobPlaceholder';
import { formatHotelResult } from './allotmentsUtils';

const GET_HOTELS = gql`
  query getHotels(
    $endCursor: String
    $stars: Int
    $search: String
    $order: HotelOrderCriteria
    $locationId: ID
    $kindsIds: [ID!]
    $state: String
    $boundingBox: HotelBoundingBox
    $discarded: Boolean
  ) {
    hotels(
      first: 100
      after: $endCursor
      stars: $stars
      displayName: $search
      order: $order
      locationId: $locationId
      kindsIds: $kindsIds
      state: $state
      boundingBox: $boundingBox
      discarded: $discarded
    ) {
      edges {
        node {
          id
          displayName
          address
          stars
          adminBookingsCount
          lat
          lon
          canReadHotel
          canUpdateHotel
          canChangeStateHotel
          showcased
          connectedChannelManager
          primaryAttachment {
            id
            imageUrl(size: MEDIUM)
          }
          kinds {
            nodes {
              id
              displayName
            }
          }
          customCategories {
            nodes {
              id
              displayName
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        startCursor
        hasNextPage
        endCursor
      }
    }
  }
`;

export default function AllotmentsHotelSelector({ hotel, setHotel }) {
  const { t } = useTranslation('allotments');
  const { currentOrganizationId } = useApp();
  const [searchParams] = useSearchParams();
  const { search, sort, order, kindsIds, searchLocation, state } = searchParams;

  const stars = Boolean(searchParams.stars) ? Number(searchParams.stars) : null;

  const { data, loading } = useQuery(GET_HOTELS, {
    variables: pickBy(
      {
        order: Boolean(sort)
          ? {
              column: sort,
              direction: order || 'DESC',
            }
          : null,
        search,
        stars,
        state,
        locationId: searchLocation?.id,
        kindsIds: Boolean(kindsIds)
          ? (kindsIds ?? []).map(({ value }) => value)
          : undefined,
        organizationId: currentOrganizationId,
        discarded: false,
      },
      value => !isNil(value),
    ),
  });

  const results =
    data?.hotels?.edges
      ?.map(({ node }) => (formatHotelResult(node)))
      ?.filter(h => h.id !== hotel?.id) ?? [];

  return (
    <KoobCard>
      <KoobTitle>{t('hotelSelector.title')}</KoobTitle>
      <KoobParagraph>{t('hotelSelector.description')}</KoobParagraph>

      <div className="my-5 flex space-x-3 items-center">
        <LocationAwareSearch name="search" />

        <FilterHotels />
      </div>

      <div className="h-[25vh] overflow-scroll bg-gray-50 p-5 rounded-md">
        <div className="grid md:grid-cols-2 gap-3">
          {hotel && (
            <AllotmentsHotelSelectorItem item={hotel} isSelected={true} />
          )}

          {results?.map(item => (
            <AllotmentsHotelSelectorItem
              key={item.id}
              item={item}
              isSelected={hotel?.id === item.id}
              onSelect={() => setHotel(item)}
            />
          ))}

          {loading && (
            <KoobPlaceholder className="h-24 w-full rounded" count={8} />
          )}
        </div>
      </div>
    </KoobCard>
  );
}
