import { ApolloClient, ApolloProvider, from, Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import cache from 'cache';
import { refreshToken } from 'api/auth';
import { onError } from 'apollo-link-error';
import { includes } from 'lodash';
import { useAuth } from 'hooks';

const API_BASE = process.env.REACT_APP_API_BASE_URL;

const uploadLink = createUploadLink({
  uri: `${API_BASE}/api/graphql`,
});

// const localeLink = new ApolloLink((operation, forward) => {
//   operation.variables.locale = localStorage.getItem('queryLocale') || 'en';
//   return forward(operation);
// });

const GQLProvider = ({ children }) => {
  const { accessToken, onUpdate, logout } = useAuth();

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: Boolean(accessToken) ? `Bearer ${accessToken}` : '',
    },
  }));

  const refreshTokenLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
    if (networkError && networkError.statusCode === 401 && networkError.result?.error === 'Invalid access token') {
      logout();
      return;
    }

    if (
      includes(
        (graphQLErrors || []).map(
          graphQLError => graphQLError?.extensions?.code,
        ),
        'unauthorized',
      )
    ) {
      const promiseToObservable = promise =>
        new Observable(subscriber => {
          promise.then(
            value => {
              if (subscriber.closed) {
                return;
              }
              subscriber.next(value);
              subscriber.complete();
            },
            err => {
              logout();
              subscriber.error(err);
            },
          );
          return subscriber;
        });

      return promiseToObservable(refreshToken()).flatMap(({ data }) => {
        const { accessToken, refreshToken } = data;
        onUpdate({ accessToken, refreshToken });
        operation.setContext((_, { headers }) => ({
          headers: {
            ...headers,
            authorization: Boolean(accessToken) ? `Bearer ${accessToken}` : '',
          },
        }));
        return forward(operation);
      });
    }
  });

  const client = new ApolloClient({
    link: from([authLink, refreshTokenLink, uploadLink]),
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GQLProvider;
