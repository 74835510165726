import { Stack } from '@koob/margaret';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'components';
import { TextAreaField } from 'components/Fields';

const ConfirmAdminBookingForm = ({ onSubmit, onClose }) => {
  const { t } = useTranslation('bookings');

  return (
    <Formik
      initialValues={{ hotelKeeperMessage: '' }}
      validationSchema={Yup.object().shape({
        hotelKeeperMessage: Yup.string(),
      })}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Stack size="full" direction="column" paddingTop={1} gutterSize={2}>
            <TextAreaField
              name="hotelKeeperMessage"
              label={t('reason')}
              labelRight={t('misc:optional')}
            />
            <Stack size="full" alignX="flex-end" gutterSize={1}>
              <Button type="button" variant="simple" onClick={onClose}>
                {t('misc:cancel')}
              </Button>
              <Button variant="primary" isLoading={isSubmitting} type="submit">
                {t('hotelKeeper:submit.confirm')}
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default ConfirmAdminBookingForm;
