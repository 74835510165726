import {
  ButtonReset,
  Dropdown,
  PopoverContainer,
  PopoverMenu,
  Stack,
} from '@koob/margaret';
import Button from 'components/Button';
import { IcArrowDown } from 'components/icons';
import { FastField, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { MdDelete } from 'react-icons/md';
import FastTextField from './FastTextField';
import SegmentedControlField from './SegmentedControlField';
import { Trigger } from './Select';

const ExtraBedsDropdownField = ({ disabled }) => {
  const { t } = useTranslation('contracts');

  return (
    <FastField name="extraBeds">
      {({ field }) => (
        <Dropdown
          trigger={
            <Trigger>
              <div>{t('ageGroups', { count: field.value.length })}</div>
              <Stack>
                <IcArrowDown size={24} />
              </Stack>
            </Trigger>
          }
        >
          <PopoverContainer style={{ width: '25em' }}>
            <FieldArray
              name="extraBeds"
              render={({ push, remove }) => (
                <PopoverMenu>
                  <Stack direction="column" padding={0.75} gutterSize={0.5}>
                    {(field?.value ?? []).map((_, index) => (
                      <Stack gutterSize={0.5} alignY="center">
                        <SegmentedControlField
                          name={`extraBeds.${index}.operand`}
                          options={[
                            { label: t('below'), value: 'below' },
                            { label: t('above'), value: 'above' },
                          ]}
                          disabled={disabled}
                        />
                        <FastTextField
                          name={`extraBeds.${index}.age`}
                          disabled={disabled}
                        />
                        <span>{t('yearsOldLong')}</span>
                        {(field?.value ?? []).length > 1 && (
                          <ButtonReset
                            onClick={(e) => {
                              e.preventDefault();
                              remove(index)
                            }}
                            disabled={disabled}
                          >
                            <MdDelete />
                          </ButtonReset>
                        )}
                      </Stack>
                    ))}

                    <Stack>
                      {!disabled && (
                        <Button
                          type="button"
                          onClick={() => push({ operand: 'above', age: '' })}
                          variant="simple"
                          disabled={disabled}
                        >
                          {t('addAgeGroup')}
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </PopoverMenu>
              )}
            />
          </PopoverContainer>
        </Dropdown>
      )}
    </FastField>
  );
};

export default ExtraBedsDropdownField;
