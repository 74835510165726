import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { Stack } from '@koob/margaret';
import { FormField, ErrorMessageWrapper, FormLabel } from 'ui/forms';
import { Avatar, Button } from 'components';
import { IcBin } from 'components/icons';
import { formatImageLinkForCss } from 'utils';

const AvatarField = ({
  label,
  name,
  url,
  size = 'medium',
  shape,
  forImage,
  variant,
  placeholder,
  disabled,
  ...props
}) => {
  const [imageUrl, setImageUrl] = useState();
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField({
    name,
  });
  const hasError = Boolean(touched) && Boolean(error);
  const theme = useTheme();
  const { t } = useTranslation('misc');

  const handleLoadFile = e => {
    setTouched(true);
    const reader = new FileReader();
    const [file] = e.target.files;
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    setValue(file);
  };

  const handleReset = () => {
    setValue(null);
    setImageUrl(null);
  };

  useEffect(() => {
    setImageUrl(url);
  }, [url]);

  return (
    <FormField>
      <Stack gutterSize={0.5}>
        <Stack>
          <Avatar
            imageUrl={formatImageLinkForCss(imageUrl)}
            size={size}
            shape={shape}
            variant={variant}
            icon={placeholder}
          />
        </Stack>
        {!disabled && (
          <Stack direction="column" gutterSize={0.25}>
            <FormLabel>{label}</FormLabel>
            <Stack alignY="center" gutterSize={0.5}>
              <Button as="label" variant="simple">
                {Boolean(forImage) ? t('importImage') : t('chooseFile')}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleLoadFile}
                  onClick={e => {
                    e.target.value = null;
                  }}
                  style={{ display: 'none' }}
                />
              </Button>
              {Boolean(value) && (
                <Button type="button" onClick={handleReset}>
                  <IcBin size={24} color={theme.textLighter} />
                </Button>
              )}
            </Stack>
            {hasError && <ErrorMessageWrapper>{error}</ErrorMessageWrapper>}
          </Stack>
        )}
      </Stack>
    </FormField>
  );
};

export default AvatarField;
