import AllotmentsEditorHeader from './AllotmentsEditorHeader';
import AllotmentsEditorRoom from './AllotmentsEditorRoom';
import KoobPlaceholder from '../../../components/Koob/KoobPlaceholder';
import { useField } from 'formik';
import AllotmentsEditorEditButton from './AllotmentsEditorEditButton';
import { useApp } from '../../../hooks';
import { useState } from 'react';
import { decodeId } from '../../../utils';
import AllotmentsEditorOrganizationSelector from './AllotmentsEditorOrganizationSelector';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

export default function AllotmentsEditorBody({ initialValues, loading, currentMonth, daysInMonth, selectedDays, setSelectedDays, reload }) {
  const { t } = useTranslation('contracts');
  const { currentOrganizationId } = useApp();
  const [{ value: rooms }] = useField('rooms');

  const currentOrganizationIdDecoded = decodeId(currentOrganizationId);

  const [showSharedAllotments, setShowSharedAllotments] = useState(true);
  const [organizationId, setOrganizationId] = useState(currentOrganizationIdDecoded);

  const canShowSharedAllotments = organizationId === currentOrganizationIdDecoded;

  const isDaySelected = (date, room) => {
    return selectedDays.some(d => d.roomId === room.id && d.day === date.format('YYYY-MM-DD'));
  };

  const toggleSelectDay = (date, room) => {
    const formattedDate = date.format('YYYY-MM-DD');

    if (isDaySelected(date, room)) {
      setSelectedDays(
        selectedDays.filter(d => d.day !== formattedDate || d.roomId !== room.id)
      );
    } else {
      setSelectedDays([
        ...selectedDays,
        { day: formattedDate, roomId: room.id }
      ]);
    }
  };

  const updatedDays = initialValues?.rooms?.map(item => {
    const findRoom = (rooms ?? []).find(room => room.id === item.id);
    return {
      ...item,
      organizations: findRoom ? findRoom?.organizations : item?.organizations
    }
  });

  return (
    <div className="my-3">
      <div className="bg-gray-50 border-t border-x border-gray-200 rounded-t-md">
        <div className="p-3 w-full flex justify-between">
          <AllotmentsEditorOrganizationSelector
            organizationId={organizationId}
            setOrganizationId={setOrganizationId}
          />

          <Form.Check
            inline
            label={t('showShared')}
            id="showShared"
            onChange={() => setShowSharedAllotments(!showSharedAllotments)}
            checked={showSharedAllotments}
            className="accent-orange-600 flex space-x-2 items-center"
            disabled={!canShowSharedAllotments}
          />

          <AllotmentsEditorEditButton
            selectedOrganizationId={organizationId}
            selectedDays={selectedDays}
            onApply={() => setSelectedDays([])}
            updatedDays={updatedDays}
          />
        </div>
      </div>

      <div className="overflow-x-auto rounded-md rounded-t-none border border-gray-200 overflow-hidden">
        <div className="min-h-[25vh] inline-block min-w-full">
          <div className="min-w-full divide-y divide-gray-200">
            <AllotmentsEditorHeader
              currentMonth={currentMonth}
              daysInMonth={daysInMonth}
              updatedDays={updatedDays}
            />

            {!loading ? (
              updatedDays?.map(room => (
                <AllotmentsEditorRoom
                  key={room.id}
                  organizationId={organizationId}
                  room={room}
                  currentMonth={currentMonth}
                  daysInMonth={daysInMonth}
                  toggleSelect={toggleSelectDay}
                  isSelected={isDaySelected}
                  showSharedAllotments={canShowSharedAllotments && showSharedAllotments}
                  reload={reload}
                />
              ))
            ) : (
              <KoobPlaceholder
                className="w-full h-16"
                count={3}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
