import { useTranslation } from 'react-i18next';

function LegendItem({ colorClass, label }) {
  return (
    <div className="flex space-x-1.5 items-center">
      <div
        className={[
          'h-5 w-12 rounded border',
          colorClass,
        ].join(' ')}
      />

      <div className="font-medium text-gray-500 text-sm">
        {label}
      </div>
    </div>
  )
}

export default function AllotmentsEditorLegend() {
  const { t } = useTranslation('allotments');

  return (
    <div className="flex space-x-5 justify-center">
      <LegendItem
        colorClass="bg-k-orange"
        label={t('modal.state.onRequest')}
      />

      <LegendItem
        colorClass="bg-emerald-400"
        label={t('modal.state.freeSales')}
      />

      <LegendItem
        colorClass="bg-green-600"
        label={t('modal.state.allotments')}
      />

      <LegendItem
        colorClass="bg-red-500"
        label={t('modal.state.stopSales')}
      />
    </div>
  );
}
