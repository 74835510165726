import { post, get } from './index';

export const exportExperience = ({
  experienceId,
  locale,
  exportFormat,
  options,
}) => {
  return post(
    `experiences/${experienceId}/export/${exportFormat}?locale=${
      locale ?? 'en'
    }`,
    options,
    { responseType: 'blob' },
    false,
  );
};

export const exportExperiencesCompatibilities = ({ filters }) => {
  return post(
    'experiences/export/experiencesCompatibilities',
    filters,
    {
      responseType: 'blob',
    },
    false,
  );
};

export const getExperiences = async ({ filters, locale, skip = false }) => {
  if (skip) {
    return Promise.resolve(null);
  }

  return await post(`/experiences/liste?locale=${locale ?? 'en'}`, filters);
};

export const getExperience = ({ experienceId, locale = 'en' }) => {
  if (!experienceId) {
    return Promise.resolve(null);
  }

  return get(`/experiences/${experienceId}?locale=${locale ?? 'en'}`);
};
