import styled, { css } from 'styled-components';
import { Text, CardTitle, CardListing, CardContentListing } from 'ui';
import { Link } from 'react-router-dom';
import { Stack } from '@koob/margaret';
import { getImageUrl } from '@koob/utils';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'components';
import { MdRemoveRedEye } from 'react-icons/md';
import { put } from 'api/node';
import { useApp, useSnack, useTripDesigner } from 'hooks';
import ReactTooltip from 'react-tooltip';
import { useError } from 'react-use';
import { useState } from 'react';
import { useField } from 'formik';
import KoobButton from 'components/Koob/KoobButton';
import IcLastMinute from 'components/icons/IcLastMinute';
import { formatCurrency, koobRole } from 'utils';
import OrganizationName from 'components/OrganizationName';

const Picture = styled.div`
  position: relative;
  padding-top: 56.25%;
  border-radius: 4px 4px 0 0;
  background: ${({ theme }) => theme.separator};
  background: ${({ imageUrl }) =>
    `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.75)), url(${imageUrl})`};
  background-size: cover;
  background-position: center center;
`;

const Tag = styled(Stack).attrs({
  gutterSize: 0.5,
  alignY: 'center',
})`
  background: #ffffff;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
  color: ${({ theme }) => theme.textLight};
  font-weight: 600;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  border-radius: 4px;
`;

const Tags = styled(Stack).attrs({
  gutterSize: 0.5,
  alignY: 'center',
})`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const Showcased = styled(Stack)`
  position: absolute;
  bottom: 38px;
  right: 16px;
  color: ${({ showcased, theme }) => (showcased ? theme.orange : theme.gray)};
  font-size: 24px;

  ${props =>
    !props.hasTag &&
    css`
      bottom: 10px;
    `}
`;

const ThematicTags = styled.div`
  width: 100%;
  height: 30px;
  background-color: #b284e2;
`;

const Duration = ({ durationDays, durationHours, durationMinutes }) => {
  const { t } = useTranslation('experiences');

  return (
    <Stack direction="row" gutterSize={0.3} alignY="center">
      <IcLastMinute size={20} />
      <div>
        {durationDays === 0 && durationHours === 0 && durationMinutes === 0
          ? t('durationUnitOptions.days', { count: durationDays })
          : null}{' '}
        {durationDays > 0
          ? t('durationUnitOptions.days', { count: durationDays })
          : null}{' '}
        {durationHours > 0
          ? t('durationUnitOptions.hours', { count: durationHours })
          : null}{' '}
        {durationMinutes > 0
          ? t('durationUnitOptions.minutes', { count: durationMinutes })
          : null}{' '}
      </div>
    </Stack>
  );
};

export const ExperienceCardContent = ({
  experience,
  variant,
  mode = 'list',
  onSelect,
  compatibility,
}) => {
  const { t } = useTranslation('experiences');
  const { notify } = useSnack();
  const { sendErrorSnack } = useError();
  const { selectedType } = useTripDesigner();
  const [showcased, setShowCased] = useState(experience?.showcased);
  const { currentUserRole } = useApp();
  const [{ value: experienceTrip }, ,] =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    mode === 'compose' ? useField(`days`) : [{}];

  const isSelectedExperienceInTrip =
    experienceTrip &&
    experienceTrip?.some(item => item?.experience?.id === experience?.id);

  const toggleShowcase = async e => {
    e.preventDefault();
    try {
      await put(`experiences/${experience?.id}/toggleShowcased`, {
        showcased: !showcased,
      });
      if (!showcased) {
        notify(t('toggleShowcaseTrueSuccess'));
      } else {
        notify(t('toggleShowcaseFalseSuccess'));
      }
      setShowCased(!showcased);
    } catch (error) {
      sendErrorSnack(error);
    }
  };

  const ExperienceTypeIcon = type => {
    switch (type) {
      case 'AIR':
        return <i className="far fa-plane mt-0.5"></i>;
      case 'LAND':
        return <i className="far fa-car mt-0.5"></i>;
      case 'RAIL':
        return <i className="far fa-train mt-0.5"></i>;
      case 'BOAT':
        return <i className="far fa-ship mt-0.5"></i>;
      case 'Program':
        return <i className="far fa-calendar-image fa-lg"></i>;
      case 'Activity':
        return (
          <div className="p-0.5">
            <svg className="h-4 w-4" viewBox="0 0 512 512">
              <path d="M234.2 4.672C241 1.592 248.5 0 256 0C263.5 0 270.1 1.592 277.8 4.672L495.2 102.1C505.4 106.7 512 116.8 512 128C512 139.2 505.4 149.3 495.2 153.9L277.8 251.3C270.1 254.4 263.5 256 256 256C248.5 256 241 254.4 234.2 251.3L16.76 153.9C6.561 149.3 .0003 139.2 .0003 128C.0003 116.8 6.561 106.7 16.76 102.1L234.2 4.672zM256 32C252.1 32 249.1 32.64 247.2 33.87L37.27 128L247.2 222.1C249.1 223.4 252.1 224 256 224C259 224 262 223.4 264.8 222.1L474.7 128L264.8 33.87C262 32.64 259 32 256 32V32zM78.6 219.9C82.22 228 78.61 237.5 70.55 241.1L37.27 256L247.2 350.1C249.1 351.4 252.1 352 256 352C259 352 262 351.4 264.8 350.1L474.7 256L441.5 241.1C433.4 237.5 429.8 228 433.4 219.9C437 211.9 446.5 208.3 454.5 211.9L495.2 230.1C505.4 234.7 512 244.8 512 256C512 267.2 505.4 277.3 495.2 281.9L277.8 379.3C270.1 382.4 263.5 384 256 384C248.5 384 241 382.4 234.2 379.3L16.76 281.9C6.561 277.3 0 267.2 0 256C0 244.8 6.561 234.7 16.76 230.1L57.46 211.9C65.52 208.3 74.99 211.9 78.6 219.9H78.6zM37.27 384L247.2 478.1C249.1 479.4 252.1 480 256 480C259 480 262 479.4 264.8 478.1L474.7 384L441.5 369.1C433.4 365.5 429.8 356 433.4 347.9C437 339.9 446.5 336.3 454.5 339.9L495.2 358.1C505.4 362.7 512 372.8 512 384C512 395.2 505.4 405.3 495.2 409.9L277.8 507.3C270.1 510.4 263.5 512 256 512C248.5 512 241 510.4 234.2 507.3L16.76 409.9C6.561 405.3 0 395.2 0 384C0 372.8 6.561 362.7 16.76 358.1L57.46 339.9C65.52 336.3 74.99 339.9 78.6 347.9C82.21 356 78.61 365.5 70.54 369.1L37.27 384z" />
            </svg>
          </div>
        );
      default:
        return (
          <div className="p-0.5">
            <svg className="h-4 w-4" viewBox="0 0 512 512">
              <path d="M234.2 4.672C241 1.592 248.5 0 256 0C263.5 0 270.1 1.592 277.8 4.672L495.2 102.1C505.4 106.7 512 116.8 512 128C512 139.2 505.4 149.3 495.2 153.9L277.8 251.3C270.1 254.4 263.5 256 256 256C248.5 256 241 254.4 234.2 251.3L16.76 153.9C6.561 149.3 .0003 139.2 .0003 128C.0003 116.8 6.561 106.7 16.76 102.1L234.2 4.672zM256 32C252.1 32 249.1 32.64 247.2 33.87L37.27 128L247.2 222.1C249.1 223.4 252.1 224 256 224C259 224 262 223.4 264.8 222.1L474.7 128L264.8 33.87C262 32.64 259 32 256 32V32zM78.6 219.9C82.22 228 78.61 237.5 70.55 241.1L37.27 256L247.2 350.1C249.1 351.4 252.1 352 256 352C259 352 262 351.4 264.8 350.1L474.7 256L441.5 241.1C433.4 237.5 429.8 228 433.4 219.9C437 211.9 446.5 208.3 454.5 211.9L495.2 230.1C505.4 234.7 512 244.8 512 256C512 267.2 505.4 277.3 495.2 281.9L277.8 379.3C270.1 382.4 263.5 384 256 384C248.5 384 241 382.4 234.2 379.3L16.76 281.9C6.561 277.3 0 267.2 0 256C0 244.8 6.561 234.7 16.76 230.1L57.46 211.9C65.52 208.3 74.99 211.9 78.6 219.9H78.6zM37.27 384L247.2 478.1C249.1 479.4 252.1 480 256 480C259 480 262 479.4 264.8 478.1L474.7 384L441.5 369.1C433.4 365.5 429.8 356 433.4 347.9C437 339.9 446.5 336.3 454.5 339.9L495.2 358.1C505.4 362.7 512 372.8 512 384C512 395.2 505.4 405.3 495.2 409.9L277.8 507.3C270.1 510.4 263.5 512 256 512C248.5 512 241 510.4 234.2 507.3L16.76 409.9C6.561 405.3 0 395.2 0 384C0 372.8 6.561 362.7 16.76 358.1L57.46 339.9C65.52 336.3 74.99 339.9 78.6 347.9C82.21 356 78.61 365.5 70.54 369.1L37.27 384z" />
            </svg>
          </div>
        );
    }
  };
  
  return (
    <>
      <Picture variant={variant} imageUrl={getImageUrl(experience)}>
        {experience?.tags?.length > 0 && (
          <Tags>
            <Tag>{experience?.tags?.[0]}</Tag>
            {experience?.tags.length > 1 && (
              <Tooltip
                tip={experience?.tags?.slice(1).map((item, index) => (
                  <p style={{ margin: 0 }} key={index}>
                    {item}
                  </p>
                ))}
              >
                <Tag>
                  <span>+ {experience?.tags?.slice(1).length}</span>
                </Tag>
              </Tooltip>
            )}
          </Tags>
        )}
        <ReactTooltip />
        <Showcased
          showcased={showcased}
          onClick={toggleShowcase}
          hasTag={experience?.tags?.length > 0}
        >
          <MdRemoveRedEye data-tip={t('experiences:toggleShowcase')} />
        </Showcased>
        {experience?.tags?.length > 0 && (
          <ThematicTags className="bg-purple-600 bg-opacity-50 w-full p-2">
            <div className="text-center text-white text-xs">
              {experience?.tags?.map((style, index) => (
                <span key={experience?.id + index}>
                  {style}
                  {index !== experience.tags.length - 1 && ', '}
                </span>
              ))}
            </div>
          </ThematicTags>
        )}
      </Picture>

      <CardContentListing variant={variant}>
        <Stack direction="column">
          <CardTitle>{experience?.name}</CardTitle>
          <Stack direction="row" gutterSize={1}>
            <Stack gutterSize={0.5}>
              {experience?.startPosition?.name && (
                <i className="far fa-flag" aria-hidden="true"></i>
              )}
              <Text color="textLighter" type="bodySmall">
                {' '}
                {experience?.startPosition?.name}
              </Text>
            </Stack>
            <Stack gutterSize={0.5}>
              {experience?.endPosition?.name && (
                <i className="far fa-flag-checkered" aria-hidden="true"></i>
              )}
              <Text color="textLighter" type="bodySmall">
                {' '}
                {experience?.endPosition?.name}
              </Text>
            </Stack>
            <Stack>
              <Duration
                durationDays={experience?.durationDays}
                durationHours={experience?.durationHours}
                durationMinutes={experience?.durationMinutes}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack 
          marginTop={1} 
          gutterSize={0.25}
          alignX="space-between"
        >
          <Stack>
            {ExperienceTypeIcon(
              experience?.transferType ?? experience.experienceType,
            )}
            <Text color="textLight" type="body">
              {t('experienceTypology', {
                name:
                  experience?.experienceType !== 'Activity'
                    ? experience?.experienceType
                    : experience?.isExtra === true
                    ? 'Extra'
                    : 'Activity',
              })}
            </Text>
          </Stack>

          <Stack>
            <div className="text-sm font-medium">
              {experience?.price?.price > 0 && (
                <div className="text-sm text-orange-600">
                  {formatCurrency({
                    amount: experience?.price?.price,
                    currency: experience?.price?.currency,
                  })}
                </div>
              )}
            </div>
          </Stack>
        </Stack>

        {currentUserRole === koobRole.KOOB_ADMIN && (
          <OrganizationName
            name={experience?.organizationName}
            imageSrc={experience?.organizationImages}
          />
        )}
          
        {mode === 'compose' && (
          <>
            {compatibility !== 'incompatible' ? (
              <KoobButton
                onClick={() => onSelect(experience)}
                color={compatibility === 'bestMatch' ? 'green' : 'primary'}
                disabled={
                  isSelectedExperienceInTrip || selectedType !== 'experience'
                }
                block
              >
                <div className="flex justify-center space-x-1.5 items-center">
                  {compatibility === 'bestMatch' && (
                    <svg className="h-5 w-5" height="1em" viewBox="0 0 512 512">
                      <path d="M243.5 37.3c8-3.4 17-3.4 25 0l176.7 75c11.3 4.8 18.9 15.5 18.8 27.6c-.5 94-39.4 259.8-195.5 334.5c-7.9 3.8-17.2 3.8-25.1 0C87.3 399.6 48.5 233.8 48 139.8c-.1-12.1 7.5-22.8 18.8-27.6l176.7-75zM281 7.8c-16-6.8-34-6.8-50 0L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.7 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2L281 7.8zm82.3 195.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L224 297.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l64 64c6.2 6.2 16.4 6.2 22.6 0l128-128z" />
                    </svg>
                  )}
                  <div className="text-sm font-medium whitespace-nowrap">
                    {t(
                      selectedType === 'experience'
                        ? 'misc:select'
                        : 'tripDesigner:compose.selectDay',
                    )}
                  </div>
                </div>
              </KoobButton>
            ) : (
              <div className="w-full text-red-600 h-9 bg-gray-200 cursor-not-allowed px-4 rounded flex space-x-1.5 justify-center items-center">
                <svg className="h-5 w-5" height="1em" viewBox="0 0 512 512">
                  <path d="M243.5 37.3c8-3.4 17-3.4 25 0l176.7 75c11.3 4.8 18.9 15.5 18.8 27.6c-.5 94-39.4 259.8-195.5 334.5c-7.9 3.8-17.2 3.8-25.1 0C87.3 399.6 48.5 233.8 48 139.8c-.1-12.1 7.5-22.8 18.8-27.6l176.7-75zM281 7.8c-16-6.8-34-6.8-50 0L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.7 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2L281 7.8zM180.7 180.7c-6.2 6.2-6.2 16.4 0 22.6L233.4 256l-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L256 278.6l52.7 52.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L278.6 256l52.7-52.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L256 233.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0z" />
                </svg>
                <span className="font-medium text-sm">{t('incompatible')}</span>
              </div>
            )}
          </>
        )}
      </CardContentListing>
    </>
  );
};

const ExperienceCard = ({ experience, mode, onSelect, compatibility }) => (
  <CardListing as={mode ? null : Link} to={`edit/${experience?.id}`}>
    <ExperienceCardContent
      experience={experience}
      mode={mode}
      onSelect={onSelect}
      compatibility={compatibility}
    />
  </CardListing>
);

export default ExperienceCard;
