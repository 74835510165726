import ISO6391 from 'iso-639-1';

export const getLanguages = () => {
  return ISO6391.getAllCodes().map(code => {
    return {
      value: code,
      label: ISO6391.getName(code),
    };
  })
    .sort((a, b) => a.label.localeCompare(b.label));
};
