import { useState } from 'react';
import { useField } from 'formik';
import { Stack } from '@koob/margaret';
import {
  FormField,
  FormLabel,
  Input,
  ErrorMessageWrapper,
  HelperText,
  PasswordButton,
  InputIconButton,
} from 'ui/forms';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { uniqueId } from 'lodash';

const TextField = ({
  label,
  labelSuffix,
  variant = '',
  helperText,
  touchRequired = true,
  required,
  errorKey,
  suffixIcon,
  name,
  ...props
}) => {
  const [field, meta] = useField(name);

  const fieldForError = useField(errorKey ?? name);
  const [id] = useState(uniqueId());

  const isPassword = props.type === 'password';
  const [type, setType] = useState(props.type || 'text');

  const hasError =
    (!touchRequired || Boolean(meta.touched)) &&
    Boolean(fieldForError[1].error);

  if (variant === 'inline') {
    return (
      <Stack alignY="center" gutterSize={label ? 0.5 : 0}>
        <FormLabel variant="bare" htmlFor={id}>
          {label}
          {required && <span className="ml-1 text-red-500">*</span>}
        </FormLabel>
        <Stack>
          <Input
            {...field}
            {...props}
            hasError={hasError}
            type={type}
            id={id}
          />
        </Stack>
      </Stack>
    );
  }

  return (
    <FormField>
      {(Boolean(label) || Boolean(labelSuffix)) && (
        <Stack
          alignX="space-between"
          alignY="center"
          marginBottom={0.5}
          size="full"
        >
          {label && (
            <FormLabel variant="bare" htmlFor={id}>
              {label}
              {required && <span className="ml-1 text-red-500">*</span>}
            </FormLabel>
          )}
          {labelSuffix}
        </Stack>
      )}

      <Stack size="full">
        <div style={{ position: 'relative', width: '100%' }}>
          <Input
            {...field}
            {...props}
            type={type}
            hasError={hasError}
            id={id}
          />
          {isPassword && (
            <PasswordButton
              disabled={props.disabled}
              type="button"
              onClick={() => setType(type === 'password' ? 'text' : 'password')}
              tabIndex={-1}
            >
              {type === 'text' ? <MdVisibility /> : <MdVisibilityOff />}
            </PasswordButton>
          )}
          {suffixIcon && <InputIconButton>{suffixIcon}</InputIconButton>}
        </div>
        {hasError && (
          <ErrorMessageWrapper>{fieldForError[1].error}</ErrorMessageWrapper>
        )}
      </Stack>
      {Boolean(helperText) && <HelperText>{helperText}</HelperText>}
    </FormField>
  );
};

export default TextField;
