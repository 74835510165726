const OrganizationName = ({ name, imageSrc }) => {
  const defaultImage = '/img/hotel-image-placeholder.png';

  return (
    <div className="flex items-center space-x-1.5 text-gray-500 col-span-2">
      <div className="w-full text-xs text-right font-medium leading-tight">
        {name}
      </div>
      <img
        className="w-8 h-8 object-cover bg-gray-100 rounded-md"
        src={imageSrc || defaultImage}
        onError={ev => (ev.target.src = defaultImage)}
        alt={name || 'Organization image'}
      />
    </div>
  );
};

export default OrganizationName;