import { useTranslation } from 'react-i18next';
import BulkEditor from './BulkEditor';
import LocationAwareSearch from '../../../../components/LocationAwareSearch';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from '../../../../hooks';
import { useAsync } from 'react-async';
import { getTripTemplates } from '../../../../api/node/trip';
import { isEqual } from 'lodash';
import { SegmentedControl } from '@koob/margaret';
import { Paginator } from 'components/Paginator';
import { OrderColumnEnum, OrderDirectionEnum } from '@koob/enums';

export default function BulkEditorTemplates() {
  const { t } = useTranslation('toConnectionRequest');
  const [{ search } ]= useSearchParams();

  const [onlyFavorites, setOnlyFavorites] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState();
  
  const requestFilters = {
    search: search ?? '',
    onlyFavorites,
    perPage: perPage,
    page,
    direction: OrderDirectionEnum.DESC,
    order: OrderColumnEnum.UPDATED_AT
  };

  useEffect(() => {
    setPage(1);
  }, [search, onlyFavorites])

  const { data, isLoading: loading } = useAsync({
    promiseFn: getTripTemplates,
    filters: requestFilters,
    watchFn: (props, prevProps) => {
      return !isEqual(props.filters, prevProps.filters);
    },
    onResolve: ((response) => {
      setTotalPages(response?.headers?.xTotalPages)
    })
  });

  const totalCount = data?.headers?.xTotalCount;
  const templates = data?.data ?? [];

  return (
    <BulkEditor
      title={t('bulkEdit.editor.templatesTitle')}
      filters={
        <>
          <LocationAwareSearch name="search" />

          <SegmentedControl
            options={[
              { label: t('experiences:state.all'), value: false },
              { label: t('tripDesigner:search.onlyFavorites'), value: true },
            ]}
            value={requestFilters.onlyFavorites ?? null}
            onSelect={value => setOnlyFavorites(value)}
          />
        </>
      }
      loading={loading}
      results={
        templates.map(template => ({
          id: template.id,
          name: template.name,
          description: template.description,
          image: template.imageUrl,
        }))
      }
      type="template"
      totalCount={totalCount}
      perPage={perPage}
      setPage={setPage}
      setTotalPages={setTotalPages}
      totalPages={data?.headers?.xTotalPages}
    >
      {templates.length > 0 && (
        <Paginator
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      )}
    </BulkEditor>
  );
}
