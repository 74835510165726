import { get, put } from './index';

export const getDefaultIncludedServices = ({
  organizationId,
  locale = 'en',
}) => {
  return get(
    `/organizations/${organizationId}/defaults/included-services?locale=${
      locale ?? 'en'
    }`,
  );
};

export const saveDefaultIncludedServices = (
  organizationId,
  locale = 'en',
  payload,
) => {
  return put(
    `/organizations/${organizationId}/defaults/included-services?locale=${
      locale ?? 'en'
    }`,
    payload,
  );
};

export const getDefaultExcludedServices = ({
  organizationId,
  locale = 'en',
}) => {
  return get(
    `/organizations/${organizationId}/defaults/excluded-services?locale=${
      locale ?? 'en'
    }`,
  );
};

export const saveDefaultExcludedServices = (
  organizationId,
  locale = 'en',
  payload,
) => {
  return put(
    `/organizations/${organizationId}/defaults/excluded-services?locale=${
      locale ?? 'en'
    }`,
    payload,
  );
};
