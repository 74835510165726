const NotificationType = Object.freeze({
  TRIP_HELP_REQUESTED: 'trip_help_requested',
  TRIP_HELP_ASSISTANT: 'trip_help_assistant',
  TRIP_NEW_VERSION_AVAILABLE: 'trip_new_version_available',
  TRIP_BOOKING_CONFIRMED: 'trip_booking_confirmed',
  TRIP_NEW_PROPOSAL: 'trip_new_proposal',
  BOOKING_NEW_REQUEST_HOTEL: 'booking_new_request_hotel',
  BOOKING_NEW_CONFIRMATION_HOTEL: 'booking_new_confirmation_hotel',
  BOOKING_HOTEL_UPDATED: 'booking_hotel_updated',
  BOOKING_HOTEL_IMPORTANT_UPDATE: 'booking_hotel_important_update',
  BOOKING_HOTEL_CANCELED: 'booking_hotel_canceled',
  BOOKING_HOTEL_CONFIRMED: 'booking_hotel_confirmed',
  BOOKING_NEW_CONFIRMATION_EXPERIENCE: 'booking_new_confirmation_experience',
  BOOKING_NEW_REQUEST_EXPERIENCE: 'booking_new_request_experience',
  BOOKING_EXPERIENCE_UPDATED: 'booking_experience_updated',
  BOOKING_EXPERIENCE_IMPORTANT_UPDATE: 'booking_experience_important_update',
  BOOKING_EXPERIENCE_CANCELED: 'booking_experience_canceled',
  BOOKING_EXPERIENCE_CONFIRMED: 'booking_experience_confirmed',
  TO_CONNECTION_REQUEST_HOTEL_REQUESTED: 'to_connection_request_hotel_requested',
  TO_CONNECTION_REQUEST_EXPERIENCE_REQUESTED: 'to_connection_request_experience_requested',
  UPDATE_HOTEL: 'update_hotel',
  UPDATE_EXPERIENCE: 'update_experience',
});

export default NotificationType;
