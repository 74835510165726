import { memo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Stack } from '@koob/margaret';
import { IcBin, IcPencil } from 'components/icons';
import { MdClear } from 'react-icons/md';
import { ActionButton } from 'ui';
import GalleryItemFileNameField from './GalleryItemFileNameField';
import { useIntersection } from 'react-use';
import { useTranslation } from 'react-i18next';

const Img = styled.img`
  display: block;
  width: 100%;
  aspect-ratio: 4 / 3;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;

const CheckboxText = styled.label`
  color: ${({ theme }) => theme.textLighter};
  text-align: left;
  font-size: 18px;
`;

const GalleryItemField = ({
  item,
  name,
  index,
  onDelete,
  disabled,
  canSelectMainPicture,
  onSelectMainPicture,
}) => {
  const [isEditingName, setIsEditingName] = useState();
  const inputRef = useRef();
  const intersectionRef = useRef(null);
  const { t } = useTranslation('gallery');

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  const { isIntersecting } = intersection || {};

  return (
    <Stack direction="column" gutterSize={0.25} size="full">
      <>
        <div>
          <div ref={intersectionRef} />
          <Img src={item?.imageUrl} alt={item?.name} />
        </div>
        <Stack
          gutterSize={0.25}
          alignX="space-between"
          size="full"
          alignY="center"
        >
          {isIntersecting && (
            <GalleryItemFileNameField
              isEditing={isEditingName}
              name={`${name}.displayName`}
              ref={inputRef}
              onBlur={() => {
                setIsEditingName(false);
              }}
            />
          )}
          {!disabled && (
            <Stack alignY="center" gutterSize={0.5}>
              {!isEditingName && (
                <>
                  <ActionButton
                    onClick={() => {
                      setIsEditingName(true);
                    }}
                  >
                    <IcPencil size={20} />
                  </ActionButton>
                  <ActionButton onClick={() => onDelete(index)}>
                    <IcBin />
                  </ActionButton>
                </>
              )}
              {isEditingName && (
                <ActionButton
                  onClick={() => {
                    try {
                      inputRef.current.blur();
                    } catch (err) {}
                  }}
                >
                  <MdClear />
                </ActionButton>
              )}
            </Stack>
          )}
        </Stack>
        {canSelectMainPicture && (
          <Stack gutterSize={0.25} size="full" alignY="center">
            <input
              type="checkbox"
              checked={item.isMainPicture}
              id={`checkbox-${name}`}
              onChange={() => onSelectMainPicture(index)}
              disabled={item.isMainPicture}
            />
            <CheckboxText htmlFor={`checkbox-${name}`}>
              {t('mainPicture')}
            </CheckboxText>
          </Stack>
        )}
      </>
    </Stack>
  );
};

export default memo(GalleryItemField);
