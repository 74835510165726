import { patch, put } from './index';

export const quickUpdateBooking = ({ secret, body }) => {
  return patch(`/hotelKeeperBooking/${secret}/quickModification`, body);
};

export const updateHotelKeeperBookingState = ({ secret, body }) => {
  return patch(`/hotelKeeperBooking/${secret}/state`, body);
};

export const updateDmcBooking = ({ bookingId, body }) => {
  return put(`/bookings/${bookingId}/dmc`, body);
};
