import styled from 'styled-components';
import { Stack } from '@koob/margaret';
import IcBasicDeal from 'components/icons/IcBasicDeal';
import IcLastMinute from 'components/icons/IcLastMinute';
import IcEarlyBooker from 'components/icons/IcEarlyBooker';

const PROMOTION_ICONS = {
  basic_deal: <IcBasicDeal size={12} />,
  early_booker: <IcEarlyBooker size={12} />,
  last_minute: <IcLastMinute size={12} />,
};

const Promotion = styled(Stack).attrs({
  alignY: 'center',
  gutterSize: 0.25,
})`
  font-size: 0.75rem;
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.75)};
  border-radius: 40px;
  color: #2e1065;
  background-color: ${({ bgColor }) => bgColor ?? '#e9d5ff'};
`;

const HotelRoomPromotion = ({ name, kind, bgColor }) => {
  return (
    <Promotion bgColor={bgColor}>
      {kind && <div>{PROMOTION_ICONS[kind]}</div>}
      <div>{name}</div>
    </Promotion>
  );
};

export default HotelRoomPromotion;
