import { Container } from 'ui';
import { Stack } from '@koob/margaret';
import {
  EditorField,
  MultiSearchableSelectField,
  SelectField,
  TextField,
} from 'components/Fields';
import TextFieldButton from '../../../../components/Fields/TextFieldButton';
import { GET_SEGMENTS } from '../../../ExperiencesSettings/Segments/SegmentsList';
import { GET_THEMES } from '../../../ExperiencesSettings/Themes/ThemesList';
import { EXPERIENCE_ACTIVITIES_SCHEMA } from '../Utilities/ExperienceValidationSchemas';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';

export const ExperienceActivities = () => {
  const { t } = useTranslation('experiences');
  const [{ value: experienceType }, ,] = useField('type');

  return (
    <Container size="full">
      <Stack direction="column" gutterSize={1.5} size="full">
        {experienceType !== 'Program' && (
          <>
            <Stack gutterSize={1.5} size="full">
              <TextField
                label={t('form.pickUpPoint')}
                name="activity.pickUpPoint"
              />

              <Stack
                gutterSize={0}
                style={{
                  flexWrap: 'wrap',
                  alignItems: 'stretch',
                }}
              >
                <TextFieldButton
                  type="number"
                  step="1"
                  label={t('form.pickUpPointTimeHour')}
                  name="activity.pickUpPointTimeHour"
                  content={'H'}
                  min="0"
                  max="23"
                />
                <TextFieldButton
                  type="number"
                  step="15"
                  label={t('form.pickUpPointTimeMin')}
                  name="activity.pickUpPointTimeMin"
                  content={'min'}
                  min="0"
                  max="59"
                />
              </Stack>
            </Stack>

            <Stack gutterSize={1.5} size="full">
              <TextField
                label={t('form.dropOffPoint')}
                name="activity.dropOffPoint"
              />

              <Stack
                gutterSize={0}
                style={{
                  flexWrap: 'wrap',
                  alignItems: 'stretch',
                }}
              >
                <TextFieldButton
                  type="number"
                  step="1"
                  label={t('form.dropOffPointTimeHour')}
                  name="activity.dropOffPointTimeHour"
                  content={'H'}
                  min="0"
                  max="23"
                />
                <TextFieldButton
                  type="number"
                  step="15"
                  label={t('form.dropOffPointTimeMin')}
                  name="activity.dropOffPointTimeMin"
                  content={'min'}
                  min="0"
                  max="59"
                />
              </Stack>
            </Stack>
          </>
        )}

        <Stack
          gutterSize={0.5}
          size="full"
          style={{
            flexWrap: 'wrap',
            alignItems: 'stretch',
            justifyContent: 'space-between',
          }}
        >
          <Stack>
            <SelectField
              label={t('form.difficulty')}
              name="difficulty"
              valueField={'value'}
              options={[
                { label: t('form.beginner'), value: 'Beginner' },
                {
                  label: t('form.intermediate'),
                  value: 'Intermediate',
                },
                { label: t('form.advanced'), value: 'Advanced' },
                { label: t('form.expert'), value: 'Expert' },
              ]}
            />
          </Stack>
        </Stack>

        <Stack gutterSize={1.5} size="full">
          <MultiSearchableSelectField
            label={t('form.segments')}
            name="segments"
            query={GET_SEGMENTS}
            pathToEdges={['experienceSegments', 'edges']}
            renderOption={({ displayName }) => displayName}
            renderSelectedOption={({ displayName }) => displayName}
          />

          <MultiSearchableSelectField
            label={t('form.themes')}
            name="themes"
            query={GET_THEMES}
            pathToEdges={['experienceThemes', 'edges']}
            renderOption={({ displayName }) => displayName}
            renderSelectedOption={({ displayName }) => displayName}
          />
        </Stack>

        <Stack gutterSize={1.5} size="full">
          <EditorField name={'highlights'} label={t('form.highlights')} />
          <EditorField
            name={'activity.freeVisitInformations'}
            label={t('form.freeVisitInformations')}
          />
        </Stack>
      </Stack>
    </Container>
  );
};

export const getSchema = () => EXPERIENCE_ACTIVITIES_SCHEMA;
