import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../../../components/Fields';

const MarginFields = ({ name }) => {
  const { t } = useTranslation('toConnectionRequest');

  return (
    <div className="flex items-center space-x-3">
      <TextField name={`${name}.margin`} label={t('margin')} type="number" />
      <div className="self-end">
        <Field type="checkbox" name={`${name}.isMarginPercent`} />
        <span className="ml-2 text-sm">{t('percent')}</span>
      </div>
    </div>
  );
};

export default MarginFields;
