import { Stack } from '@koob/margaret';
import { ActionButton, Card, Text } from 'ui';
import { FieldArray, useField } from 'formik';
import { Scrollbars } from 'react-custom-scrollbars';
import { List } from 'react-movable';
import { IcBin } from 'components/icons';
import FacilityPopoverItem from 'components/FacilityPopoverItem';

const EditGroupFacilitiesField = ({ name }) => {
  const [{ value }] = useField({ name });

  if ((value || []).length === 0) {
    return null;
  }

  return (
    <Card variant="small" size="full" style={{ padding: 0 }}>
      <Scrollbars autoHeight autoHeightMax={350}>
        <FieldArray
          name={name}
          render={({ move, remove }) => (
            <List
              values={value}
              onChange={({ oldIndex, newIndex }) => move(oldIndex, newIndex)}
              renderList={({ children, props }) => (
                <Stack
                  direction="column"
                  size="full"
                  alignX="stretch"
                  {...props}
                >
                  {children}
                </Stack>
              )}
              renderItem={({ isDragged, value, props, index }) => {
                return (
                  <Stack
                    isDragged={isDragged}
                    {...props}
                    style={{ ...props.style }}
                    alignY="center"
                    size="full"
                  >
                    <FacilityPopoverItem
                      isDraggable
                      facility={value}
                      actions={
                        <Stack alignY="center" gutterSize={0.5}>
                          <Text type="bodySmall">{value?.kind}</Text>
                          <ActionButton onClick={() => remove(index)}>
                            <IcBin />
                          </ActionButton>
                        </Stack>
                      }
                    />
                  </Stack>
                );
              }}
            />
          )}
        />
      </Scrollbars>
    </Card>
  );
};

export default EditGroupFacilitiesField;
