import { cva } from 'class-variance-authority';

export default function KoobInputCheckbox({
  label,
  children,
  value,
  setValue,
  disabled,
  id
}) {
  const checkbox = cva(
    [
      'form-checkbox h-4 w-4',
      'text-k-orange',
      'border-gray-300 checked:border-none focus:ring-0 focus:ring-offset-0',
      'rounded shadow-sm',
    ],
    {
      variants: {
        disabled: {
          true: 'opacity-75 pointer-events-none',
        },
      },
    },
  );

  return (
    <div>
      <label className="flex items-center space-x-3">
        <input
          type="checkbox"
          className={checkbox({ disabled })}
          checked={value}
          onChange={e => setValue(e.target.checked)}
          disabled={disabled}
          id={id}
        />

        {children ??
          (label && (
            <span className="text-sm font-semibold text-gray-600 dark:text-slate-400 select-none">
              {label}
            </span>
          ))}
      </label>
    </div>
  );
}
