import { TripDesignerContext } from 'contexts';
import { useEffect } from 'react';
import { useCallback, useState } from 'react';

const TripDesignerProvider = ({ children }) => {
  const [shouldRender, setShouldRender] = useState(true);
  const [libraryFilters, setLibraryFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [listMode, setListMode] = useState('map');
  const [searchHotels, setSearchHotels] = useState(false);
  const [lastAddedExperienceId, setLastAddedExperienceId] = useState(null);
  const [compareWithVersion, setCompareWithVersion] = useState(null);
  const [tripFilters, setTripFilters] = useState(
    localStorage.getItem('tbFiltersCache')
      ? JSON.parse(localStorage.getItem('tbFiltersCache'))
      : null,
  );
  const [tripData, setTripData] = useState(
    localStorage.getItem('tbDataCache')
      ? JSON.parse(localStorage.getItem('tbDataCache'))
      : null,
  );
  const [selectedExtras, setSelectedExtras] = useState(
    localStorage.getItem('tbExtrasCache')
      ? JSON.parse(localStorage.getItem('tbExtrasCache'))
      : [],
  );
  const [oldTripData, setOldTripData] = useState(
    localStorage.getItem('tbOldDataCache')
      ? JSON.parse(localStorage.getItem('tbOldDataCache'))
      : null,
  );
  const [basket, setBasket] = useState([]);
  const [initialExtras, setInitialExtras] = useState([]);

  const addToBasket = useCallback((experience, filters) => {
    setBasket(basket => [
      ...basket,
      { ...experience, filters, internalId: crypto.randomUUID() },
    ]);
  }, []);

  const removeFromBasket = useCallback(experience => {
    setBasket(basket =>
      basket.filter(
        basketExp => basketExp.internalId !== experience.internalId,
      ),
    );
  }, []);

  const updateInBasket = useCallback(experience => {
    setBasket(basket =>
      basket.map(basketExp =>
        basketExp.internalId === experience.internalId ? experience : basketExp,
      ),
    );
  }, []);

  useEffect(() => {
    if (!tripFilters && !tripData) {
      localStorage.removeItem('tbFiltersCache');
      localStorage.removeItem('tbDataCache');
    }
    if (tripFilters) {
      localStorage.setItem('tbFiltersCache', JSON.stringify(tripFilters));
    }
    if (tripData) {
      localStorage.setItem('tbDataCache', JSON.stringify(tripData));
    }
  }, [tripFilters, tripData]);

  useEffect(() => {
    localStorage.setItem('tbExtrasCache', JSON.stringify(selectedExtras ?? []));
  }, [selectedExtras]);

  useEffect(() => {
    localStorage.setItem('tbOldDataCache', JSON.stringify(oldTripData ?? []));
  }, [oldTripData]);

  useEffect(() => {
    if (selectedType === 'hotel' && selectedDay !== null) {
      setSearchHotels(true);
    } else {
      setSearchHotels(false);
    }
  }, [selectedType, selectedDay]);

  const resetContext = useCallback(() => {
    setTripFilters(null);
    setTripData(null);
    setSelectedDay(null);
    setSelectedType(null);
    setLastAddedExperienceId(null);
    setOldTripData(null);
    setSelectedExtras([]);
    setCompareWithVersion(null);
    setInitialExtras([]);
  }, []);

  const setDates = useCallback(({ startAt, endAt }) => {
    setTripFilters(current => ({
      ...current,
      startAt,
      endAt,
    }));
  }, []);

  const selectDay = useCallback((dayIndex, type) => {
    setSelectedDay(dayIndex);
    setSelectedType(type);
  }, []);

  const resetDaySelection = useCallback(() => {
    setSelectedDay(null);
    setSelectedType(null);
  }, []);

  const resetBasket = useCallback(() => {
    setBasket([]);
  }, []);

  const reRender = () => {
    setShouldRender(false);
    setTimeout(() => {
      setShouldRender(true);
    }, 1000);
  };

  const value = {
    tripFilters,
    setTripFilters,
    libraryFilters,
    setLibraryFilters,
    resetContext,
    setDates,
    tripData,
    setTripData,
    loading,
    setLoading,
    selectedDay,
    selectedType,
    selectDay,
    resetDaySelection,
    lastAddedExperienceId,
    setLastAddedExperienceId,
    compareWithVersion,
    setCompareWithVersion,
    selectedExtras,
    setSelectedExtras,
    basket,
    setBasket,
    addToBasket,
    removeFromBasket,
    resetBasket,
    updateInBasket,
    oldTripData,
    setOldTripData,
    shouldRender,
    reRender,
    listMode,
    setListMode,
    searchHotels,
    setSearchHotels,
    initialExtras,
    setInitialExtras,
  };

  return (
    <TripDesignerContext.Provider value={value}>
      {children}
    </TripDesignerContext.Provider>
  );
};

export default TripDesignerProvider;
