import styled, { css } from 'styled-components';
import {
  Stack,
  Container as MgtContainer,
  Box,
  media,
  ButtonReset,
} from '@koob/margaret';
import { Link as RawLink, NavLink } from 'react-router-dom';
import { fontStyles } from './theme';

export const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 6em;
  width: fit-content;
  background-color: ${({ theme }) => theme.secondaryBackground};
  border-radius: 4px;
  padding: 2px ${({ theme }) => theme.spacing(0.5)};
  font-size: 14px;
  color: ${({ theme }) => theme.secondary};

  svg {
    font-size: 20px;
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }

  ${({ variant, theme }) =>
    variant === 'primary' &&
    `
      background-color: ${theme.primaryLight};
      color: ${theme.primary};
  `}

  ${({ variant, theme }) =>
    variant === 'green' &&
    `
    background-color: ${theme.greenLight};
    color: ${theme.green};
  `}

  ${({ variant, theme }) =>
    variant === 'gray' &&
    `
    background-color: ${theme.disabled};
    color: ${theme.textLight};
  `}

  ${({ variant, theme }) =>
    variant === 'red' &&
    `
    background-color: ${theme.redLighter};
    color: ${theme.red};
  `}

  ${({ variant, theme }) =>
    variant === 'blue' &&
    `
    background-color: ${theme.blueLighter};
    color: ${theme.blue};
  `}

  ${({ variant }) =>
    variant === 'purple' &&
    `
    background-color: #d8b4fe;
    color: #a855f7;
  `}

  ${({ size, theme }) =>
    size === 'big' &&
    `
      padding: ${theme.spacing(0.5)} ${theme.spacing(0.75)};
  `}
`;

export const Link = styled(RawLink)`
  font-size: 14px;
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
`;

export const Container = styled(MgtContainer)`
  padding: ${({ theme }) => theme.spacing()};
  flex-direction: column;
  display: flex;

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(2)};
  `}

  ${({ barePaddingVertical }) =>
    Boolean(barePaddingVertical) &&
    `
      padding-top: 0;
      padding-bottom: 0;
    `}
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.separator};
`;

export const ActionsLinks = styled(Stack).attrs({
  gutterSize: 0.5,
  alignY: 'center',
  alignX: 'flex-end',
  size: 'full',
})``;

export const ActionLink = styled(Link)`
  display: flex;
  color: ${({ theme }) => theme.textLighter};
  font-size: 24px;

  &:hover {
    color: ${({ theme }) => theme.secondary};
  }
`;

export const ActionButton = styled(ButtonReset).attrs({ type: 'button' })`
  display: flex;
  color: ${({ theme }) => theme.textLighter};
  font-size: 24px;

  &:hover {
    color: ${({ theme }) => theme.secondary};
  }

  ${({ variant, theme }) =>
    variant === 'danger' &&
    `
      &:hover {
        color: ${theme.danger};
      }
    `}
`;

export const MailTo = styled.a.attrs({
  target: '_blank',
  rel: 'noopener, noreferrer',
})`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.secondary};
  text-decoration: none;
  cursor: pointer;
  padding: 2px 4px;
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.secondaryLight};
  }
`;

export const Text = styled(Box).attrs(
  ({ as, type = 'body', theme, ...props }) => ({
    as: as || theme?.fontSizes?.[type]?.defaultTag || type || 'span',
  }),
)`
  ${({ type, theme }) =>
    Boolean(type) &&
    Boolean(theme?.fontSizes?.[type]) &&
    css`
      ${fontStyles?.[type]}
    `}

  ${({ marginTop, marginVertical, margin }) =>
    !Boolean(marginTop) &&
    !Boolean(marginVertical) &&
    !Boolean(margin) &&
    `
      margin-top: 0;
    `}

  ${({ marginBottom, marginVertical, margin }) =>
    !Boolean(marginBottom) &&
    !Boolean(marginVertical) &&
    !Boolean(margin) &&
    `
      margin-bottom: 0;
    `}

  ${({ color, theme }) =>
    Boolean(color) &&
    `
      color: ${theme?.colors?.[color] || theme?.[color]};
    `}

  ${({ fontWeight }) =>
    Boolean(fontWeight) &&
    `
      font-weight: ${fontWeight};
    `}
`;

export const Tabs = styled(Stack).attrs({
  gutterSize: 1,
})``;

export const Tab = styled(NavLink)`
  display: flex;
  text-decoration: none;
  font-weight: 600;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  background-color: #ffffff;
  color: ${({ theme }) => theme.textLight};
  border: none;
  box-shadow: none;
  border-radius: 4px;

  &.active {
    background-color: ${({ theme }) => theme.primaryLight};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.primary},
      0 1px 2px 0 ${({ theme }) => theme.primaryBackground};
    color: ${({ theme }) => theme.primary};
  }

  ${({ variant, theme }) =>
    variant === 'bordered' &&
    `
      box-shadow: 0 0 0 1px ${theme.separator},
        0 1px 2px 0 ${theme.separator};
    `}

  ${({ size }) =>
    size === 'large' &&
    `
      min-width: 320px;
  `}
`;

export const Card = styled(Box)`
  padding: ${({ theme }) => theme.spacing(1.5)}
    ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.containerBackground};
  border: solid 1px ${({ theme }) => theme.separator};
  border-radius: 4px;
  text-align: inherit;
  text-decoration: none;
  color: inherit;

  ${({ variant, theme }) =>
    variant === 'small' &&
    `
      padding: ${theme.spacing(0.75)};
    `}
`;

export const CardTitle = styled.div`
  ${fontStyles?.bodyLarge};
  font-weight: bold;
`;

export const EmptyStateWrapper = styled(Card)`
  width: 100%;
`;

export const Label = styled.div`
  ${fontStyles?.bodySmall}
  color: ${({ theme }) => theme.textLighter};
`;

export const Caption = styled(Box)`
  ${fontStyles?.bodySmall}
  font-weight: 600;
  color: ${({ theme }) => theme.textLighter};
`;

export const DefaultLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const SectionCard = styled(Card).attrs({ size: 'full', as: 'section' })`
  padding: ${({ theme }) => theme.spacing()};
  height: min-content;

  ${({ isUpdated, theme }) =>
    isUpdated &&
    `
      background-color: ${theme.orangeLighter}
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid ${({ theme }) => theme.primary};
    `}

  ${({ variant }) =>
    variant === 'archive' &&
    css`
      background-color: ${({ theme }) => theme.yellowLight};
      background: repeating-linear-gradient(
        45deg,
        ${({ theme }) => theme.separatorLight},
        ${({ theme }) => theme.separatorLight} 16px,
        ${({ theme }) => theme.yellowLight} 16px,
        ${({ theme }) => theme.yellowLight} 32px
      );
    `}

    ${({ disabled, theme }) =>
    disabled &&
    `
      background-color: ${theme.disabled}
    `}
`;

export const Pills = styled.div`
  display: flex;
  margin-left: ${({ theme }) => theme.spacing(-1)};
  margin-top: ${({ theme }) => theme.spacing(-0.5)};
  flex-wrap: wrap;

  > * {
    margin-left: ${({ theme }) => theme.spacing(1)};
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  }
`;

export const Pill = styled(ButtonReset).attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  line-height: 1.2;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  background-color: #ffffff;
  color: ${({ theme }) => theme.textLight};
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.separator};
  border: solid 1px ${({ theme }) => theme.separator};
  border-radius: 4px;

  ${({ isActive, theme }) =>
    isActive &&
    `
      background-color: ${theme.primaryLight};
      border: solid 1px ${theme.primary};
      box-shadow: 0 1px 2px 0 ${theme.primaryBackground};
      color: ${theme.primary};
  `}

  ${({ disabled, theme }) =>
    disabled &&
    `
      background-color: ${theme.disabled};
      border: solid 1px ${theme.disabled};
      box-shadow: revert;
      color: ${theme.textLight};
  `}
`;

export const Divider = styled.hr`
  margin: ${({ theme }) => theme.spacing(1)} 0;
  display: block;
  height: 1px;
  width: 100%;
  border-bottom-color: ${({ theme }) => theme.darkSeparator};
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom-style: solid;
`;

const CounterSetting = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 1rem;
  max-width:2rem;
  white-space: nowrap;
  padding: 3px;
  height: 1rem;
  margin: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: #ffffff;
  background-color: #f9a25f;
  border-radius: 999px;
`;

export const ExperiencesGrid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(1.5)};
  grid-template-columns: 100%;
  grid-template-rows: auto;

  ${media.medium`
    grid-template-columns: repeat(2, 1fr);
  `};

  ${media.desktop`
    grid-template-columns: repeat(3, 1fr);
  `};
`;

export const Picture = styled.div`
  position: relative;
  padding-top: 56.25%;
  border-radius: 4px 4px 0 0;
  background: ${({ theme }) => theme.separator};
  background: ${({ imageUrl }) =>
    `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.75)), url(${imageUrl})`};
  background-size: cover;
  background-position: center center;
`;

export const CardListing = styled(Stack).attrs({
  direction: 'column',
  size: 'full',
  alignX: 'stretch',
})`
  border-radius: 4px;
  color: inherit;
  text-decoration: none;
`;

export const CardContentListing = styled(Stack).attrs({
  direction: 'column',
  size: 'full',
  alignY: 'space-between',
  alignX: 'stretch',
  gutterSize: 0.5,
})`
  flex: 1;
  padding: ${({ theme }) => theme.spacing()};
  border: 1px solid ${({ theme }) => theme.separator};
  border-top: 0;
  border-radius: 0 0 4px 4px;
  background-color: #ffffff;
`;

export const CounterBadge = ({ children }) => {
  return (
    <>{children && <CounterSetting >{children}</CounterSetting>}</>
  );
};

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing(-1)};
  margin-left: ${({ theme }) => theme.spacing(-1)};
  grid-template-columns: repeat(1, 1fr);
`;

export const Cell = styled.div`
  flex: 1;
  padding-top: ${({ theme }) => theme.spacing()};
  padding-left: ${({ theme }) => theme.spacing()};
  min-width: 0;

  ${props =>
    props.sizes &&
    css`
      flex: 0 0 ${Math.floor(props.sizes.default * 10000) / 100}%;
    `};
`;

export const PictureDiv = styled.div`
  background-image: url('${props => props.image}');
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 145px;
  width: 150px;
`;

export const Troncate = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${props => props.maxWidth}px;
`;