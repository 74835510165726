import { getNotifications } from 'api/node/notifications';
import { NotificationsContext } from 'contexts';
import { useMemo } from 'react';
import { useAsync } from 'react-async';

const NotificationsProvider = ({ children }) => {
  const {
    data: notificationsData,
    loading,
    reload: reloadNotifications,
  } = useAsync({
    promiseFn: getNotifications,
    onResolve: () => {
      setTimeout(() => reloadNotifications(), 15 * 1000);
    },
    onReject: () => {
      setTimeout(() => reloadNotifications(), 30 * 1000);
    },
  });

  const notifications = useMemo(() => {
    return notificationsData?.data ?? [];
  }, [notificationsData]);

  const value = { notifications, reloadNotifications, loading };
  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
