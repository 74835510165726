import React, { useCallback } from 'react';
import { Formik, FieldArray, Form } from 'formik';
import * as Yup from 'yup';
import { useTheme } from 'styled-components';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { parseBoolean } from 'utils';
import { getHotelsContracts } from 'api/node/contracts';
import { Button, ToggleSection, Tooltip } from 'components';
import { TextField, ToggleSwitchField } from 'components/Fields';
import KoobTitle from 'components/Koob/KoobTitle';
import KoobPlaceholder from 'components/Koob/KoobPlaceholder';
import { getRoomsDetail } from '../../../../utils/hotelDistribution';
import EyeButton from './EyeButton';
import MarginFields from './MarginFields';
import { Stack } from '@koob/margaret';

const HotelDetailForm = ({
                           hotel,
                           onSelectionFinished,
                           dmcorganization,
                           toorganization,
                           contracts,
                         }) => {
  const theme = useTheme();
  const { t } = useTranslation('toConnectionRequest');

  const { data, isLoading } = useAsync({
    promiseFn: getHotelsContracts,
    hotelId: hotel.id,
  });

  const canDisplayPeriod = useCallback(
    period => {
      if (!contracts) {
        return true;
      }
      const contract = contracts?.find(contract => {
        return (
          new Date(contract.startAt) <= new Date(period.startAt) &&
          new Date(contract.endAt) >= new Date(period.endAt)
        );
      });
      return !contract?.enabled || contract?.enabled === 'true';
    },
    [contracts]
  );

  const onSubmit = useCallback(
    values => {
      const rooms = values.rooms;
      const periods = [];
      rooms.forEach(room => {
        room.periods.forEach(period => {
          const newPeriod = {
            id: period.id,
            allotmentCount: period.toElement.allotmentCount,
            freeSale: period.toElement.freeSale,
            isPeriodAllowedForBooking: true,
            isMarginPercent: period.toElement?.isMarginPercent,
            margin: period.toElement?.margin || null,
          };
          newPeriod.isPeriodAllowedForBooking = parseBoolean(room.enabled);
          if (!room.enabled) {
            newPeriod.allotmentCount = 0;
            newPeriod.freeSale = false;
          }
          periods.push(newPeriod);
        });
      });
      hotel.periods = periods;
      onSelectionFinished();
    },
    [hotel, onSelectionFinished]
  );

  return (
    <>
      {isLoading && (
        <div className="flex-col space-y-5">
          <KoobPlaceholder className="h-20 w-full rounded-md" />
          <KoobPlaceholder className="h-20 w-full rounded-md" />
          <KoobPlaceholder className="h-20 w-full rounded-md" />
        </div>
      )}

      {!isLoading && (
        <>
          <div className="text-center max-w-lg mx-auto">
            <KoobTitle size="text-xl">
              {t('hotelModalTitle', {
                hotelName: hotel.displayName,
                interpolation: { escapeValue: false },
              })}
            </KoobTitle>

            {data?.data.length === 0 && (
              <KoobTitle className="m-4" size="text-sm">
                {t('roomModalInformation')}
              </KoobTitle>
            )}
          </div>

          <Formik
            initialValues={{
              rooms: getRoomsDetail(
                data,
                hotel.periods,
                dmcorganization,
                toorganization,
              ),
            }}
            validationSchema={Yup.object().shape({
              rooms: Yup.array().of(
                Yup.object().shape({
                  periods: Yup.array().of(
                    Yup.object().shape({
                      id: Yup.string().required(
                        t('required', { ns: 'errors' }),
                      ),
                      toElement: Yup.object().shape({
                        allotmentCount: Yup.number()
                          .required(t('required', { ns: 'errors' }))
                          .test(
                            'is-allotment-valid',
                            () => t('allotmentNotValid'),
                            async (allotmentCount, testContext) => {
                              return (
                                allotmentCount >= 0 &&
                                allotmentCount <=
                                testContext.parent
                                  .totalAllotmentsAvailableForDistribution
                              );
                            },
                          ),
                        freeSale: Yup.boolean().required(
                          t('required', { ns: 'errors' }),
                        ),
                        isPeriodAllowedForBooking: Yup.boolean().required(
                          t('required', { ns: 'errors' }),
                        ),
                      }),
                    }),
                  ),
                }),
              ),
            })}
            enableReinitialize
            onSubmit={onSubmit}
            validateOnBlur={true}
            validateOnChange={false}
          >
            {({ values, setFieldValue, isValid, isSubmitting }) => (
              <Form>
                <FieldArray
                  name="rooms"
                  render={arrayHelpers =>
                    values.rooms.map((room, roomIndex) => (
                      <div className="border m-5 p-2 rounded-md" key={room.id}>
                        <ToggleSection
                          title={room.name}
                          variant="full"
                          disabled={!parseBoolean(room.enabled)}
                          rightElement={
                              <EyeButton
                                room={room}
                                roomIndex={roomIndex}
                                setFieldValue={setFieldValue}
                                className={`${room.name?.toLowerCase().split(' ').join('-')} ${parseBoolean(room.enabled) ? "enabled" : "disabled"}`}
                              />
                          }
                        >
                          <div>
                            {room.periods.map((period, periodIndex) => (
                              canDisplayPeriod(period) && (
                                <div key={period.id} className="grid grid-cols-5 gap-5 mb-10">
                                  <h3
                                    className={`text-base font-bold text-k-h`}
                                    style={{ color: theme.gray }}
                                  >
                                    {t('periodDates', {
                                      start: format(
                                        parseISO(period.startAt),
                                        'dd-MM-yyyy',
                                      ),
                                      end: format(
                                        parseISO(period.endAt),
                                        'dd-MM-yyyy',
                                      ),
                                    })}
                                  </h3>

                                  <Tooltip
                                    tip={period.allAllocations
                                      .sort((a, b) => {
                                        if (a.organization.scope === b.organization.scope) {
                                          return b.allotmentCount - a.allotmentCount;
                                        }
                                        return a.organization.scope.localeCompare(b.organization.scope);
                                      })
                                      .map(allocation => (
                                        <p key={allocation.id}>
                                          {t('allocation', {
                                            name: allocation.organization.displayName,
                                            count: allocation.allotmentCount,
                                            totalCount: period.totalAllotmentsAvailable,
                                          })}
                                        </p>
                                      ))}
                                    position="bottom"
                                  >
                                    <TextField
                                      name={`rooms.${roomIndex}.periods.${periodIndex}.toElement.allotmentCount`}
                                      label={t('allotment')}
                                      disabled={values.rooms?.[roomIndex]?.periods?.[periodIndex]?.toElement?.freeSale}
                                      type="number"
                                    />
                                  </Tooltip>

                                  <div className="w-2/5">
                                    <ToggleSwitchField
                                      name={`rooms.${roomIndex}.periods.${periodIndex}.toElement.freeSale`}
                                      disabled={!period.freeSale}
                                      label={t('freeSale')}
                                      id="free-sales"
                                      className={period?.toElement?.freeSale ? "enabled" : "disabled"}
                                    />
                                  </div>

                                  <div className="col-span-2">
                                    <MarginFields
                                      name={`rooms.${roomIndex}.periods.${periodIndex}.toElement`}
                                    />
                                  </div>
                                </div>
                              )
                            ))}
                          </div>
                        </ToggleSection>
                      </div>
                    ))
                  }
                />

                <Stack
                  alignX="flex-end"
                  style={{
                    marginTop: '1rem',
                  }}
                  gutterSize={0.5}
                >
                  <Button
                    style={{ marginLeft: 'auto' }}
                    variant="simple"
                    type="button"
                    onClick={onSelectionFinished}
                  >
                    {t('misc:cancel')}
                  </Button>

                  <Button
                    type="submit"
                    disabled={!isValid}
                    variant="primary"
                    isLoading={isSubmitting}
                    id="save-room"
                  >
                    {t('misc:save')}
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default HotelDetailForm;
