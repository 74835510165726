import React from 'react';
import { useState } from 'react';
import ExperienceForm from './Partials/ExperienceForm';
import ExperienceLayout from './Partials/ExperienceLayout';
import { post } from '../../../api/node';
import { useTranslation } from 'react-i18next';
import { useSnack } from '../../../hooks';
import { Spinner as Loader } from '@koob/margaret';
import { initialLocale } from '../../../utils';
import axios from 'axios';
import { fromByteArray } from 'base64-js';

export default function ExperienceCreate() {
  const { t } = useTranslation('experiences');
  const { notify, notifyError } = useSnack();
  const [saveIsLoading, setSaveIsLoading] = useState(false);

  const handleSave = async experience => {
    try {
      setSaveIsLoading(true);
      // In the case of an experience copy, the images are loaded and send as new for the new experience.
      if (experience.pictures?.some((p) => p.imageUrl.startsWith('https'))) {
        const promises = experience.pictures.map(async (pic) => {
          if (!pic.imageUrl.startsWith('https')) return; // Skip if url is a HTTPS link
          try {
            const response = await axios.get(pic.imageUrl, {
              responseType: 'arraybuffer',
            });
            const arrayBuffer = response.data;
            const byteArray = new Uint8Array(arrayBuffer);
            const base64Image = fromByteArray(byteArray);
            const mimeType = response.headers['content-type'];
            pic.imageUrl = `data:${mimeType};base64,${base64Image}`;
            delete pic.id;
          } catch (e) {}
        });
        await Promise.all(promises);
      }
      const exp = await post(`/experiences`, experience);
      notify(t('createSuccess'));
      setSaveIsLoading(false);
      return exp?.data.id;
    } catch (err) {
      setSaveIsLoading(false);
      if (err?.response?.data?.message) {
        notify(err?.response?.data?.message, { type: 'error' });
      } else {
        notifyError(err);
      }
    }
  };

  return (
    <ExperienceLayout>
      <div className="relative">
        {saveIsLoading && (
          <div className="bg-gray-100/75 backdrop-blur-sm rounded-lg border z-20 absolute h-full w-full flex justify-center items-center">
            <Loader />
          </div>
        )}
        <ExperienceForm handleSave={handleSave} currentLocale={initialLocale} />
      </div>
    </ExperienceLayout>
  );
}
