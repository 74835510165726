import { Scrollbars } from 'react-custom-scrollbars';
import { IcCancel } from './icons';
import { Button, Stack } from '@koob/margaret';
import { Text } from 'ui';
import { ModalContent, ModalHeader } from './Modal';
import KoobModal from './Koob/KoobModal';

const RespondBookingModal = ({ isOpen, setIsOpen, title, children }) => {
  return (
    <KoobModal
      open={isOpen}
      setOpen={setIsOpen}
      widthClass="sm:max-w-lg rounded-[24px]"
      paddingClass="p-0"
    >
      <ModalHeader>
        <Stack direction="column" gutterSize={1} size="full">
          <Stack
            gutterSize={1}
            alignX="space-between"
            alignY="center"
            size="full"
          >
            <div style={{ width: 36 }} />
            <Text type="h2">{title}</Text>
            <Button
              style={{ color: '#c3b8c6' }}
              onClick={() => setIsOpen(false)}
            >
              <IcCancel size={36} />
            </Button>
          </Stack>
        </Stack>
      </ModalHeader>
      <Scrollbars
        autoHeight
        autoHeightMax="calc(100vh - 300px)"
        style={{ flex: 1 }}
      >
        <ModalContent>{children}</ModalContent>
      </Scrollbars>
    </KoobModal>
  );
};

export default RespondBookingModal;
