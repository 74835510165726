import { TextField } from 'components/Fields';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Button, Tooltip, LocaleSwitcher } from 'components';
import { useTranslation } from 'react-i18next';
import { Stack } from '@koob/margaret';
import { Text } from 'ui';

const ExperienceSegmentForm = ({
  onSubmit,
  segment,
  queryLocale,
  updateQueryLocale,
  disabledCreate = false,
}) => {
  const { t } = useTranslation('experienceSegment');

  return (
    <Formik
      initialValues={{
        displayName: segment?.displayName ?? '',
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        displayName: Yup.string().required(t('errors:required')),
      })}
    >
      {({ isSubmitting, dirty }) => (
        <Form>
          <Stack direction="column" gutterSize={1} size="full" alignX="stretch">
            <Stack
              size="full"
              alignX="space-between"
              alignY="center"
              gutterSize={1}
            >
              <Text type="h1">
                {t(Boolean(segment) ? segment?.displayName : 'newSegment')}
              </Text>
              <LocaleSwitcher
                queryLocale={queryLocale}
                updateQueryLocale={updateQueryLocale}
                disabled={Boolean(segment) && dirty}
              />
            </Stack>
            <TextField label={t('name')} name="displayName" />
            <Stack gutterSize={1}>
              <Tooltip disabled={!disabledCreate} tip={t('selectEnglish')}>
                <Button
                  type="submit"
                  variant="primary"
                  isLoading={isSubmitting}
                  disabled={disabledCreate}
                >
                  {Boolean(segment) ? t('misc:saveChanges') : t('create')}
                </Button>
              </Tooltip>
              <Button
                variant="simple"
                type="button"
                to="/settings/experiences/segments"
                state={queryLocale}
              >
                {t('misc:cancel')}
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default ExperienceSegmentForm;
