import { useState } from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PopoverItem, PopoverMenu, Stack, ButtonReset } from '@koob/margaret';
import { ActionButton, FormField, Text, theme } from 'ui';
import { Button, FacilityIcon, Modal, ToggleSection } from 'components';
import { IcBin, IcMessages } from 'components/icons';
import { formatFacilitiesToGroupsFacilities } from 'utils';
import Editor from './Editor';
import { REACT_QUILL_INIT_CONTENT } from './EditorField';

const ListItems = styled(PopoverMenu)`
  position: static;
  border: solid 1px ${({ theme }) => theme.separator};
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadow};
  border-radius: 8px;
  max-height: 350px;
  &:hover {
    border: solid 1px ${({ theme }) => theme.darkSeparator};
  }
`;

const FacilityName = styled(Text).attrs({
  type: 'body',
})`
  transition: color 150ms ease;
`;

const FacilityPopoverItem = styled(Stack).attrs({
  size: 'full',
  alignX: 'space-between',
  alignY: 'center',
  gutterSize: 0.5,
})`
  ${({ theme }) => `padding: ${theme.spacing(
    0.5,
  )} ${theme.spacing()} ${theme.spacing(0.5)}
    ${theme.spacing(2)}`};

  &:hover {
    ${FacilityName} {
      color: ${({ theme }) => theme.secondary};
    }
  }
`;

const MsgButton = styled(ActionButton)`
  transition: color 150ms ease;
  ${FacilityPopoverItem}:hover & {
    color: ${({ theme }) => theme.secondary};
  }
`;

const BinButton = styled(ActionButton).attrs({
  variant: 'danger',
})`
  transition: color 150ms ease;
`;

const FacilityPopoverContent = styled(ButtonReset).attrs({ type: 'button' })`
  flex: 1;
  text-align: left;
`;

const Actions = styled(Stack).attrs({ gutterSize: 0.5, alignY: 'center' })`
  svg {
    margin-right: 0;
  }
`;

const EditSelectedFacilitiesField = ({ disabled, name }) => {
  const { t } = useTranslation('');
  const [editedFacilityIndex, setEditedFacilityIndex] = useState(-1);
  const [newDetails, setNewDetails] = useState('');
  const [{ value }, , { setValue }] = useField({ name });

  const groups = formatFacilitiesToGroupsFacilities(value, t('misc:others'));

  const handleInitSelectFacilityEdition = ({ details, index }) => {
    setEditedFacilityIndex(index);
    setNewDetails(details);
  };

  const handleDismissEditFacilityDetails = () => {
    setNewDetails('');
    setEditedFacilityIndex(-1);
  };

  const handleSubmitEditFacilityDetails = () => {
    const newValue = value.map((facility, index) => {
      if (index !== editedFacilityIndex) {
        return facility;
      }

      return {
        ...facility,
        details: newDetails,
      };
    });
    setValue(newValue);
    setEditedFacilityIndex(-1);
  };

  const handleUnselectFacility = targetIndex => {
    setValue(value.filter((_, index) => index !== targetIndex));
  };

  return (
    <FormField>
      {groups.length > 0 && (
        <ListItems>
          {groups.map(({ group, facilities }) => (
            <PopoverItem key={group?.id}>
              <ToggleSection title={group?.displayName || group?.enDisplayName}>
                {(facilities || []).map(({ details, facility, index }) => (
                  <FacilityPopoverItem key={index}>
                    <FacilityPopoverContent
                      onClick={() =>
                        handleInitSelectFacilityEdition({
                          details,
                          index,
                        })
                      }
                    >
                      <Stack gutterSize={0.5} alignY="center" size="full">
                        <FacilityIcon facility={facility} />
                        <FacilityName>
                          {facility?.displayName || facility?.enDisplayName}
                        </FacilityName>
                      </Stack>
                    </FacilityPopoverContent>
                    {!disabled && (
                      <Actions>
                        <MsgButton
                          onClick={() =>
                            handleInitSelectFacilityEdition({
                              details,
                              index,
                            })
                          }
                        >
                          <IcMessages
                            color={
                              Boolean(details) &&
                              details !== REACT_QUILL_INIT_CONTENT &&
                              theme.secondary
                            }
                          />
                        </MsgButton>
                        <BinButton
                          onClick={() => handleUnselectFacility(index)}
                        >
                          <IcBin />
                        </BinButton>
                      </Actions>
                    )}
                  </FacilityPopoverItem>
                ))}
              </ToggleSection>
            </PopoverItem>
          ))}
        </ListItems>
      )}

      <Modal
        isOpen={editedFacilityIndex > -1}
        onRequestClose={() => setEditedFacilityIndex(-1)}
        title={value?.[editedFacilityIndex]?.facility?.displayName}
      >
        <Stack direction="column" size="full" gutterSize={2}>
          <Editor
            value={newDetails}
            onChange={setNewDetails}
            disabled={disabled}
          />
          <Stack size="full" gutterSize={1} alignX="flex-end">
            <Button
              type="button"
              onClick={handleDismissEditFacilityDetails}
              variant="simple"
            >
              {t('misc:cancel')}
            </Button>
            {!disabled && (
              <Button
                type="button"
                onClick={handleSubmitEditFacilityDetails}
                variant="primary"
              >
                {t('misc:save')}
              </Button>
            )}
          </Stack>
        </Stack>
      </Modal>
    </FormField>
  );
};

export default EditSelectedFacilitiesField;
