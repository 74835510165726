import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Stack } from '@koob/margaret';
import { TextField } from 'components/Fields';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';
import { FormLabel } from 'ui';

const CommentContent = styled.p`
  font-weight: 600;
  color: ${({ theme }) => theme.gray};
  background-color: #eaeaea;
  width: 100%;
  border-radius: 6px;
  min-height: 50px;
  padding: 5px;
  box-shadow: 1px 1px #cecece;
  margin-top: -2px;
`;

const DetailsSettingRequestForm = ({ settingRequest }) => {
  const { t } = useTranslation('settingRequest');

  const initialValues = {
    settingName: settingRequest?.settingName,
    displayName: settingRequest?.displayName,
    kind: settingRequest?.kind,
    refusedExplaiantion: settingRequest?.refusedExplanation,
  };
  return (
    <>
      <Formik initialValues={initialValues} enableReinitialize>
        {() => (
          <Form>
            <Stack direction="column" gutterSize={1}></Stack>

            <Stack direction="column" gutterSize={1.5} size="full">
              <Stack
                gutterSize={1.5}
                size="full"
                direction={{ default: 'column', tablet: 'row' }}
              >
                <TextField
                  label={t('settingName')}
                  name="settingName"
                  disabled
                />
                <TextField
                  label={t('displayName')}
                  name="displayName"
                  disabled
                />
              </Stack>

              <Stack
                gutterSize={1.5}
                size="full"
                direction={{ default: 'column', tablet: 'row' }}
              >
                <TextField label={t('kind')} name="kind" disabled />
              </Stack>

              <FormLabel>{t('refusedExplanation')}</FormLabel>
              <CommentContent
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(settingRequest?.refusedExplaination),
                }}
              ></CommentContent>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DetailsSettingRequestForm;
