import { get, patch, post, put } from './index';

export const saveTrip = trip => {
  return post(`/trip/save`, trip);
};

export const saveTripTemplate = ({ tripId, body }) => {
  return post(`/trip/${tripId}/template`, body);
};

export const updateTripDmcReference = ({ tripId, dmcReference }) => {
  return put(`/trip/${tripId}/dmc-reference`, { dmcReference });
};

export const updateTripTemplate = ({ templateId, body }) => {
  return put(`/trip/template/${templateId}`, body);
};

export const getTripPrograms = ({ filters, locale }) => {
  return post(`/trip/programs?locale=${locale ?? 'en'}`, filters);
};

export const saveTripDays = ({ tripId, tripDays, draftPrice, folderId, travelers, globalOptions }) => {
  return put(`/trip/${tripId}/days`, { tripDays, draftPrice, folderId, travelers, globalOptions });
};

export const getTrip = ({ tripId, versionId }) => {
  return get(`/trip/${tripId}${versionId ? `?version=${versionId}` : ''}`);
};

export const getTripTemplates = ({ filters }) => {
  return post('/trip/templates', filters);
};
export const getTrips = ({ kind, filters }) =>
  post(`/trip?kind=${kind}`, filters);

export const getOneTrip = ({ tripId }) => get(`/trip/${tripId}`);

export const getBooking = ({ bookingId }) => {
  if (!bookingId) {
    return Promise.resolve(null);
  }
  return get(`/bookings/${bookingId}`);
};

export const addTripAssistant = ({ tripId, body }) =>
  post(`/trip/${tripId}/tripAssistant`, body);

export const updateBookedTrip = ({ tripId, body }) =>
  patch(`/trip/updateTripBook/${tripId}`, body);

export const saveTripProposal = ({ tripId, body }) => {
  return post(`/trip/${tripId}/proposal`, body);
};