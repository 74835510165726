import { useState } from 'react';
import { useDebounce } from 'react-use';
import { gql, useQuery } from '@apollo/client';
import { useField } from 'formik';
import { pickBy } from 'lodash';
import { IoMdBed } from 'react-icons/io';
import styled, { useTheme } from 'styled-components';
import { FormField } from 'ui/forms';
import {
  Dropdown,
  PopoverContainer,
  PopoverMenu,
  Stack,
  PopoverItemButton as RawPopoverItemButton,
} from '@koob/margaret';
import { IcCheckMark, IcAddCircle } from 'components/icons';
import Search from 'components/Search';
import { Text } from 'ui';

const GET_BEDS = gql`
  query getBeds($search: String) {
    beds(displayName: $search) {
      edges {
        node {
          id
          displayName
          enDisplayName
          minCapacity
          maxCapacity
        }
      }
    }
  }
`;

const PopoverItemButton = styled(RawPopoverItemButton)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};

  color: ${({ theme }) => theme.textLight};

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const BedsSelectField = ({
  placeholder,
  name,
  kind,
  disabled,
  currentLocale,
}) => {
  const theme = useTheme();
  const [{ value }, , { setValue }] = useField({ name });
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { data } = useQuery(GET_BEDS, {
    variables: pickBy({
      search: debouncedSearch,
      kind,
      locale: currentLocale?.value || 'en',
    }),
  });

  const beds = (data?.beds?.edges || []).map(({ node }) => ({
    bedId: node.id,
    displayName: node?.displayName || node?.enDisplayName,
    minCapacity: node.minCapacity,
    maxCapacity: node.maxCapacity,
  }));

  const handleClick = selectedBed => {
    if (value?.length > 0) {
      if (value.some(({ bedId }) => bedId === selectedBed.bedId)) {
        setValue(value.filter(({ bedId }) => bedId !== selectedBed.bedId));
      } else {
        setValue([...value, { ...selectedBed, bedCount: 1 }]);
      }
    } else {
      setValue([{ ...selectedBed, bedCount: 1 }]);
    }
  };

  useDebounce(() => setDebouncedSearch(search), 500, [search]);

  return (
    <FormField>
      {!disabled && (
        <Stack direction="column" gutterSize={1} size="full" alignX="stretch">
          <Dropdown
            trigger={
              <Search
                placeholder={placeholder}
                onChange={setSearch}
                value={search}
              />
            }
          >
            <PopoverContainer>
              <PopoverMenu>
                {beds.map((bed) => (
                  <PopoverItemButton
                    key={bed.bedId}
                    onClick={() => handleClick(bed)}
                    type="button"
                  >
                    <Stack
                      size="full"
                      alignX="space-between"
                      alignY="center"
                      gutterSize={0.5}
                    >
                      <Stack alignY="center" gutterSize={0.25}>
                        <IoMdBed size={24} />

                        <Text type="body">
                          {bed.displayName}{' '}
                          {bed.minCapacity !== bed.maxCapacity ? (
                            <Text type="bodySmall" color="textLight">
                              ({bed.minCapacity} <i className="fal fa-arrows-left-right text-gray-400"/> {bed.maxCapacity})
                            </Text>
                          ) : (
                            ` (${bed.minCapacity})`
                          )}
                        </Text>
                      </Stack>

                      {value.some(b => bed.bedId === b.bedId) ? (
                        <IcCheckMark size={24} color={theme.primary} />
                      ) : (
                        <IcAddCircle size={24} color={theme.textLight} />
                      )}
                    </Stack>
                  </PopoverItemButton>
                ))}
              </PopoverMenu>
            </PopoverContainer>
          </Dropdown>
        </Stack>
      )}
    </FormField>
  );
};

export default BedsSelectField;
