import KoobButton from 'components/Koob/KoobButton';
import KoobModal from 'components/Koob/KoobModal';
import KoobParagraph from 'components/Koob/KoobParagraph';
import KoobTitle from 'components/Koob/KoobTitle';
import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useAsync } from 'react-async';
import { getHotelsContracts } from '../../../../api/node/contracts';
import KoobPlaceholder from '../../../../components/Koob/KoobPlaceholder';
import { ToggleSection } from '../../../../components';
import { format, parseISO } from 'date-fns';
import KoobInputSelect from '../../../../components/Koob/Input/KoobInputSelect';
import KoobInput from '../../../../components/Koob/Input/KoobInput';
import { parseBoolean } from '../../../../utils';
import { getRoomsDetail } from '../../../../utils/hotelDistribution';

function MarginFields({ name }) {
  const { t } = useTranslation('toConnectionRequest');

  return (
    <div className="flex items-center space-x-3">
      <KoobInputSelect
        label={t('bulkEdit.editor.marginType')}
        name={`${name}.isMarginPercent`}
        options={[
          { label: t('bulkEdit.editor.noChange'), value: null },
          { label: t('bulkEdit.editor.marginPercent'), value: true },
          { label: t('bulkEdit.editor.marginFixed'), value: false },
        ]}
      />

      <KoobInput
        label={t('bulkEdit.editor.marginValue')}
        name={`${name}.margin`}
        type="number"
        min="0"
      />
    </div>
  );
}

function EyeButton({ room, roomsFieldName, roomFieldName, disabled }) {
  const { t } = useTranslation('toConnectionRequest');
  const [{ value: rooms }, , { setValue: setRooms }] = useField(roomsFieldName);
  const [{ value: roomField }, , { setValue: setRoomField }] =
    useField(roomFieldName);
  const roomValue = rooms?.find(r => r?.id === room?.id);

  useEffect(() => {
    if (disabled && roomField && parseBoolean(roomField.enabled) === true) {
      setRoomField({ ...roomField, enabled: 'false' });
    }
  }, [disabled, roomField]);

  const addItem = () => {
    if (roomValue !== undefined) {
      return;
    }

    setRooms([
      ...(rooms ?? []),
      {
        id: room.id,
        enabled: null,
        periods: room.periods,
      },
    ]);
  };

  return (
    <div onClick={e => e.stopPropagation()}>
      {roomValue?.enabled === undefined ? (
        <KoobButton onClick={() => addItem()} disabled={disabled}>
          <i className="far fa-eye mr-1" />
          {t('bulkEdit.editor.contracts.editButton')}
        </KoobButton>
      ) : (
        <KoobInputSelect
          label={t('bulkEdit.editor.visibility')}
          name={`${roomFieldName}.enabled`}
          options={[
            { label: t('bulkEdit.editor.noChange'), value: null },
            { label: t('bulkEdit.editor.visible'), value: true },
            { label: t('bulkEdit.editor.invisible'), value: false },
          ]}
          disabled={disabled}
        />
      )}
    </div>
  );
}

function RoomListItem({ itemField, room }) {
  const theme = useTheme();
  const { t } = useTranslation('toConnectionRequest');

  const [{ value: rooms }] = useField(`${itemField}.rooms`);

  const rIndex = rooms?.findIndex(r => r?.id === room?.id) ?? 0;
  const roomIndex = rIndex >= 0 ? rIndex : rooms?.length ?? 0;

  const roomsFieldName = `${itemField}.rooms`;
  const roomFieldName = `${roomsFieldName}[${roomIndex}]`;

  const [{ value: enabled }] = useField(`${roomFieldName}.enabled`);
  const [{ value: contracts }] = useField(`${itemField}.contracts`);

  const canDisplayPeriod = period => {
    if (!contracts) {
      return true;
    }
    const contract = contracts?.find(contract => {
      return (
        new Date(contract.startAt) <= new Date(period.startAt) &&
        new Date(contract.endAt) >= new Date(period.endAt)
      );
    });
    return parseBoolean(contract?.enabled) !== false;
  };

  return (
    <div className="border p-2 rounded-md">
      <ToggleSection
        key={room.id}
        title={room.name}
        variant="full"
        disabled={!parseBoolean(enabled)}
        rightElement={
          <div>
            <EyeButton
              room={room}
              roomsFieldName={roomsFieldName}
              roomFieldName={roomFieldName}
              disabled={room?.periods?.every(
                period => !canDisplayPeriod(period),
              )}
            />
          </div>
        }
      >
        <div className="m-5 flex-col space-y-3">
          {room.periods.map(
            (period, periodIndex) =>
              canDisplayPeriod(period) && (
                <div className="bg-gray-100 py-4 px-5 rounded" key={period.id}>
                  <div className="flex justify-between items-center space-x-5">
                    <h3
                      className={`text-base font-bold text-k-h`}
                      style={{ color: theme.gray }}
                    >
                      {t('periodDates', {
                        start: format(parseISO(period.startAt), 'dd-MM-yyyy'),
                        end: format(parseISO(period.endAt), 'dd-MM-yyyy'),
                      })}
                    </h3>

                    <div>
                      <MarginFields
                        name={`${roomFieldName}.periods.${periodIndex}`}
                      />
                    </div>
                  </div>
                </div>
              ),
          )}
        </div>
      </ToggleSection>
    </div>
  );
}

function RoomList({ hotel, itemField }) {
  const { t } = useTranslation('toConnectionRequest');

  const { data, isLoading } = useAsync({
    promiseFn: getHotelsContracts,
    hotelId: hotel.id,
  });
  const contracts = data?.data;

  const rooms = getRoomsDetail(data, hotel.periods ?? []) ?? [];

  return (
    <>
      {isLoading || contracts?.length > 0 ? (
        <div className="flex-col space-y-5">
          {isLoading && (
            <div className="flex-col space-y-5">
              <KoobPlaceholder className="h-24 w-full rounded-md" />
              <KoobPlaceholder className="h-24 w-full rounded-md" />
              <KoobPlaceholder className="h-24 w-full rounded-md" />
            </div>
          )}

          {!isLoading &&
            rooms?.map(room => (
              <RoomListItem key={room.id} room={room} itemField={itemField} />
            ))}
        </div>
      ) : (
        <div className="h-60 flex flex-col justify-center items-center">
          <div className="text-center">
            <i className="far fa-file-signature text-4xl text-gray-300" />

            <div className="mt-3 font-medium text-gray-500">
              {t('bulkEdit.editor.contracts.noContracts')}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default function BulkEditorItemRooms({ hotel, itemField, disabled }) {
  const { t } = useTranslation('toConnectionRequest');

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <KoobButton
        size="sm"
        disabled={disabled}
        onClick={() => setShowModal(true)}
      >
        <i className="fa-regular fa-bed text-lg" />
      </KoobButton>

      {showModal && (
        <KoobModal
          open={true}
          setOpen={() => setShowModal(false)}
          widthClass="sm:max-w-xl xl:max-w-4xl overflow-visible"
        >
          <KoobTitle>{t('bulkEdit.editor.periods.title')}</KoobTitle>
          <KoobParagraph>{hotel.name}</KoobParagraph>

          <div className="mt-5">
            {showModal && <RoomList hotel={hotel} itemField={itemField} />}
          </div>
        </KoobModal>
      )}
    </>
  );
}
