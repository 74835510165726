import { Text } from '../../ui';
import { useTranslation } from 'react-i18next';
import { useApp, useError, useSnack } from '../../hooks';
import { Stack } from '@koob/margaret';
import StepperField from '../Fields/StepperField';
import { SelectField } from '../Fields';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from '../index';
import { useEffect, useState } from 'react';
import {
  getOrganization,
  updateOrganization,
} from '../../api/node/organization';
import { useAsync } from 'react-async';
import styled from 'styled-components';

const DurationText = styled(Text)`
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.secondaryLight};
    text-decoration-color: ${({ theme }) => theme.primary};
  }
`;

const TosMaximumTimeBeforeBooking = ({ isAllowedToManage }) => {
  const { t } = useTranslation('organization');
  const { sendErrorSnack } = useError();
  const { notify } = useSnack();
  const { currentOrganizationId, currentOrganizationIdInt } = useApp();
  const { data, reload } = useAsync({
    promiseFn: getOrganization,
    id: currentOrganizationIdInt,
  });
  const currentOrganization = data?.data;
  const maxReservationInterval = currentOrganization?.maxReservationInterval;
  const [shouldEdit, setShouldEdit] = useState(true);

  useEffect(() => {
    if (
      maxReservationInterval === undefined ||
      maxReservationInterval === null
    ) {
      return;
    }
    setShouldEdit(false);
  }, [maxReservationInterval]);

  let duration;
  let durationUnit;
  if (maxReservationInterval >= 0) {
    durationUnit =
      maxReservationInterval >= 24
        ? t('misc:duration.day')
        : t('misc:duration.hour');
    duration =
      maxReservationInterval >= 24
        ? Math.ceil(maxReservationInterval / 24)
        : maxReservationInterval;
  }

  const updateMaxReservationInterval = async values => {
    setShouldEdit(false);
    let amount = values.amount;
    if (values.duration.value === t('misc:duration.day')) {
      amount *= 24;
    }
    try {
      const body = new FormData();
      const input = {
        maxReservationInterval: amount,
        canBeRequestedForToAccess:
          currentOrganization?.canBeRequestedForToAccess,
      };
      body.append('input', JSON.stringify(input));
      await updateOrganization(currentOrganizationId, body);
      notify(t('organizationUpdateSuccess'));
      reload();
    } catch (err) {
      sendErrorSnack(err);
    }
  };

  return shouldEdit ? (
    <Formik
      initialValues={{
        amount: duration || 0,
        duration: {
          label: durationUnit || t('misc:duration.day'),
          value: durationUnit || t('misc:duration.day'),
        },
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        amount: Yup.number().required(t('errors:required')),
      })}
      onSubmit={updateMaxReservationInterval}
    >
      {({ isSubmitting }) => (
        <Form>
          <Stack
            gutterSize={1}
            style={{
              flexWrap: 'wrap',
              alignItems: 'stretch',
              justifyContent: 'space-between',
            }}
          >
            <Stack
              alignY={'center'}
              style={{ flexFlow: 'row wrap', justifyContent: 'space-around' }}
            >
              <Text>{t('distribution.distribTO1')}&nbsp;</Text>
              <StepperField name={'amount'} />
              <SelectField
                name={`duration`}
                options={[
                  {
                    label: t('misc:duration.day'),
                    value: t('misc:duration.day'),
                  },
                  {
                    label: t('misc:duration.hour'),
                    value: t('misc:duration.hour'),
                  },
                ]}
              />
              <Text> &nbsp; {t('distribution.distribTO2')}</Text>
            </Stack>
            <Stack gutterSize={1}>
              <Button
                variant="simple"
                type="button"
                onClick={() => setShouldEdit(false)}
              >
                {t('misc:cancel')}
              </Button>
              <Button variant="primary" type="submit" isLoading={isSubmitting}>
                {t('misc:saveChanges')}
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  ) : (
    <Stack
      alignY={'center'}
      style={{ flexFlow: 'row wrap', justifyContent: 'start' }}
    >
      <Text type={'h3'}>{t('distribution.distribTO1')}&nbsp;</Text>&nbsp;
      <DurationText
        type={'h3'}
        onClick={() => {
          if (!isAllowedToManage) {
            return;
          }
          setShouldEdit(true);
        }}
      >
        {`${duration} ${durationUnit}`}
      </DurationText>
      &nbsp;
      <Text type={'h3'}>{t('distribution.distribTO2')}&nbsp;</Text>
    </Stack>
  );
};

export default TosMaximumTimeBeforeBooking;
