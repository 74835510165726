import { gql } from '@apollo/client';

export const CREATE_HOTEL = gql`
  mutation createHotel($input: CreateHotelInput!) {
    createHotel(input: $input) {
      hotel {
        id
        displayName
        country {
          id
        }
        region {
          id
        }
        city {
          id
          title
        }
        address
      }
      errors {
        message
      }
    }
  }
`;

export const UPDATE_HOTEL = gql`
  mutation updateHotel($input: UpdateHotelInput!) {
    updateHotel(input: $input) {
      hotel {
        id
        displayName
        city {
          id
          title
        }
        country {
          id
          title
        }
        region {
          id
          title
        }
        address
      }
      errors {
        message
        attribute
      }
    }
  }
`;

export const UPDATE_HOTEL_INFORMATION = gql`
  mutation updateHotelInformations($input: UpdateHotelInformationsInput!) {
    updateHotelInformations(input: $input) {
      hotel {
        id
        kinds {
          nodes {
            displayName
            id
          }
        }
        styles {
          nodes {
            displayName
            id
          }
        }
        locations {
          nodes {
            displayName
            id
          }
        }
        roomCount
        priceLevel
        koediaId
        phhId
        lat
        lon
        stars
      }
      errors {
        message
        attribute
      }
    }
  }
`;
export const UPDATE_HOTEL_GENERAL_INFORMATION = gql`
  mutation updateHotelGeneralInformations(
    $input: UpdateHotelGeneralInformationsInput!
  ) {
    updateHotelGeneralInformations(input: $input) {
      hotel {
        id
        hotelDescriptions {
          nodes {
            id
            content
            description {
              id
              displayName
            }
          }
        }
      }
      errors {
        message
        attribute
      }
    }
  }
`;

export const UPDATE_HOTEL_SUSTAINABLE_COMMITMENT = gql`
  mutation updateHotelSustainableCommitments(
    $input: UpdateHotelSustainableCommitmentsInput!
  ) {
    updateHotelSustainableCommitments(input: $input) {
      hotel {
        id
        sustainableCommitments {
          nodes {
            id
            descriptions
            sustainableCluster {
              id
              displayName
            }
          }
        }
      }
      errors {
        message
        attribute
      }
    }
  }
`;

export const UPDATE_HOTEL_FACILITIES = gql`
  mutation updateHotelFacilities($input: UpdateHotelFacilitiesInput!) {
    updateHotelFacilities(input: $input) {
      errors {
        path
        message
        attribute
      }
      hotel {
        hotelFacilities {
          edges {
            node {
              id
              details
              facility {
                id
                displayName
                iconUrl(size: ICON)
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_HOTEL_ATTACHMENTS = gql`
  mutation updateHotelAttachments($input: UpdateHotelAttachmentsInput!) {
    updateHotelAttachments(input: $input) {
      errors {
        path
        message
        attribute
      }
      hotel {
        id
        attachments {
          id
          imageUrl(size: MEDIUM)
          displayName
        }
      }
    }
  }
`;

export const GET_HOTEL_CURRENCY = gql`
  query getHotelCurrency($id: ID!) {
    node(id: $id) {
      ... on Hotel {
        id
        currency
      }
    }
  }
`;

export const GET_HOTEL = gql`
  query getHotel($id: ID!) {
    node(id: $id) {
      ... on Hotel {
        id
        displayName
        showToTa
        city {
          title
          id
        }
        country {
          title
          id
        }
        region {
          title
          id
        }
        address
        marketSegments {
          nodes {
            displayName
          }
        }
        themes {
          nodes {
            displayName
          }
        }
        roomCount
        priceLevel
        koediaId
        phhId
        lat
        lon
        stars
        kinds {
          nodes {
            displayName
            id
          }
        }
        styles {
          nodes {
            displayName
            id
          }
        }
        locations {
          nodes {
            displayName
            id
          }
        }
        sustainable {
          id
          level
          accreditations {
            nodes {
              displayName
              id
            }
          }
        }
        sustainableCommitments {
          nodes {
            id
            descriptions
            sustainableCluster {
              id
              displayName
            }
          }
        }
        hotelDescriptions {
          nodes {
            id
            content
            description {
              id
              displayName
            }
          }
        }
        hotelFacilities {
          edges {
            node {
              id
              details
              facility {
                id
                displayName
                enDisplayName
                iconUrl(size: ICON)
                groups {
                  nodes {
                    id
                    displayName
                    enDisplayName
                  }
                }
              }
            }
          }
        }
        primaryAttachment {
          displayName
          id
          imageUrl(size: SMALL)
        }
      }
    }
  }
`;
