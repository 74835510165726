import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Stack } from '@koob/margaret';

import { Modal } from 'components';

const Section = styled(Stack).attrs({ direction: 'column', gutterSize: 0.5 })`
  color: ${({ theme }) => theme.textLight};
`;

const SectionTitle = styled.div`
  color: ${({ theme }) => theme.textLight};
  font-weight: bold;

  ${({ variant, theme }) =>
    variant === 'primary' &&
    `
    color: ${theme.primary}
  `}
  ${({ variant, theme }) =>
    variant === 'secondary' &&
    `
    color: ${theme.secondary}
  `}
`;

const RightsExplanationModal = ({ isOpen, onRequestClose, isTo }) => {
  const { t } = useTranslation('user');

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={t('kindOfRights')}
    >
      <Stack direction="column" gutterSize={1.5}>
        <Section>
          <SectionTitle variant="primary">{t('admin')}</SectionTitle>
          {isTo ? (
            <div>{t('adminExplanationTO')}</div>
          ) : (
            <div>{t('adminExplanation')}</div>
          )}
        </Section>
        <Section>
          <SectionTitle variant="secondary">{t('editorSales')}</SectionTitle>
          {isTo ? (
            <div>{t('editorSalesExplanationTO')}</div>
          ) : (
            <div>{t('editorSalesExplanation')}</div>
          )}
        </Section>
        <Section>
          <SectionTitle variant="secondary"> {t('viewer')}</SectionTitle>
          {isTo ? (
            <div>{t('viewerExplanationTO')}</div>
          ) : (
            <div>{t('viewerExplanation')}</div>
          )}
        </Section>
        <Section>
          <SectionTitle>{t('noAccess')}</SectionTitle>
          {isTo ? (
            <div>{t('noAccessExplanationTO')}</div>
          ) : (
            <div>{t('noAccessExplanation')}</div>
          )}
        </Section>
      </Stack>
    </Modal>
  );
};

export default RightsExplanationModal;
