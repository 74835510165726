import { Container } from 'ui';
import { Stack } from '@koob/margaret';
import {
  EditorField,
  MultiSearchableSelectField,
  SelectField,
} from 'components/Fields';
import { GET_SEGMENTS } from '../../../ExperiencesSettings/Segments/SegmentsList';
import { GET_THEMES } from '../../../ExperiencesSettings/Themes/ThemesList';
import { EXPERIENCE_PROGRAM_SCHEMA } from '../Utilities/ExperienceValidationSchemas';
import { useTranslation } from 'react-i18next';

export const ExperienceProgram = () => {
  const { t } = useTranslation('experiences');

  return (
    <Container size="full">
      <Stack direction="column" gutterSize={1.5} size="full">
        <Stack
          gutterSize={0.5}
          size="full"
          style={{
            flexWrap: 'wrap',
            alignItems: 'stretch',
            justifyContent: 'space-between',
          }}
        >
          <Stack>
            <SelectField
              label={t('form.difficulty')}
              name="difficulty"
              valueField={'value'}
              options={[
                { label: t('form.beginner'), value: 'Beginner' },
                {
                  label: t('form.intermediate'),
                  value: 'Intermediate',
                },
                { label: t('form.advanced'), value: 'Advanced' },
                { label: t('form.expert'), value: 'Expert' },
              ]}
            />
          </Stack>
        </Stack>

        <Stack gutterSize={1.5} size="full">
          <MultiSearchableSelectField
            label={t('form.segments')}
            name="segments"
            query={GET_SEGMENTS}
            pathToEdges={['experienceSegments', 'edges']}
            renderOption={({ displayName }) => displayName}
            renderSelectedOption={({ displayName }) => displayName}
          />

          <MultiSearchableSelectField
            label={t('form.themes')}
            name="themes"
            query={GET_THEMES}
            pathToEdges={['experienceThemes', 'edges']}
            renderOption={({ displayName }) => displayName}
            renderSelectedOption={({ displayName }) => displayName}
          />
        </Stack>

        <Stack gutterSize={1.5} size="full">
          <EditorField name={'highlights'} label={t('form.highlights')} />
        </Stack>
      </Stack>
    </Container>
  );
};

export const getSchema = () => EXPERIENCE_PROGRAM_SCHEMA;
