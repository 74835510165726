import styled from 'styled-components';
import { Stack } from '@koob/margaret';
import { IcCheckMark } from './icons';
import { useTranslation } from 'react-i18next';

const PasswordValidationText = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme, isTouched, isValid }) =>
    isTouched ? (isValid ? theme.success : theme.danger) : theme.textLighter};
  font-size: 14px;

  svg {
    margin-right: 4px;
    color: ${({ theme, isTouched, isValid }) =>
      isTouched ? (isValid ? theme.success : theme.danger) : theme.textLighter};
  }
`;

export const isLongerThanEight = input => input.match(/.{8,}/);
export const hasOneDigit = input => input.match(/[0-9]/g);
export const hasOneUppercase = input => input.match(/[A-Z]/g);

export const validatePassword = ({ password, error }) => {
  if (
    !isLongerThanEight(password) ||
    !hasOneDigit(password) ||
    !hasOneUppercase(password)
  ) {
    return { password: error };
  }
  return null;
};

const PasswordStrength = ({ password, isTouched }) => {
  const { t } = useTranslation(['auth', 'errors']);

  return (
    <Stack direction="row" alignY="center" gutterSize={1}>
      <PasswordValidationText
        isValid={isLongerThanEight(password)}
        isTouched={isTouched}
      >
        <IcCheckMark size={20} />
        {t('passwordValidation.characters')}
      </PasswordValidationText>
      <PasswordValidationText
        isTouched={isTouched}
        isValid={hasOneUppercase(password)}
      >
        <IcCheckMark size={20} />
        {t('passwordValidation.capital')}
      </PasswordValidationText>
      <PasswordValidationText
        isTouched={isTouched}
        isValid={hasOneDigit(password)}
      >
        <IcCheckMark size={20} />
        {t('passwordValidation.number')}
      </PasswordValidationText>
    </Stack>
  );
};

export default PasswordStrength;
