import styled from 'styled-components';
import { ActionLink, Container, Input } from 'ui';
import { formatCurrencyLabel, formatDate } from 'utils';
import { DataTable } from 'components';
import { useTranslation } from 'react-i18next';
import {
  PopoverItem,
  PopoverItemButton,
  PopoverMenu,
  SegmentedControl,
  Spinner,
  Stack,
} from '@koob/margaret';
import RequestToState from 'components/RequestToState';
import { useAsync } from 'react-async';
import { put } from 'api/node';
import { useState } from 'react';
import Header from './Partials/Header';
import { useApp, useNotifications, useSnack } from 'hooks';
import { useQuery } from '@apollo/client';
import { GET_CURRENCIES } from '../../../api/contracts';
import Select from '../../../components/Fields/Select';
import DropdownConnectionSettings from 'components/DropdownConnectionSettings';
import { getToConnectionRequest } from 'api/node/toConnectionRequest';
import KoobButton from '../../../components/Koob/KoobButton';
import { Link } from 'react-router-dom';
import { markAsRead } from 'api/node/notifications';
import NotificationType from 'constants/NotificationType';

const DateWrapper = styled.span`
  text-transform: capitalize;
`;

const BlocSettings = styled.div`
  margin-top: -10px;
`;

const TextLink = styled(ActionLink)`
  font-size: 14px;
  color: ${({ theme }) => theme.textLight};
  font-weight: 600;
  &:hover {
    color: orange;
  }
`;

const ToConnectionRequestList = () => {
  const { t } = useTranslation('toConnectionRequest');
  const { isAllowedTo, canManageToConnectionRequest, user } = useApp();
  const { data, reload, isLoading } = useAsync({
    promiseFn: getToConnectionRequest,
  });
  const [search, setSearch] = useState('');
  const { notify } = useSnack();
  const { notifications } = useNotifications();

  const { data: currenciesData } = useQuery(GET_CURRENCIES);
  const currenciesOptions = (currenciesData?.__type?.enumValues || []).map(
    ({ name: currency }) => ({
      value: currency,
      label: formatCurrencyLabel(currency),
    }),
  );

  const handleToogle = async (value, request, kind) => {
    const payload =
      kind === 'cancelCondition'
        ? {
            partnerCancelCondition: value === 'to',
          }
        : {
            showToTa: value,
          };
    await put(`ToConnectionRequest/${request?.id}/quickModification`, payload);
    reload();
    notify(t('updated'));
  };
  const modifyCurrency = async (value, request) => {
    await put(`ToConnectionRequest/${request?.id}/quickModification`, {
      currency: value,
    });
    reload();
    notify(t('updatedTOCurrency'));
  };

  const getNotificationsByProductType = (id, type) => {
    return notifications.filter(
      (notification) =>
        !notification.read &&
        notification.relatedObject === id &&
        notification.notificationType === type,
    );
  };

  const headings = [
    { slug: 'companyName', label: t('headings.organisation'), width: '12%' },
    { slug: 'createdAt', label: t('headings.created'), width: '8%' },
    {
      slug: 'state',
      label: t('headings.hotel'),
      tooltip: t('headings.hotelStatus'),
      cannotBeReordered: true,
      width: '6%',
      allowOverflow: true,
    },
    {
      slug: 'stateExperience',
      label: t('headings.experience'),
      tooltip: t('headings.experienceStatus'),
      cannotBeReordered: true,
      width: '8%',
      allowOverflow: true,
    },
    {
      slug: 'stateTemplate',
      label: t('headings.trip'),
      tooltip: t('headings.tripStatus'),
      cannotBeReordered: true,
      width: '5%',
      allowOverflow: true,
    },
    {
      slug: 'apply_conditions_to',
      label: t('headings.condition'),
      tooltip: t('headings.contractingCondition'),
      cannotBeReordered: true,
      width: '14%',
      allowOverflow: true,
    },
    {
      slug: 'showToTa',
      label: t('headings.hotelkeeperVisibility'),
      tooltip: t('headings.shareTOTA'),
      cannotBeReordered: true,
      width: '14%',
      allowOverflow: true,
    },
    {
      slug: 'currency',
      label: t('headings.hotelCurrency'),
      cannotBeReordered: true,
      width: '14%',
      allowOverflow: true,
    },
    {
      slug: 'experienceCurrency',
      label: t('headings.experienceCurrency'),
      cannotBeReordered: true,
      width: '14%',
      allowOverflow: true,
    },
    { slug: 'actions', cannotBeReordered: true, width: '5%' },
  ];

  const readConnectionRequestNotifications = async (notifications) => {
    if (!notifications?.length) {
      return;
    }

    for (const notification of notifications) {
      await markAsRead({ notificationId: notification?.id });
    }
  };

  const request = data?.data || [];
  const formatResult = Object.values(request).reduce((acc, curr) => {
    const id = curr.toorganization.id;
    const type = curr.type;
    const index = acc.findIndex((v) => v.toorganization.id === id);
    if (index !== -1) {
      acc[index] = {
        ...acc[index],
        [type]: curr,
      };
    } else {
      acc = [...acc, { ...curr, [type]: curr }];
    }
    return acc;
  }, []);

  const isAllowedToManage = () => {
    return (
      isAllowedTo('canManageToConnectionRequest') ||
      canManageToConnectionRequest(user)
    );
  };

  const requests = (formatResult || [])
    .filter((r) =>
      search
        ? r?.toorganization?.displayName
            .toLowerCase()
            .indexOf(search.toLowerCase()) > -1
        : true,
    )
    ?.map((request) => {
      const requestHotelNotifications = getNotificationsByProductType(
        request?.hotel?.id,
        NotificationType.TO_CONNECTION_REQUEST_HOTEL_REQUESTED,
      );
      const requestExperienceNotifications = getNotificationsByProductType(
        request?.experience?.id,
        NotificationType.TO_CONNECTION_REQUEST_EXPERIENCE_REQUESTED,
      );

      return {
        id: { value: request?.[0]?.id },
        companyName: { value: request?.toorganization?.displayName },
        createdAt: {
          value: request?.createdAt,
          render: () => (
            <DateWrapper>
              {formatDate(request?.createdAt, 'MMM dd, yyyy')}
            </DateWrapper>
          ),
        },
        apply_conditions_to: {
          value: request?.partnerCancelCondition,
          render: () => (
            <>
              <SegmentedControl
                value={request?.partnerCancelCondition ? 'to' : 'dmc'}
                onSelect={(value) =>
                  handleToogle(value, request, 'cancelCondition')
                }
                options={[
                  { value: 'dmc', label: 'DMC' },
                  { value: 'to', label: 'TO' },
                ]}
                disabled={!isAllowedToManage()}
              />
            </>
          ),
        },
        showToTa: {
          value: request?.showToTa,
          render: () => (
            <>
              <SegmentedControl
                value={request?.showToTa}
                onSelect={(value) => handleToogle(value, request, 'ToTa')}
                options={[
                  {
                    label: t('show'),
                    value: true,
                  },
                  {
                    label: t('hide'),
                    value: false,
                  },
                ]}
                disabled={!isAllowedToManage()}
              />
            </>
          ),
        },
        currency: {
          value: request?.partnerCancelCondition,
          render: () => (
            <Select
              onChange={(value) => {
                modifyCurrency(value, request?.hotel);
              }}
              renderOption={(value) => value?.label}
              renderSelectedValue={(value) =>
                value?.label || formatCurrencyLabel(value)
              }
              valueField="value"
              options={currenciesOptions}
              value={request?.hotel?.currency}
              disabled={!request?.hotel?.currency || !isAllowedToManage()}
            />
          ),
        },
        experienceCurrency: {
          value: request?.partnerCancelCondition,
          render: () => (
            <Select
              onChange={(value) => {
                modifyCurrency(value, request?.experience);
              }}
              renderOption={(value) => value?.label}
              renderSelectedValue={(value) =>
                value?.label || formatCurrencyLabel(value)
              }
              valueField="value"
              options={currenciesOptions}
              value={request?.experience?.currency}
              disabled={!request?.experience?.currency || !isAllowedToManage()}
            />
          ),
        },
        state: {
          value: request?.state,
          render: () => (
            <RequestToState state={request?.hotel?.state} iconType />
          ),
        },
        stateExperience: {
          value: request?.state,
          render: () => (
            <RequestToState state={request?.experience?.state} iconType />
          ),
        },
        stateTemplate: {
          value: request?.state,
          render: () => (
            <RequestToState state={request?.template?.state} iconType />
          ),
        },
        actions: {
          value: request?.id,
          render: () => (
            <BlocSettings className="absolute">
              <DropdownConnectionSettings
                id={`${request?.toorganization?.displayName}-menu-setting`}
                popoverElement={
                  <PopoverMenu>
                    <PopoverItem>
                      {request?.hotel?.id && (
                        <PopoverItemButton>
                          <TextLink
                            to={`${request?.hotel?.id}/hotels`}
                            onClick={() => {
                              readConnectionRequestNotifications(
                                requestHotelNotifications,
                              );
                            }}
                          >
                            <div className="flex space-x-2 items-center">
                              <i className="far fa-bed text-blue-500" />

                              <div>{t('hotelSetting')}</div>
                            </div>
                          </TextLink>
                        </PopoverItemButton>
                      )}

                      {request?.experience?.id && (
                        <PopoverItemButton>
                          <TextLink
                            to={`${request?.experience?.id}/experiences`}
                            onClick={() => {
                              readConnectionRequestNotifications(
                                requestExperienceNotifications,
                              );
                            }}
                          >
                            <div className="flex space-x-2 items-center">
                              <i className="far fa-layer-group text-blue-500" />

                              <div>{t('experienceSetting')}</div>
                            </div>
                          </TextLink>
                        </PopoverItemButton>
                      )}

                      {request?.template?.id && (
                        <PopoverItemButton>
                          <TextLink to={`${request?.template?.id}/templates`}>
                            <div className="flex space-x-2 items-center">
                              <i className="far fa-copy text-blue-500" />

                              <div>{t('templateSetting')}</div>
                            </div>
                          </TextLink>
                        </PopoverItemButton>
                      )}
                    </PopoverItem>
                  </PopoverMenu>
                }
              />
            </BlocSettings>
          ),
        },
      };
    });

  return (
    <>
      <Header isAllowedToManage={isAllowedToManage()} />

      {isLoading ? (
        <Spinner />
      ) : (
        <Container>
          <div className="flex space-x-3">
            <Input
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={t('misc:search')}
            />

            {isAllowedToManage() && (
              <Link to="/to-connection-request/bulk-edit">
                <KoobButton>
                  <i className="far fa-list-check mr-1.5" />
                  {t('bulkEditButton')}
                </KoobButton>
              </Link>
            )}
          </div>

          <Stack direction="column" size="full" alignX="center" gutterSize={1}>
            <DataTable
              headings={headings}
              data={requests}
              isSearchable={false}
              headLineBreak
            />
          </Stack>
        </Container>
      )}
    </>
  );
};

export default ToConnectionRequestList;
